import React, { useContext } from "react";
import PropTypes from "prop-types";

import Modal from "react-modal";

import WidgetContext from "@dashboard/WidgetContext";

const WidgetSettingsModal = props => {
  const { component, label, visible, resetModal } = props;
  const { widget } = useContext(WidgetContext);

  const SettingsComponent = component;

  Modal.setAppElement("#root");

  const renderWidgetId = () => {
    if (!widget?.id || ESB_NODE_ENV !== "development") {
      return <></>;
    }

    return <div>Widget ID: {widget.id}</div>;
  };

  const renderModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title sg-mgmt-modal-title-edit">Edit Widget</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <SettingsComponent closeModal={resetModal} />
            {renderWidgetId()}
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={visible}
      onRequestClose={resetModal}
      contentLabel={label}
    >
      {renderModal()}
    </Modal>
  );
};

WidgetSettingsModal.defaultProps = {
  label: "",
  component: <></>,
};

WidgetSettingsModal.propTypes = {
  label: PropTypes.string,
  component: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
};

export default WidgetSettingsModal;
