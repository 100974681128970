import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import MetadataTable from "./MetadataTable";

const Metadata = () => {
  const metadataTypes = [
    {
      label: "Event Participant",
      fieldType: "event_participant"
    },
    {
      label: "Session Participant",
      fieldType: "session_participant"
    },
    {
      label: "Session",
      fieldType: "session"
    },
    {
      label: "Event",
      fieldType: "event"
    }
  ];
  const { apiRoot } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [fields, setFields] = useState({
    fetched: false,
    data: []
  });

  const sortFields = fieldsToSort => sortBy(fieldsToSort, ["sort_order"]);

  useEffect(() => {
    const fetchFields = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/metadata/fields"));
        setFields({
          fetched: true,
          data: sortFields(result.data)
        });
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchFields();
  }, [apiRoot]);

  const addField = field => {
    setFields({
      fetched: fields.fetched,
      data: sortFields(fields.data.concat(field))
    });
  };

  const forceSetFields = fields => {
    setFields({
      fetched: true,
      data: fields
    });
  };


  const deleteField = fieldId => {
    setFields({
      fetched: fields.fetched,
      data: sortFields(fields.data.filter(f => f.id !== fieldId))
    });
  };

  const updateField = field => {
    setFields({
      fetched: fields.fetched,
      data: fields.data.map(f => (f.id === field.id ? field : f))
    });
  };

  const removeField = fieldId => {
    const postData = { field_id: fieldId };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/metadata/fields", `/${fieldId}`),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        deleteField(response.data.field_id);
        alertSuccess("Successfully deleted registration field");
      } else {
        alertError("Failed deleting registration field");
      }
    });
  };

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const renderMetadataType = metadata => {
    const tableFields = fields.data.filter(
      f => f.field_type === metadata.fieldType
    );
    return (
      <MetadataTable
        key={`table-${metadata.fieldType}`}
        fields={tableFields}
        fieldType={metadata.fieldType}
        label={metadata.label}
        addField={addField}
        forceSetFields={forceSetFields}
        removeField={removeField}
        updateField={updateField}
      />
    );
  };

  const renderBody = () => {
    if (fields.fetched) {
      return metadataTypes.map(md => renderMetadataType(md));
    }
    return <Loading />;
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">
      <PageHeader text="Metadata" />
      {renderBody()}
    </div>
  );
};

export default Metadata;
