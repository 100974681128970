import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

const WidgetContentFrame = props => {
  const { children, fluidHeight } = props;

  return <div className={clsx("group relative p-7", fluidHeight ? "" : "h-widget")}>{children}</div>;
};

WidgetContentFrame.defaultProps = {
  fluidHeight: false
};

WidgetContentFrame.propTypes = {
  fluidHeight: PropTypes.bool,
  children: PropTypes.node.isRequired
};

export default WidgetContentFrame;
