import React from "react";

import TabbedPage from "@shared/TabbedPage";

import CustomReports from "./custom/CustomReports";
import StandardReports from "./standard/StandardReports";

const Reporting = () => {
  return (
    <TabbedPage
      tabs={[
        { label: "Standard Reports", component: StandardReports },
        { label: "Custom Reports", component: CustomReports }
      ]}
    />
  );
};

export default Reporting;
