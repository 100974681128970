import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { format, zonedTimeToUtc } from "date-fns-tz";
import ReactTooltip from "react-tooltip";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import { renderCancelButton } from "@shared/FormUtils";
import Iframe from "@shared/Iframe";
import Loading from "@shared/Loading";
import UiToggle from "@shared/UiToggle";

const CommunicationsEmailTransactional = (props) => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { back, clickedTracking, clickedView, templateId } = props;
  const [template, setTemplate] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [showDetails, setShowDetails] = useState(clickedView);
  const [showPreview, setShowPreview] = useState(false);
  const [showTracking, setShowTracking] = useState(clickedTracking);

  useEffect(() => {
    const fetchEmail = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `/communications/email_templates/${templateId}/emails`));
        setTemplate(result.data.template);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchEmail();
  }, [apiRoot, templateId]);

  const renderPostmarkTimestamp = (timestamp) => {
    try {
      return `${format(zonedTimeToUtc(timestamp, event.time_zone), "yyyy-MM-dd HH:mm:ss zzz", {
        timeZone: event.time_zone
      })}`;
    } catch (err) {
      return timestamp;
    }
  };

  const renderDetails = () => {
    if (showDetails) {
      return (
        <table className="ml-2 w-3/4">
          <tbody>
            <tr className="pb-1">
              <td className="w-36 p-2 align-top font-bold text-sg-orange">Email Template</td>
              <td className="p-2 align-top">{template.name}</td>
            </tr>
            <tr className="pb-1">
              <td className="w-36 p-2 align-top font-bold text-sg-orange">Template ID</td>
              <td className="p-2 align-top">{template.template_id}</td>
            </tr>
          </tbody>
        </table>
      );
    }
    return <></>;
  };

  const renderPreview = () => {
    if (showPreview) {
      return <Iframe scrolling="no" content={template.html} />;
    }
    return <></>;
  };

  const renderTracking = () => {
    if (showTracking) {
      const activity = template.emails.map((e) => e.activity).flat();

      return (
        <div>
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th className="w-1/4 bg-ui-3 px-4 py-2 text-left text-white">Recipient</th>
                <th className="w-1/4 bg-ui-3 px-4 py-2 text-left text-white">Time</th>
                <th className="w-1/2 bg-ui-3 px-4 py-2 text-right text-white">Status</th>
              </tr>
            </thead>
            <tbody>
              {activity.map((item) => (
                <tr key={item.ReceivedAt}>
                  <td className="border-t px-4 py-2 align-top">{item.Recipient}</td>
                  <td className="border-t px-4 py-2 align-top">{renderPostmarkTimestamp(item.ReceivedAt)}</td>
                  <td className="border-t px-4 py-2 text-right align-top">
                    <span>{item.Type}</span>
                    <i className="fas fa-info-circle" data-tip data-for={`tt-${item.ReceivedAt}`} />
                    <ReactTooltip
                      id={`tt-${item.ReceivedAt}`}
                      border
                      borderColor="gray"
                      type="dark"
                      html
                      className="email-activity-tt"
                    >
                      {Object.keys(item.Details).map((k) => `${k}: ${item.Details[k]}<br><br>`)}
                    </ReactTooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return <></>;
  };

  const renderBackButton = () => {
    return renderCancelButton("Done", back);
  };

  const renderBody = () => {
    if (fetched) {
      return (
        <div>
          <div>
            <h1>{template.name}</h1>
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showDetails}
              label="Email Details"
              toggleFunc={() => {
                setShowDetails(!showDetails);
              }}
            />
            {renderDetails()}
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showPreview}
              label="Preview"
              toggleFunc={() => {
                setShowPreview(!showPreview);
              }}
            />
            {renderPreview()}
          </div>
          <div className="border-t border-solid border-sg-orange py-3">
            <UiToggle
              flag={showTracking}
              label="Tracking"
              toggleFunc={() => {
                setShowTracking(!showTracking);
              }}
            />
            {renderTracking()}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  const renderEmail = () => <div>{renderBody()}</div>;

  return (
    <div>
      {renderEmail()}
      <div className="py-3">{renderBackButton()}</div>
    </div>
  );
};

CommunicationsEmailTransactional.propTypes = {
  back: PropTypes.func.isRequired,
  templateId: PropTypes.number.isRequired,
  clickedTracking: PropTypes.bool.isRequired,
  clickedView: PropTypes.bool.isRequired
};

export default CommunicationsEmailTransactional;
