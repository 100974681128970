import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from 'clsx';
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
// import DateTimePickerField from "@shared/DateTimePickerField";
import EventContext from "@event/EventContext";
import CommunicationsBlankMailingList from "./CommunicationsBlankEmailMailingList";
import { renderSubmitButton, renderTextField } from "@shared/FormUtils";
import RequiredAsterisk from "@shared/forms/RequiredAsterisk";

const CommunicationsEmailMailingListForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackFailure, callbackSuccess, cancel, list } = props;

  const isEdit = () => {
    return list && list.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-email-edit",
        formUrl: urljoin(apiRoot, "/communications/lists", `/${list.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit Mailing List"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-email-add",
      formUrl: urljoin(apiRoot, "/communications/lists"),
      method: "POST",
      saveButton: "Continue",
      title: "Create Mailing List"
    };
  })();

  const renderRequiredField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>
        {label}
        <RequiredAsterisk/>
      </label>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name={`list[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  // const renderDateTimeField = (label, field, formatClasses = []) => (
  //   <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
  //     <label>{label}</label>
  //     <DateTimePickerField
  //       className={clsx("sg-mgmt-form-input", "sg-mgmt-form-input-time")}
  //       minDate={new Date(`${event.date_begin}T00:00:00`)}
  //       maxDate={new Date(`${event.date_end}T00:00:00`)}
  //       name={`email[${field}]`}
  //       autoComplete="off"
  //     />
  //   </div>
  // );

  // const renderTextArea = (label, field, formatClasses = []) => (
  //   <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
  //     <label>{label}</label>
  //     <Field
  //       className="sg-mgmt-form-input"
  //       as="textarea"
  //       name={`session[${field}]`}
  //       autoComplete="off"
  //     />
  //   </div>
  // );

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: list.name
      };
    }
    return CommunicationsBlankMailingList;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        list: formInitialValues()
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <h1>New Mailing List</h1>
            <div className="flex items-end">
              <div className="w-1/2">
                {renderTextField("Mailing List Name", "list[name]", [], false, true)}
              </div>
              <div className="pl-4 w-1/2 mb-4">
                {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );

  return <>{renderForm()}</>;
};

CommunicationsEmailMailingListForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancel: () => {},
  list: CommunicationsBlankMailingList
};

CommunicationsEmailMailingListForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancel: PropTypes.func,
  list: PropTypes.object
};

export default CommunicationsEmailMailingListForm;
