import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import { formatTime } from "@shared/TimeUtils";
import Loading from "@shared/Loading";
import { alertHttpError } from "@shared/Alerts";
import EventContext from "@event/EventContext";
import { renderCancelButton } from "@shared/FormUtils";

const CommunicationsEmailMailingListImportLog = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { back, listId } = props;
  const [imports, setImports] = useState({});
  const [listName, setListName] = useState("");
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchImports = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, `/communications/lists/${listId}/imports`)
        );
        setImports(result.data.imports);
        setListName(result.data.list_name);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchImports();
  }, []);

  const renderImports = () => {
    return (
      <div className="py-3">
        {imports.map(im => (
          <div className="py-3" key={im.gid}>
            <div>Date/Time: {formatTime(im.updated_at, event.time_zone)}</div>
            <div>Imported by: {`${im.user.name_first} ${im.user.name_last}`}</div>
            <div>
              Filename:&nbsp;
              <a
                href={im.sheet_url}
                className="sg-mgmt-link"
              >
                {im.sheet_data.metadata.filename}
              </a>
            </div>
            <div>Entries created: {im.import_count}</div>
            <div>Errors: {im.import_errors}</div>
          </div>
        ))}
        {imports.length === 0 ? (
          <div><span className="italic">No imports yet.</span></div>
        ) : <></>}
      </div>
    );
  };

  const renderImportList = () => {
    if (fetched) {
      return (
        <div>
          <h2>{listName}</h2>
          {renderImports()}
        </div>
      );
    }
    return <Loading />;
  };

  return (
    <div>
      {renderImportList()}
      <br />
      {renderCancelButton("Back", back)}
    </div>
  );
};

CommunicationsEmailMailingListImportLog.propTypes = {
  back: PropTypes.func.isRequired,
  listId: PropTypes.number.isRequired
};

export default CommunicationsEmailMailingListImportLog;
