import urljoin from "url-join";
import axios from "axios";
const preview = (form, custom_keys={}) => {
  const url = custom_keys.custom_preview_link ? custom_keys.custom_preview_link : form.data.custom_preview_link
  var form_html = document.createElement("form");
  form_html.target = "_blank";
  form_html.method = "POST";
  form_html.action = url;
  form_html.style.display = "none";
  const data = {
    form: form,
    attendee_email: form.data.custom_preview_attendee_email ? form.data.custom_preview_attendee_email : null,
    ...custom_keys
  }
  var input = document.createElement("input");
  input.type = "hidden";
  input.name = "data";
  input.value = JSON.stringify(data);
  form_html.appendChild(input);
  document.body.appendChild(form_html);
  form_html.submit();
}

const previewById = async (form_id, apiRoot, custom_keys={}, custom_pages=[]) => {
  const result = await axios(urljoin(apiRoot, `forms/${form_id}`));
  const form = result.data.form;
  custom_pages.forEach(page => {
    form.pages = form.pages.filter(x=>x.id != page.id).concat([page])
  })
  preview(form, custom_keys)
}

const isFormPreviewable = (form) => {
  return form.data.custom_preview_link
}

export {
  preview,
  isFormPreviewable,
  previewById
};