import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";
import { renderTextField } from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import HousingBlankRoomBlock from "./HousingBlankRoomBlock";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const HousingRoomBlockForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackFailure, callbackSuccess, cancelButton, block } = props;

  const isEdit = () => {
    return block && block.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/housing/blocks", `/${block.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit Block"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/housing/blocks"),
      method: "POST",
      saveButton: "Create",
      title: "Create Block"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: block.name || "",
        code: block.code || "",
        owner_name_first: block.owner_name_first || "",
        owner_name_last: block.owner_name_last || "",
        owner_email: block.owner_email || "",
        notes: block.notes || ""
      };
    }
    return HousingBlankRoomBlock;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          block: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <div className="sg-mgmt-form-row">
                {renderTextField("Room Block Name", "block[name]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Code", "block[code]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Owner First Name", "block[owner_name_first]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Owner Last Name", "block[owner_name_last]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Owner Email", "block[owner_email]")}
              </div>
              <div className="sg-mgmt-form-row">
                {renderTextField("Notes", "block[notes]")}
              </div>
            </div>
            <Stack spacing={2} direction="row">
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

HousingRoomBlockForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

HousingRoomBlockForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  block: PropTypes.object.isRequired
};

export default HousingRoomBlockForm;
