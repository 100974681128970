import React, { useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import compact from "lodash/compact";

import { renderCreateButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";

import HousingBlankHotel from "./HousingBlankHotel";
import HousingHotelModal from "./HousingHotelModal";

const HousingHotelsIndex = props => {
  const {
    addHotel,
    fetched,
    hotels,
    goEdit,
    goRooms,
    setEditHotelId,
  } = props;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const muiTheme = useTheme();

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const renderHotelTable = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <div className="my-2">
        <table
          className="w-full table-fixed"
          style={{
            fontFamily: muiTheme.typography.fontFamily,
            fontSize: muiTheme.typography.fontSize
          }}
        >
          <thead>
            <tr>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Hotel Name
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Address
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Room Types
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-right text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {hotels.map(hotel => (
              <tr key={hotel.gid}>
                <td className="border-t px-4 py-4 font-bold">{hotel.name}</td>
                <td className="border-t px-4 py-4">
                  {hotel.address_street_1}
                  <br />
                  {compact([
                    hotel.address_city,
                    hotel.address_state,
                    hotel.address_country
                  ])
                    .filter(Boolean)
                    .join(", ")}
                </td>
                <td className="border-t px-4 py-4">
                  {hotel.room_types.length}
                </td>
                <td className="border-t px-4 py-4 text-right leading-none">
                  <span
                    className="inline-block cursor-pointer border-r border-r-ui-7 pr-2 uppercase text-ui-7"
                    onClick={() => {
                      setEditHotelId(hotel.id);
                      goRooms();
                    }}
                  >
                    Manage Rooms
                  </span>
                  <span
                    className="inline-block cursor-pointer pl-2 uppercase text-ui-7"
                    onClick={() => {
                      setEditHotelId(hotel.id);
                      goEdit();
                    }}
                  >
                    Edit Hotel
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderNewHotelButton = () => {
    return renderCreateButton("Create New Hotel", () => {
      setModalAddVisible(true);
    });
  };

  return (
    <div>
      {renderNewHotelButton()}
      {renderHotelTable()}
      <HousingHotelModal
        hotel={HousingBlankHotel}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Hotel"
        updateFunc={addHotel}
      />
    </div>
  );
};

HousingHotelsIndex.propTypes = {
  addHotel: PropTypes.func.isRequired,
  config: PropTypes.object.isRequired,
  fetched: PropTypes.bool.isRequired,
  hotels: PropTypes.array.isRequired,
  goEdit: PropTypes.func.isRequired,
  goRooms: PropTypes.func.isRequired,
  setEditHotelId: PropTypes.func.isRequired,
  updateHotels: PropTypes.func.isRequired
};

export default HousingHotelsIndex;
