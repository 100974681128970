import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import EventUserContext from "../EventUserContext";

const EventMenuDropdown = ({ links, icon, title, requiredPermissions }) => {
  const { user } = useContext(EventUserContext);
  const [open, setOpen] = useState(false);
  const iconUrl = `/images/nav/${icon}.svg`;
  const linkStyle = { textDecoration: "none" };

  const hasRequiredAccess = () => {
    if (user.role !== "basic") {
      return true
    }
    const permittedLinks = links.filter(x=>hasLinkRequiredAccess(x))
    return permittedLinks.length > 0
  }

  hasLinkRequiredAccess = (link) => {
    if (user.role !== "basic") {
      return true
    }
    for (const permission of link.requiredPermissions) {
      if (!user.permission[permission]) {
        return false
      }
    }
    return true
  }

  const renderLinks = () => {
    return links.filter(x=>hasLinkRequiredAccess(x)).map((link) => 
      renderLink(link)
    )
  }

  const renderLink = (link) => {
    return (
      <NavLink
        exact
        key={link.link}
        style={linkStyle}
        to={link.link}
        className="sg-mgmt-menu-nav-link block"
        activeClassName="sg-mgmt-menu-nav-link-active"
      >
        <div
        className=" cursor-pointer flex items-center sg-mgmt-menu-item"
        >
          <div className="cursor-pointer flex items-center justify-center h-[50px] shrink-0 w-[50px]">
          </div>
          <div className="text-left text-base flex-grow sg-mgmt-menu-item-title font-normal font-['Roboto']">{link.title}</div>
        </div>
      </NavLink>
    )
  }

  if(hasRequiredAccess()){
    return (
      <div className="">
        <div className="sg-mgmt-menu-nav-link">
          <div
            className="flex items-center sg-mgmt-menu-item"
            onClick={() => setOpen(!open)}
          >
            <div className="cursor-pointer flex items-center justify-center h-[50px] shrink-0 w-[50px]">
              <img
                className="w-[22px] h-auto sg-mgmt-menu-item-icon  sg-mgmt-menu-item-icon-inactive"
                src={iconUrl}
                alt={`${title} nav icon`}
              />
            </div>
            <div className="text-left text-base flex-grow sg-mgmt-menu-item-title font-medium font-['Roboto']">{title}</div>
            <div
              className="p-2"
            >
              <img
                className={` ${open ? "rotate-180" : ""}`}
                src={"/images/icons/expand-arrow.svg"}
              />
            </div>
          </div>
        </div>
        <div className="bg-white">
          <div className="bg-gradient-to-l from-sky-50 to-slate-5">
            {open ? (
              renderLinks()
            ):""}
          </div>
        </div>
      </div>
    );
  }
  return (<div></div>)

};

EventMenuDropdown.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  links: PropTypes.array,
};

EventMenuDropdown.defaultProps = {
  links: [],
  title: "Tile Tile",
  icon: "tile",
}

export default EventMenuDropdown;
