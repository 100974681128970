export default {
  email: "",
  name_first: "",
  name_last: "",
  cc_list: "",
  bcc_list: "",
  custom_tag_1: "",
  custom_tag_2: "",
  custom_tag_3: "",
  custom_tag_4: "",
  custom_tag_5: ""
};
