import React, { useContext, useEffect, useState } from "react";

import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import ContentFrame from "@shared/ContentFrame";
import Loading from "@shared/Loading";
import Tabs from "@shared/tabs/Tabs";

import WidgetBoard from "./WidgetBoard";

const Dashboard = () => {
  const [selectedBoard, setSelectedBoard] = useState("default");
  const [loaded, setLoaded] = useState(false);
  const { apiRoot, event } = useContext(EventContext).values;
  const queryClient = useQueryClient();

  useEffect(() => {
    const board = localStorage.getItem(`${event}-dashboard`);
    if (board) {
      setSelectedBoard(board);
    }
    setLoaded(true); // avoid flash of content if defaulting to user board
  }, [event]);

  const { isPending, error, data } = useQuery({
    queryKey: ["dashboard"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "dashboard"), { signal })
        .then(res => res.data)
        .catch(err => {
          alertHttpError(err);
        })
  });

  const metaQuery = useQuery({
    queryKey: ["participantMeta"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/participants/meta"), { signal })
        .then(res => res.data)
        .catch(error => {
          alertHttpError(error);
        })
  });

  const refreshDashboard = useMutation({
    mutationFn: () => {
      return true;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("dashboard");
    }
  });

  const onUpdate = () => {
    refreshDashboard.mutate();
  };

  const updateSelectedBoard = board => {
    setSelectedBoard(board);
    localStorage.setItem(`${event}-dashboard`, board);
  };

  // perform optimistic update on react-query cache
  const optimisticUpdateWidgets = (active, over) => {
    queryClient.setQueryData(["dashboard"], prev => {
      const activeBoard = selectedBoard === "user" ? prev.widget_board_user : prev.widget_board_default;
      const newWidgets = activeBoard.widgets.map(widget => {
        if (widget.id === active.id) {
          return {
            ...widget,
            widget_position: activeBoard.widgets.find(w => w.id === over.id).widget_position
          };
        }
        if (widget.id === over.id) {
          return {
            ...widget,
            widget_position: activeBoard.widgets.find(w => w.id === active.id).widget_position
          };
        }
        return widget;
      });

      let returnObj = { ...prev };
      returnObj[`widget_board_${selectedBoard}`] = {
        ...activeBoard,
        widgets: newWidgets
      };

      return returnObj;
    });
  };

  const renderBody = () => {
    if (!loaded) {
      return (
        <ContentFrame>
          <Loading />
        </ContentFrame>
      );
    }

    if (isPending || metaQuery.isPending) {
      return (
        <ContentFrame>
          <Loading />
        </ContentFrame>
      );
    }

    if (error || metaQuery.error) {
      return (
        <ContentFrame>
          <div className="mt-4">
            <h3>Error loading dashboard</h3>
          </div>
        </ContentFrame>
      );
    }

    const selectedWidgetBoard = selectedBoard === "user" ? data.widget_board_user : data.widget_board_default;
    const widgetBoardValues = selectedBoard === "user" ? data.values_user : data.values_default;

    return (
      <ContentFrame>
        <WidgetBoard
          metaFields={metaQuery.data}
          onUpdate={onUpdate}
          optimisticUpdateWidgets={optimisticUpdateWidgets}
          selectedBoard={selectedBoard}
          widgetBoard={selectedWidgetBoard}
          widgetBoardValues={widgetBoardValues}
        />
      </ContentFrame>
    );
  };

  return (
    <div className="w-full">
      <Tabs
        tabs={[
          { id: "default", name: "Event Dashboard", onClick: () => updateSelectedBoard("default") },
          { id: "user", name: "My Dashboard", onClick: () => updateSelectedBoard("user") }
        ]}
        activeTabId={selectedBoard}
      />
      {renderBody()}
    </div>
  );
};

export default Dashboard;
