import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";
import { alertError, alertHttpError } from "@shared/Alerts";
import {
  renderCancelButton,
  renderSubmitButton,
  renderTextField
} from "@shared/FormUtils";
import Stack from '@mui/material/Stack';
import EventContext from "@event/EventContext";
import EventAnalyticsBlankExternalReport from "./EventAnalyticsBlankExternalReport";

const EventAnalyticsExternalReportsForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackSuccess, cancel, externalReport } = props;

  const isEdit = () => {
    return externalReport && externalReport.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-delivery-edit",
        formUrl: urljoin(apiRoot, "/external_reports", `/${externalReport.id}`),
        method: "PATCH",
        saveButton: "Save",
        title: "Edit External Report"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-delivery-add",
      formUrl: urljoin(apiRoot, "/external_reports"),
      method: "POST",
      saveButton: "Continue",
      title: "Create External Report"
    };
  })();

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        name: externalReport.name || "",
        url: externalReport.url || ""
      };
    }
    return EventAnalyticsBlankExternalReport;
  };

  const formValidation = values => {
    const errors = {};
    if (values.external_report.name === "") {
      alert("You must enter a name");
      errors.external_report = errors.external_report || {};
      errors.external_report.name = "Required";
    }

    if (values.external_report.url === "") {
      alert("You must enter a URL");
      errors.external_report = errors.external_report || {};
      errors.external_report.url = "Required";
    }

    return errors;
  };

  const renderForm = () => (
    <Formik
      initialValues={{
        external_report: formInitialValues()
      }}
      validate={formValidation}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById(formConfig.formId);
        const formData = new FormData(form);

        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        axios({
          url: formConfig.formUrl,
          method: formConfig.method,
          data: formData
        })
          .then(response => {
            if (response.data.error === null) {
              callbackSuccess();
              setSubmitting(false);
            } else {
              alertError(response.data.error);
              setSubmitting(false);
            }
          })
          .catch(error => {
            alertHttpError(error);
          });
      }}
    >
      {({ isSubmitting }) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <div className="sg-mgmt-form-container">
            <div className="sg-mgmt-form-section">
              {renderTextField("Report Name", "external_report[name]")}
              {renderTextField("Report URL", "external_report[url]")}
            </div>
          </div>
          <Stack direction="row" spacing={2}>
            {renderSubmitButton(formConfig.saveButton, isSubmitting)}
            {renderCancelButton("Back", cancel)}
          </Stack>
        </Form>
      )}
    </Formik>
  );

  return (
    <div>
      <h2>{formConfig.title}</h2>
      {renderForm()}
    </div>
  );
};

EventAnalyticsExternalReportsForm.defaultProps = {
  callbackSuccess: () => {},
  cancel: () => {},
  externalReport: EventAnalyticsBlankExternalReport
};

EventAnalyticsExternalReportsForm.propTypes = {
  callbackSuccess: PropTypes.func,
  cancel: PropTypes.func,
  externalReport: PropTypes.object
};

export default EventAnalyticsExternalReportsForm;
