import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import HousingConfigurationBillingInstructionsTable from "./HousingConfigurationBillingInstructionsTable";

const HousingConfigurationBillingInstructions = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [instructions, setInstructions] = useState([]);
  const [fetched, setFetched] = useState(false);

  const sortInstructions = instructionsToSort => sortBy(instructionsToSort, ["sort_order"]);

  useEffect(() => {
    const fetchInstructions = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/billing_instructions"));
        setInstructions(sortInstructions(result.data));
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchInstructions();
  }, [apiRoot]);

  const addInstruction = instruction => {
    setInstructions(sortInstructions(instructions.concat(instruction)));
  };

  const deleteInstruction = instructionId => {
    setInstructions(sortInstructions(instructions.filter(f => f.id !== instructionId)));
  };

  const updateInstruction = instruction => {
    setInstructions(instructions.map(f => (f.id === instruction.id ? instruction : f)));
  };

  const removeInstruction = instructionId => {
    const postData = { instruction_id: instructionId };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/housing/billing_instructions", `/${instructionId}`),
      method: "DELETE",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        deleteInstruction(response.instruction_id);
        alertSuccess("Successfully deleted billing instruction");
      } else {
        alertError("Failed deleting billing instruction");
      }
    });
  };

  const renderBody = () => {
    if (fetched) {
      return (
        <HousingConfigurationBillingInstructionsTable
          instructions={instructions}
          addInstruction={addInstruction}
          removeInstruction={removeInstruction}
          updateInstruction={updateInstruction}
        />
      );
    }
    return <Loading />;
  };

  return (
    <div>
      <h1>Billing Instructions</h1>
      {renderBody()}
    </div>
  );
};

export default HousingConfigurationBillingInstructions;
