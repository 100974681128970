import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import CommunicationsEmailMailingListEntryForm from "./CommunicationsEmailMailingListEntryForm";

const CommunicationsEmailMailingListEntryModal = props => {
  const {
    entry,
    listId,
    modalVisible,
    participants,
    resetModal,
    title,
    updateFunc
  } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Edit Recipient"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <CommunicationsEmailMailingListEntryForm
            entry={entry}
            listId={listId}
            participants={participants}
            reset={resetModal}
            update={updateFunc}
          />
        </div>
      </div>
    </Modal>
  );
};

CommunicationsEmailMailingListEntryModal.propTypes = {
  entry: PropTypes.object.isRequired,
  listId: PropTypes.number.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  participants: PropTypes.array.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default CommunicationsEmailMailingListEntryModal;
