import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { renderCancelButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const EventDetailsTokenView = props => {
  const { modalVisible, resetModal, title, token } = props;

  Modal.setAppElement("#root");

  const renderToken = () => (
    <div>
      <p>Name: {token.name}</p>
      <p>Token: {token.value}</p>
      <p>IP whitelist: {token.whitelist.toString().replace(",", ", ")}</p>
      <p>TODO: Show permissions enabled</p>
    </div>
  );

  const renderActions = () => (
    <Stack direction="row" spacing={2}>
      {renderCancelButton("Close", resetModal)}
    </Stack>
  );

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          {renderToken()}
          {renderActions()}
        </div>
      </div>
    </Modal>
  );
};

EventDetailsTokenView.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  token: PropTypes.object.isRequired
};

export default EventDetailsTokenView;
