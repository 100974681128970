import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetFilterByMetadata = (props) => {
  const { control, metadataFields, watchers } = props;

  const renderMetadataValueField = () => {
    if (watchers.widget_config.filters.metadata !== "") {
      return (
        <div className="mb-4 mt-4">
          <div className="mb-4 w-full">
            <FormControl fullWidth>
              <FormLabel>Metadata Value</FormLabel>
              <Controller
                name="widget_config[filters][metadata_value]"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <>
                    <TextField {...field} fullWidth onChange={onChange} value={value} variant="outlined" size="small" />
                  </>
                )}
              />
            </FormControl>
          </div>
        </div>
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="mb-4 mt-4">
        <div className="mb-4 w-full">
          <FormControl fullWidth>
            <FormLabel>Filter by Metadata (optional)</FormLabel>
            <Controller
              name="widget_config[filters][metadata]"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  fullWidth
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  size="small"
                  MenuProps={{ disableScrollLock: true }}
                >
                  {metadataFields.map((md) => (
                    <MenuItem key={`md-${md.id}`} value={md.id}>
                      {`${md.label} (${md.slug})`}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
      {renderMetadataValueField()}
    </>
  );
};

WidgetFilterByMetadata.propTypes = {
  control: PropTypes.object.isRequired,
  metadataFields: PropTypes.array.isRequired
};

export default WidgetFilterByMetadata;
