import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetDataSetSelect = props => {
  const { control, dataSets, errors } = props;

  const renderDataSetOptions = () => {
    return dataSets.map(opt => (
      <MenuItem key={`dataset-option-${opt.value}`} value={opt.value}>
        {opt.label}
      </MenuItem>
    ));
  };

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>Data Set</FormLabel>
        <Controller
          name="widget_data_set"
          control={control}
          rules={{ required: "Data set is required" }}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <Select
                {...field}
                fullWidth
                value={value}
                onChange={onChange}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
              >
                {renderDataSetOptions()}
              </Select>
              {errors.widget_data_set === "required" && (
                <div className="py-1 text-red-500">{errors.widget_data_set?.message}</div>
              )}
            </>
          )}
        />
      </FormControl>
    </div>
  );
};

WidgetDataSetSelect.propTypes = {
  control: PropTypes.object.isRequired,
  dataSets: PropTypes.array.isRequired,
  errors: PropTypes.object.isRequired
};

export default WidgetDataSetSelect;
