import axios from "axios";

const railsCsrfToken = () => {
  return document.querySelector("[name=csrf-token]").content;
};

// bare client, attach your own callbacks
export const axiosClient = axios.create({
  headers: {
    common: {
      "X-CSRF-TOKEN": railsCsrfToken()
    }
  }
});

export const request = async ({ ...options }) => {
  const response = await axiosClient(options);
  return response.data;
};
