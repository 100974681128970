import React, { useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from "clsx";
import Dropzone from "react-dropzone";
import { Formik, Form } from "formik";
import Modal from "react-modal";
import urljoin from "url-join";
import { renderTextField, renderCancelButton, renderSubmitButton  } from "@shared/FormUtils";
import { Stack } from "@mui/material";

const MainLogoModal = props => {
  const { config, modalVisible, resetModal, rootUrl } = props;
  const [logo, setLogo] = useState(null);
  const [logoDropped, setLogoDropped] = useState(false);
  const [previewLogoURL, setPreviewLogoURL] = useState(config.logo_url);

  Modal.setAppElement("#root");
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";
  const formId = "sg-mgmt-logo-form";

  const formInitialValues = () => {
    return {
      config: {
        logo: config.logo || "",
        name: config.name || ""
      }
    };
  };

  const onDrop = acceptedFiles => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = e => {
        setLogo(acceptedFiles[0]);
        setLogoDropped(true);
        setPreviewLogoURL(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setLogo(acceptedFiles[0]);
      setLogoDropped(true);
      setPreviewLogoURL(null);
    }
  };

  const dropzoneFilename = () => {
    if (logoDropped) {
      return (
        <>
          File attached:
          <br />
          {logo.name}
        </>
      );
    }
    return <></>;
  };

  const renderLogoPreview = (header = false) => {
    const previewClass = header ? "sg-mgmt-form-tile-preview-header" : "";
    if (logoDropped && previewLogoURL) {
      return (
        <div className="sg-mgmt-form-tile-preview-container">
          <img
            className={clsx("sg-mgmt-banner-logo-preview", previewClass)}
            src={previewLogoURL}
            alt="Logo Preview"
          />
        </div>
      );
    }
    return <></>;
  };

  const renderLogoUpload = () => {
    return (
      <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="config[logo]">
        {({ getRootProps, getInputProps, isDragActive }) => {
          return (
            <div
              {...getRootProps()}
              className={clsx(
                "dropzone",
                "sg-mgmt-form-input-dropzone-tile",
                {
                  "dropzone--isActive": isDragActive
                }
              )}
            >
              <input {...getInputProps()} />
              <div className="sg-mgmt-form-input-dropzone-tile-prompt">
                Drag and Drop
                <br />
                or Click to Choose File
              </div>
              <div className="sg-mgmt-form-input-dropzone-tile-filename">
                {dropzoneFilename()}
              </div>
            </div>
          );
        }}
      </Dropzone>
    );
  };

  const renderForm = () => {
    return (
      <div className="sg-mgmt-event-container">
        <Formik
          initialValues={formInitialValues()}
          onSubmit={() => {
            const form = document.getElementById(formId);
            const formData = new FormData(form);
            if (document.body.dataset.environment !== "test") {
              const token = document.querySelector("[name=csrf-token]").content;
              axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
            }

            if (logo) {
              formData.set("config[logo]", logo);
            }

            axios({
              url: urljoin(rootUrl, "/events/logo"),
              method: "POST",
              data: formData
            }).then(response => {
              console.log(response);
              window.location.replace(urljoin(rootUrl, "/events"));
            });
          }}
        >
          {({ isSubmitting }) => (
            <Form className="sg-mgmt-form" id={formId}>
              <div className="sg-mgmt-form-container">
                <div className="sg-mgmt-form-section">
                  <div className="sg-mgmt-form-row">
                    <div className="sg-mgmt-form-input-container">
                      <label>Choose logo image</label>
                      {renderLogoUpload()}
                      {renderTextField("Choose Company Name", "config[name]")}

                      {renderLogoPreview()}
                    </div>
                  </div>
                </div>
              </div>
              <Stack direction="row" spacing={2}>
                {renderSubmitButton("Save Details", isSubmitting)}
                {renderCancelButton("Cancel", resetModal)}
              </Stack>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel="Details"
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Details</div>
        <div className="sg-mgmt-modal-content">{renderForm()}</div>
      </div>
    </Modal>
  );
};

MainLogoModal.propTypes = {
  config: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired
};

export default MainLogoModal;
