import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Field, Formik } from "formik";
import urljoin from "url-join";
import { alertError, alertSuccess } from "@shared/Alerts";
import { renderTextField, renderButton} from "@shared/FormUtils";
import EventContext from "@event/EventContext";
import HousingConfigurationBlankBillingInstruction from "./HousingConfigurationBlankBillingInstruction";
import HousingConfigurationBillingInstructionsForm from "./HousingConfigurationBillingInstructionsForm";

const HousingConfigurationBillingInstructionsTable = props => {
  const {
    addInstruction,
    instructions,
    removeInstruction,
    updateInstruction
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [instructionAdd, setInstructionAdd] = useState(false);
  const [instructionEdit, setInstructionEdit] = useState({ id: null });

  const revealAddInstructionForm = () => {
    setInstructionAdd(true);
  };

  const hideAddInstructionForm = () => {
    setInstructionAdd(false);
  };

  const addSuccess = response => {
    alertSuccess("Instruction saved successfully");
    addInstruction(response.data.instruction);
    setInstructionAdd(false);
  };

  const addFailure = response => {
    alertError(`Failed adding instruction: ${response.data.error}`);
  };

  const editInstruction = id => {
    const instruction = instructions.find(f => f.id === id);
    if (instruction) {
      setInstructionEdit(instruction);
    }
  };

  const resetEdit = () => {
    setInstructionEdit({ id: null });
  };

  const renderEditInstructionLink = instruction => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-instruction-type"
      onClick={() => {
        editInstruction(instruction.id);
      }}
    >
      Edit
    </span>
  );

  const renderDeleteInstructionLink = instruction => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-instruction-type"
      onClick={() => {
        removeInstruction(instruction.id);
      }}
    >
      &nbsp;|&nbsp;Delete
    </span>
  );

  const renderCancelInstructionLink = () => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-instruction-type"
      onClick={() => {
        resetEdit();
      }}
    >
      Cancel
    </span>
  );

  const renderUpdateInstructionLink = submitForm => (
    <span
      className="sg-mgmt-link sg-mgmt-reg-instruction-type"
      onClick={() => {
        submitForm();
      }}
    >
      Save
    </span>
  );

  const renderInstructionInfo = instruction => (
    <tr key={instruction.gid}>
      <td className="py-2 px-4 text-sm align-top border">{instruction.instruction_text}</td>
      <td className="py-2 px-4 text-sm align-top border">{instruction.sort_order}</td>
      <td className="py-2 px-4 text-sm align-top border">
        {renderEditInstructionLink(instruction)}
        {renderDeleteInstructionLink(instruction)}
      </td>
    </tr>
  );

  const renderFormField = name => (
    <Field
      key={name}
      className="sg-mgmt-form-input"
      type="text"
      name={`instruction[${name}]`}
      autoComplete="off"
    />
  );

  const renderInstructionEdit = instruction => (
    <Formik
      key={`edit-instruction-${instruction.id}`}
      initialValues={{
        instruction: {
          instruction_text: instruction.instruction_text,
          sort_order: instruction.sort_order
        }
      }}
      onSubmit={(values, { setSubmitting }) => {
        const form = document.getElementById("sg-mgmt-reg-instruction-edit");
        const formData = new FormData(form);
        const token = document.querySelector("[name=csrf-token]").content;
        axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

        axios({
          url: urljoin(apiRoot, "/housing/billing_instructions", `/${instruction.id}`),
          method: "PATCH",
          data: formData
        }).then(response => {
          if (response.data.error === null) {
            updateInstruction(response.data.instruction);
            resetEdit();
            setSubmitting(false);
          } else {
            alertError(response.data.error);
            setSubmitting(false);
          }
        });
        // .catch(error => {
        //   alertHttpError(error);
        // });
      }}
    >
      {({ submitForm }) => (
        <tr key={instruction.gid}>
          <td className="py-2 px-4 text-sm align-top border">
            {renderFormField("instruction_text")}
          </td>
          <td className="py-2 px-4 text-sm align-top border">
            {renderFormField("sort_order")}
          </td>
          <td className="py-2 px-4 text-sm align-top border">
            {renderCancelInstructionLink()}
            {renderUpdateInstructionLink(submitForm)}
          </td>
        </tr>
      )}
    </Formik>
  );

  const renderField = instruction => {
    if (instruction.id === instructionEdit.id) {
      return renderInstructionEdit(instruction);
    }
    return renderInstructionInfo(instruction);
  };

  const renderInstructions = () => {
    const columns = [
      "Instruction Text",
      "Sort Order",
      "Actions"
    ];
    return (
      <form className="sg-mgmt-form" id="sg-mgmt-reg-instruction-edit">
        <table className="table-auto">
          <thead>
            <tr>
              {columns.map(col => (
                <th key={col} className="py-2 px-4 border">
                  {col}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>{instructions.map(instruction => renderField(instruction))}</tbody>
        </table>
      </form>
    );
  };

  const renderNoInstructions = () => {
    if (instructions.length === 0) {
      return <p>No instructions created.</p>;
    }
    return <></>;
  };

  const renderAddInstruction = () => {
    if (!instructionAdd) {
      return (
        <div className="pt-2">
          {renderButton(
            "Add Instruction",
            revealAddInstructionForm
          )}
        </div>
      );
    }
    return <></>;
  };

  const renderAddInstructionForm = () => {
    if (instructionAdd) {
      return (
        <div className="sg-mgmt-reg-instruction-form-container">
          <HousingConfigurationBillingInstructionsForm
            instruction={HousingConfigurationBlankBillingInstruction}
            callbackFailure={addFailure}
            callbackSuccess={addSuccess}
            cancelButton={hideAddInstructionForm}
          />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div className="sg-mgmt-details-section">
      <h2>Billing Instructions</h2>
      {renderInstructions()}
      {renderNoInstructions()}
      {renderAddInstructionForm()}
      {renderAddInstruction()}
    </div>
  );
};

HousingConfigurationBillingInstructionsTable.propTypes = {
  addInstruction: PropTypes.func.isRequired,
  instructions: PropTypes.array.isRequired,
  removeInstruction: PropTypes.func.isRequired,
  updateInstruction: PropTypes.func.isRequired
};

export default HousingConfigurationBillingInstructionsTable;
