import React from "react";
import PropTypes from "prop-types";

import { renderCreateButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatDateFriendly } from "@shared/TimeUtils";

const HousingConfigurationInfo = props => {
  const {
    configuration,
    fetched,
    goEdit,
    goInstructions,
    instructions
  } = props;

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderConfiguration = () => {
    if (fetched) {
      return (
        <div className="my-3">
          <div className="sg-mgmt-modal-view-section">
            <h2>Housing Dates</h2>
            {renderField(
              "Date Begin",
              formatDateFriendly(configuration.date_begin, null)
            )}
            {renderField(
              "Date End",
              formatDateFriendly(configuration.date_end, null)
            )}
            {renderField(
              "Default Check-in Date",
              formatDateFriendly(configuration.check_in_date_default, null)
            )}
            {renderField(
              "Default Check-out Date",
              formatDateFriendly(configuration.check_out_date_default, null)
            )}
          </div>
          {/* <div className="sg-mgmt-modal-view-section"> */}
          {/*   <h2>Booking Window</h2> */}
          {/*   {renderField( */}
          {/*     "Booking Date Begin", */}
          {/*     formatDateFriendly(configuration.booking_date_begin, null) */}
          {/*   )} */}
          {/*   {renderField( */}
          {/*     "Booking Date Start Time", */}
          {/*     formatTimeOnlyFriendly( */}
          {/*       configuration.booking_time_begin, */}
          {/*       event.time_zone */}
          {/*     ) */}
          {/*   )} */}
          {/*   {renderField( */}
          {/*     "Booking Date End", */}
          {/*     formatDateFriendly(configuration.booking_date_end, null) */}
          {/*   )} */}
          {/*   {renderField( */}
          {/*     "Booking Date End Time", */}
          {/*     formatTimeOnlyFriendly( */}
          {/*       configuration.booking_time_end, */}
          {/*       event.time_zone */}
          {/*     ) */}
          {/*   )} */}
          {/* </div> */}
          <div className="sg-mgmt-modal-view-section">
            <h2>Additional Settings</h2>
            {renderField(
              "Currency",
              `${configuration.currency} (${configuration.currency_symbol})`
            )}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  const renderEditButton = () => {
    return renderCreateButton("Edit", goEdit);
  };

  const renderInstructionsButton = () => {
    return renderCreateButton("Edit Instructions", goInstructions);
  };

  const renderInstructions = () => {
    return (
      <div className="my-3">
        <div className="sg-mgmt-modal-view-section">
          <h2>Billing Instructions</h2>
          {instructions.map(i => (
            <div key={i.id}>&bull;&nbsp;{i.instruction_text}</div>
          ))}
          {instructions.length == 0 ? (
            <div>
              <em>No billing instructions created yet</em>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    );
  };

  return (
    <div>
      <PageHeader text="Housing Settings" />
      <div className="container flex">
        <div className="flex-1">
          {renderEditButton()}
          {renderConfiguration()}
        </div>
        <div className="flex-1">
          {renderInstructionsButton()}
          {renderInstructions()}
        </div>
      </div>
    </div>
  );
};

HousingConfigurationInfo.defaultProps = {
  instructions: []
};

HousingConfigurationInfo.propTypes = {
  configuration: PropTypes.object.isRequired,
  fetched: PropTypes.bool.isRequired,
  goEdit: PropTypes.func.isRequired,
  goInstructions: PropTypes.func.isRequired,
  instructions: PropTypes.array
};

export default HousingConfigurationInfo;
