// Formik field that receives a RegExp as prop and silently rejects input
// attempts that violate the regex.
//
// Prevents typing invalid input, rather than Formik's default approach of
// allowing it and using validations to inform the user of the invalid input.

import React from "react";
import PropTypes from "prop-types";

import { Field, useField, useFormikContext } from "formik";

import { MuiTextField } from "@shared/muiformik/FieldConversions";

const RegexField = props => {
  const { regex } = props;
  const { fieldProperties, ...restProps } = props;
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const onChange = ele => {
    const { value } = ele.target;
    if (regex.test(value)) {
      setFieldValue(field.name, value);
    } else {
      setFieldValue(field.name, value.slice(0, -1));
    }
  };

  return (
    <Field
      component={MuiTextField}
      {...field}
      {...restProps}
      {...fieldProperties}
      size="small"
      selected={field.value || null}
      onChange={onChange}
      fullWidth
    />
  );
};

RegexField.propTypes = {
  fieldProperties: PropTypes.object,
  regex: PropTypes.instanceOf(RegExp).isRequired
};

export default RegexField;
