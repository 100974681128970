import React, { useContext } from "react";
import PropTypes from "prop-types";

import { NavLink, useLocation } from "react-router-dom";

import EventUserContext from "../EventUserContext";

const EventMenuItem = ({ link, icon, title, requiredPermissions, activeLinks = [] }) => {
  const { user } = useContext(EventUserContext);
  const linkStyle = { textDecoration: "none" };
  const iconUrl = `/images/nav/${icon}.svg`;
  const { pathname } = useLocation();

  const hasRequiredAccess = () => {
    if (user.role !== "basic") {
      return true;
    }
    for (const permission of requiredPermissions) {
      if (!user.permission[permission]) {
        return false;
      }
    }
    return true;
  };

  const navIsActive = () => {
    if (activeLinks.length === 0) {
      return pathname === link;
    }
    let isActive = false;
    activeLinks.forEach((link) => {
      if (pathname.includes(link)) isActive = true;
    });
    return isActive;
  };

  if (hasRequiredAccess()) {
    return (
      <NavLink
        activeClassName="sg-mgmt-menu-nav-link-active"
        className="sg-mgmt-menu-nav-link"
        isActive={() => navIsActive()}
        style={linkStyle}
        to={link}
      >
        <div className="sg-mgmt-menu-item flex items-center">
          <div className="flex h-[50px] w-[50px] shrink-0 items-center justify-center">
            <img
              className="sg-mgmt-menu-item-icon sg-mgmt-menu-item-icon-inactive h-auto w-[22px] cursor-pointer"
              src={iconUrl}
              alt={`${title} nav icon`}
            />
          </div>
          <div className="sg-mgmt-menu-item-title font-['Roboto'] text-base font-medium">{title}</div>
        </div>
      </NavLink>
    );
  }
  return <div></div>;
};

EventMenuItem.propTypes = {
  icon: PropTypes.string,
  title: PropTypes.string,
  requiredPermissions: PropTypes.array,
  link: PropTypes.string
};

EventMenuItem.defaultProps = {
  requiredPermissions: [],
  link: "/",
  title: "Tile Tile",
  icon: "tile"
};

export default EventMenuItem;
