import React, { useContext, useState } from "react";

import urljoin from "url-join";

import EventContext from "@event/EventContext";
import GrowlTable from "@shared/GrowlTable";
import { useCustomReports, useRefreshCustomReports } from "@shared/hooks/useCustomReports";
import Loading from "@shared/Loading";

import CustomReportDownload from "./CustomReportDownload";
import CustomReportEdit from "./CustomReportEdit";
import CustomReportNew from "./CustomReportNew";

const CustomReports = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [page, setPage] = useState("home");
  const [editReportId, setEditReportId] = useState(null);
  const reports = useCustomReports(apiRoot, event.id);
  const refreshReports = useRefreshCustomReports(event.id);

  /* eslint-disable react/prop-types, react/display-name */
  const columns = [
    {
      field: "name",
      headerName: "Report Name"
    },
    {
      field: "report_type",
      headerName: "Report Type",
      renderCell: (params) => {
        return reportTypeLabel(params.row.report_type)
      }
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      getActions: (params) => [renderDownloadLink(params.row), renderEditLink(params.row), renderDeleteLink(params.row)]
    }
  ];

  const reportTypeLabel = (report_type) => {
    switch (report_type) {
      case "session":
        return "Sessions";
      case "event_participant":
        return "Event Participants";
      default:
        return "Unknown";
    }
  };

  // const renderDirectDownloadLink = (report) => {
  //   return (
  //     <a className="cursor-pointer" href={urljoin(apiRoot, "/reports", `/${report.id}`, "/xlsx")}>
  //       Download
  //     </a>
  //   );
  // };

  const renderDownloadLink = (report) => {
    return <CustomReportDownload url={urljoin(apiRoot, "/reports", `/${report.id}`, "/download")} />;
  };

  const renderEditLink = (report) => {
    return (
      <span
        className="cursor-pointer"
        onClick={() => {
          setEditReportId(report.id);
          setPage("edit");
        }}
      >
        Edit
      </span>
    );
  };

  const renderDeleteLink = (_report) => {
    return (
      <span
        className="cursor-pointer"
        onClick={() => {
          alert("Coming soon")
        }}
      >
        Delete
      </span>
    );
  };

  const renderReports = () => {
    return <GrowlTable tableName="Custom Reports" columns={columns} items={reports.data.reports} />;
  };

  const renderHome = () => {
    return (
      <>
        <button className="sg-mgmt-btn" onClick={() => setPage("new")}>
          New Report
        </button>
        <div>{renderReports()}</div>
      </>
    );
  };

  const renderNew = () => {
    return <CustomReportNew goBack={() => setPage("home")} refreshReports={refreshReports} />;
  };

  const renderEdit = () => {
    return <CustomReportEdit goBack={() => setPage("home")} refreshReports={refreshReports} reportId={editReportId} />;
  };

  const renderContent = () => {
    switch (page) {
      case "home":
        return renderHome();
      case "new":
        return renderNew();
      case "edit":
        return renderEdit();
      default:
        throw new Error("invalid reports page");
    }
  };

  if (reports.isLoading) {
    return <Loading />;
  }

  if (reports.isError) {
    return <div>Error: {reports.error.message}</div>;
  }

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h1>Reports</h1>
        {renderContent()}
      </div>
    </div>
  );
};

export default CustomReports;
