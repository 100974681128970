import React, { useContext } from "react";

import { PieChart } from "@mui/x-charts/PieChart";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";

import PieWidgetSettings from "./PieWidgetSettings";

const PieWidget = () => {
  const { editMode, values, widget } = useContext(WidgetContext);
  const dataSet = widget?.widget_data_set;

  const labelText = text => {
    return text.length == 0 ? "(blank)" : text;
  };

  const colorScheme = () => {
    switch (widget?.widget_config?.pie?.colorScheme) {
      case "yellow":
        return ["#F89728", "#D2450F", "#FDB92A", "#A9360A", "#F9A825", "#FFC107", "#FF9800", "#FF5722"];
      case "blue":
        return ["#2196F3", "#1A206C", "#1B5EC1", "#90CAF9", "#64B5F6", "#42A5F5", "#1976D2", "#0D47A1"];
      case "mixed":
        return ["#F89728", "#D2450F", "#2196F3", "#1A206C", "#FDB92A", "#A9360A", "#90CAF9", "#64B5F6"];
      default:
        return ["#FF6633", "#FFB399", "#FF33FF", "#FFFF99", "#00B3E6"];
    }
  };

  const renderPie = () => {
    const series = [
      {
        data: Object.entries(values).map(val => ({ id: labelText(val[0]), value: val[1], label: labelText(val[0]) })),
        innerRadius: 20,
        paddingAngle: 0
      }
    ];

    return (
      <div className="align-center mr-auto" style={{ width: "400px" }}>
        <PieChart
          colors={colorScheme()}
          series={series}
          slotProps={{
            legend: {
              direction: "column",
              labelStyle: { fontSize: 11 },
              itemMarkHeight: 15,
              itemMarkWidth: 15,
              position: {
                vertical: "middle",
                horizontal: "right"
              }
            }
          }}
          sx={{
            "& .MuiChartsLegend-mark": {
              rx: 7,
              ry: 7
            },
            "& .MuiChartsLegend-series": {
              textTransform: "uppercase"
            }
          }}
          height={175}
        />
      </div>
    );
  };

  const renderContent = () => {
    if (!dataSet) {
      return <div>No data set selected</div>;
    }
    return (
      <div className="justify-begin mt-2 flex" style={{ height: "175px" }}>
        {renderPie()}
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Pie Settings" settingsComponent={PieWidgetSettings} />;
  };

  return (
    <WidgetContentFrame>
      <WidgetHeader />
      {renderContent()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default PieWidget;
