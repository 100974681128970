import { format, zonedTimeToUtc, utcToZonedTime } from "date-fns-tz";

// JavaScript date objects are fucking garbage. Forcing dates into the local timezone of your browser.
// that doesn't make me deal with JS date objects.
// Pass in a date in ISO format YYYY-MM-dd
const dateObjectFuckTimezones = (datestamp) => {
  return new Date(`${datestamp}T00:00:00`);
};

const formatDate = (timestamp, timezone) => {
  if (!timestamp) {
    return "--";
  }

  const dateString = "yyyy-MM-dd";

  return `${format(zonedTimeToUtc(timestamp, timezone), dateString, {
    timeZone: timezone
  })}`;
};

const formatDateFriendly = (timestamp, timezone) => {
  if (!timestamp) {
    return "--";
  }

  const dateString = "MMM d, yyyy";

  return `${format(zonedTimeToUtc(timestamp, timezone), dateString, {
    timeZone: timezone
  })}`;
};

const formatDateFriendlyShort = (timestamp, timezone) => {
  if (!timestamp) {
    return "--";
  }

  const dateString = "MMM d";

  return `${format(zonedTimeToUtc(timestamp, timezone), dateString, {
    timeZone: timezone
  })}`;
};

const formatTimeUtcToZone = (timestamp, timezone, meridian = true) => {
  if (!timestamp) {
    return "--";
  }

  const timeString = meridian ? "yyyy-MM-dd h:mm aaa zzz" : "yyyy-MM-dd HH:mm zzz";

  const timeObj = utcToZonedTime(timestamp, timezone);

  const formatted = `${format(timeObj, timeString, {
    timeZone: timezone
  })}`;

  return formatted;
};

const formatTime = (timestamp, timezone, meridian = true) => {
  if (!timestamp) {
    return "--";
  }

  const timeString = meridian ? "yyyy-MM-dd h:mm aaa zzz" : "yyyy-MM-dd HH:mm zzz";

  const timeObj = zonedTimeToUtc(timestamp, timezone);

  const formatted = `${format(timeObj, timeString, {
    timeZone: timezone
  })}`;

  return formatted;
};

const formatTimeObj = (timeObj, timezone, meridian = true) => {
  if (!timeObj) {
    return "--";
  }

  const timeString = meridian ? "yyyy-MM-dd h:mm aaa" : "yyyy-MM-dd HH:mm";

  return `${format(timeObj, timeString, {
    timeZone: timezone
  })}`;
};

const formatTimeFriendly = (timestamp, timezone, meridian = true) => {
  if (!timestamp) {
    return "--";
  }

  const timeString = meridian ? "MMM d, yyyy h:mm aaa zzz" : "MMM d, yyyy HH:mm zzz";

  return `${format(zonedTimeToUtc(timestamp, timezone), timeString, {
    timeZone: timezone
  })}`;
};

const formatTimeOnly = (timestamp, timezone, meridian = true) => {
  if (!timestamp) {
    return "--";
  }

  const timeString = meridian ? "h:mm aaa zzz" : "HH:mm zzz";

  return `${format(zonedTimeToUtc(timestamp, timezone), timeString, {
    timeZone: timezone
  })}`;
};

const formatTimeOnlyFriendly = (timestamp, timezone, meridian = true) => {
  if (!timestamp) {
    return "--";
  }

  const timeString = meridian ? "h:mm aaa zzz" : "yyyy HH:mm zzz";

  return `${format(zonedTimeToUtc(timestamp, timezone), timeString, {
    timeZone: timezone
  })}`;
};
export {
  dateObjectFuckTimezones,
  formatDate,
  formatDateFriendly,
  formatDateFriendlyShort,
  formatTime,
  formatTimeObj,
  formatTimeUtcToZone,
  formatTimeFriendly,
  formatTimeOnly,
  formatTimeOnlyFriendly
};
