import React from "react";
import PropTypes from "prop-types";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import clsx from "clsx";

const Bubble = (props) => {
  const { colorBack, colorText, label, marginLeft, marginRight, scaleFactor, size, twClasses, value } = props;

  const renderDownloadBadge = () => {
    const { downloadLink } = props;
    const badgeSize = Math.round(size * scaleFactor);

    if (downloadLink) {
      return (
        <div
          className="number-circle-badge absolute bottom-0 right-0 overflow-hidden text-center"
          style={{
            backgroundColor: "#1ba310",
            borderRadius: Math.round(badgeSize * 0.5),
            color: "#ffffff",
            fontSize: Math.round(badgeSize * 0.5),
            lineHeight: `${badgeSize}px`,
            height: badgeSize,
            width: badgeSize
          }}
        >
          <FontAwesomeIcon icon={faFileDownload} />
        </div>
      );
    }
    return <></>;
  };

  return (
    <div
      className="number-circle-container relative"
      style={{
        marginLeft: marginLeft,
        marginRight: marginRight,
        height: size,
        width: size
      }}
    >
      <div
        className={clsx("number-circle flex items-center justify-center overflow-hidden text-center", twClasses)}
        style={{
          backgroundColor: colorBack,
          color: colorText,
          borderRadius: Math.round(size * 0.5),
          height: size,
          width: size
        }}
      >
        <div>
          <div style={{ fontSize: Math.round(size * scaleFactor) }}>{value}</div>
          <div className="px-3 leading-tight" style={{ fontSize: Math.round(Math.max(size * 0.1, 10)) }}>
            {label}
          </div>
        </div>
      </div>
      {renderDownloadBadge()}
    </div>
  );
};

Bubble.defaultProps = {
  colorBack: "#D2450F",
  colorText: "#ffffff",
  downloadLink: null,
  label: "",
  marginLeft: 4,
  marginRight: 4,
  scaleFactor: 0.2666,
  size: 150,
  twClasses: "",
  value: 0
};

Bubble.propTypes = {
  colorBack: PropTypes.string,
  colorText: PropTypes.string,
  downloadLink: PropTypes.string,
  label: PropTypes.string,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  scaleFactor: PropTypes.number,
  size: PropTypes.number,
  twClasses: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Bubble;
