import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from "react-router-dom";
import ErrorBoundary from "@shared/ErrorBoundary";
import MuiAlert from "@shared/MuiAlert";
import Main from "./Main";

const MainApp = props => {
  return (
    <ErrorBoundary>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3500}
        Components={{
          mui: MuiAlert
        }}
      >
        <BrowserRouter>
          <Main {...props} />
        </BrowserRouter>
      </SnackbarProvider>
    </ErrorBoundary>
  );
};

MainApp.propTypes = {
  config: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default MainApp;
