import React, { useContext, useEffect, useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventAnalyticsExternalReportForm from "@event/analytics/reports/EventAnalyticsExternalReportForm";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderButton } from "@shared/FormUtils";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { isDeveloper } from "@shared/UserUtils";

import StandardReportsParticipants from "./StandardReportsParticipants";
import StandardReportsSessions from "./StandardReportsSessions";

const StandardReports = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [addExternal, setAddExternal] = useState(false);
  const [editExternal, setEditExternal] = useState(false);
  const [fetched, setFetched] = useState(false);
  const [reports, setReports] = useState([]);
  const [historyDateTime, setHistoryDateTime] = useState(new Date());
  const confirm = useConfirm();

  useEffect(() => {
    const fetchReports = async () => {
      const result = await axios(urljoin(apiRoot, "/external_reports"));
      setReports(result.data.reports);
      setFetched(true);
    };

    fetchReports();
  }, [apiRoot]);

  const renderAddButton = () => {
    if (isDeveloper(user)) {
      return renderButton("Add External Report", () => setAddExternal(true));
    }
    return <></>;
  };

  const returnToReports = () => {
    setAddExternal(false);
  };

  const finishEdit = () => {
    setEditExternal(false);
    returnToReports();
  };

  const editReport = (report) => {
    setEditExternal(report);
  };

  const renderAttendeeReportLink = () => (
    <div>
      <a href={`/reporting/events/${event.slug}/attendees`} className="sg-mgmt-link" data-turbolinks="false">
        Registration Report
      </a>
    </div>
  );

  const renderParticipantsReportLink = () => (
    <div>
      <a href={`/reporting/events/${event.slug}/participants`} className="sg-mgmt-link" data-turbolinks="false">
        Participants Report
      </a>
    </div>
  );

  const renderHistoryParticipantsReportLink = () => (
    <div>
      <a href={`/reporting/events/${event.slug}/participants/changes`} className="sg-mgmt-link" data-turbolinks="false">
        Participants History Report
      </a>
    </div>
  );

  const renderParticipantChangeReportLink = () => (
    <div>
      <a
        href={`/reporting/events/${event.slug}/participants/changes_deprecated`}
        className="sg-mgmt-link"
        data-turbolinks="false"
      >
        Participant Change Report
      </a>
    </div>
  );

  const renderAttendanceCsvLink = () => (
    <div>
      <a
        href={`/reporting/events/${event.slug}/sessions/attendance?format=csv`}
        className="sg-mgmt-link"
        data-turbolinks="false"
      >
        Session Registration (export)
      </a>
    </div>
  );

  const renderTimezoneReportLink = () => (
    <div>
      <a href={`/reports/events/${event.slug}/timezones`} className="sg-mgmt-link" data-turbolinks="false">
        Timezone Report
      </a>
    </div>
  );

  const renderExecMeetingReport = () => (
    <div>
      <a href={`/reports/events/${event.slug}/exec_1on1`} className="sg-mgmt-link" data-turbolinks="false">
        Exec 1:1 Meetings Report
      </a>
    </div>
  );

  const renderObservabilityLink = () => (
    <div>
      <a href={`/reports/events/${event.slug}/observability`} className="sg-mgmt-link" data-turbolinks="false">
        Observability Report
      </a>
    </div>
  );

  const renderSessionsReportLink = () => (
    <div>
      <a href={`/reports/events/${event.slug}/sessions`} className="sg-mgmt-link" data-turbolinks="false">
        Session List
      </a>
    </div>
  );

  const renderSurveysReportLink = () => (
    <div>
      <a href={`/reports/events/${event.slug}/surveys`} className="sg-mgmt-link" data-turbolinks="false">
        Survey Report
      </a>
    </div>
  );

  const renderStaticReports = () => (
    <div>
      {renderAttendeeReportLink()}
      {renderParticipantsReportLink()}
      {renderHistoryParticipantsReportLink()}
      {renderParticipantChangeReportLink()}
      {renderExecMeetingReport()}
      {renderAttendanceCsvLink()}
      {renderSessionsReportLink()}
      {renderSurveysReportLink()}
      {renderTimezoneReportLink()}
      {renderObservabilityLink()}
    </div>
  );

  const deleteReport = (report) => {
    setReports(reports.filter((r) => r.id !== report.id));
  };

  const performDelete = (report) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/external_reports", `/${report.id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deleteReport(report);
          alertSuccess("Report deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const confirmDelete = (report) => {
    confirm({
      title: "Confirm delete",
      description: "Are you sure you want to delete this external report?"
    })
      .then(() => {
        performDelete(report);
      })
      .catch((err) => {
        alertError(err);
      });
  };

  const renderParticipantHistory = () => {
    return (
      <div className="mt-4">
        <h2>Participant History Report</h2>
        <a
          href={`/reporting/events/${event.slug}/participants/changes`}
          className="sg-mgmt-link"
          data-turbolinks="false"
        >
          Download All Changes
        </a>
        <div className="mt-2 flex">
          <div className="mr-1 pt-2">Changes Since:</div>
          <div className="w-1/8">
            <DateTimePicker
              className="sg-mgmt-form-input"
              selected={historyDateTime}
              onChange={(date) => {
                setHistoryDateTime(date);
              }}
              dateFormat="MMMM d, yyyy - h:mm aa"
            />
          </div>
          <div className="ml-2 pt-2">
            <a
              href={`/reporting/events/${event.slug}/participants/changes?from=${Date.parse(historyDateTime)}`}
              className="sg-mgmt-link"
              data-turbolinks="false"
            >
              Download
            </a>
          </div>
        </div>
      </div>
    );
  };

  const renderExternalReports = () => {
    return (
      <div>
        {reports.map((r) => (
          <div key={r.gid}>
            <a href={r.url} className="sg-mgmt-link" data-turbolinks="false">
              {r.name}
            </a>
            {isDeveloper(user) ? (
              <>
                &nbsp;(
                <span className="sg-mgmt-link" onClick={() => editReport(r)}>
                  Edit
                </span>
                ) &nbsp;(
                <span className="sg-mgmt-link" onClick={() => confirmDelete(r)}>
                  Delete
                </span>
                )
              </>
            ) : (
                <></>
              )}
          </div>
        ))}
      </div>
    );
  };

  const renderReports = () => {
    return (
      <>
        {renderExternalReports()}
        <StandardReportsParticipants />
        <StandardReportsSessions />
        {/* {renderParticipantHistory()} */}
      </>
    );
  };

  const renderContent = () => {
    if (!fetched) return <Loading />;

    if (addExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm cancel={returnToReports} callbackSuccess={returnToReports} />
        </>
      );
    }

    if (editExternal) {
      return (
        <>
          <EventAnalyticsExternalReportForm
            cancel={finishEdit}
            callbackSuccess={finishEdit}
            externalReport={editExternal}
          />
        </>
      );
    }

    return renderReports();
  };

  return (
    <div>
      <PageHeader text="Reports" />
      {renderAddButton()}
      {renderContent()}
    </div>
  );
};

export default StandardReports;
