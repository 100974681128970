import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import PageHeader from "@shared/PageHeader";

import HousingHotel from "./HousingHotel";
import HousingHotelRoomTypes from "./HousingHotelRoomTypes";
import HousingHotelsIndex from "./HousingHotelsIndex";

const HousingHotels = props => {
  const { forceUpdate } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [hotels, setHotels] = useState([]);
  const [config, setConfig] = useState({});
  const [page, setPage] = useState("index");
  const [editHotelId, setEditHotelId] = useState(null);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/hotels"));
        setHotels(result.data.hotels);
        setConfig(result.data.configuration);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHotels();
  }, [apiRoot]);

  const goToPage = p => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("index");
  }, [forceUpdate]);

  const sortHotels = hotelsToSort => sortBy(hotelsToSort, ["name"]);

  const addHotel = hotel => {
    setHotels(sortHotels(hotels.concat(hotel)));
  };

  const updateHotels = hotel => {
    const newHotels = hotels.map(h => (h.gid === hotel.gid ? hotel : h));
    setHotels(newHotels);
  };

  const renderPage = () => {
    switch (page) {
      case "index":
        return (
          <HousingHotelsIndex
            addHotel={addHotel}
            config={config}
            setEditHotelId={setEditHotelId}
            fetched={fetched}
            hotels={hotels}
            goEdit={() => goToPage("edit")}
            goRooms={() => goToPage("room_types")}
            updateHotels={updateHotels}
          />
        );
      case "edit":
        return (
          <HousingHotel
            config={config}
            editHotelId={editHotelId}
            goIndex={() => goToPage("index")}
            setEditHotelId={setEditHotelId}
            updateHotels={updateHotels}
          />
        );
      case "room_types":
        return (
          <HousingHotelRoomTypes
            config={config}
            hotel={hotels.find(ele => ele.id === editHotelId)}
            goIndex={() => goToPage("index")}
            setEditHotelId={setEditHotelId}
          />
        );
      default:
        throw new Error("invalid housing page");
    }
  };

  return (
    <div>
      <PageHeader text="Hotels" />
      {renderPage()}
    </div>
  );
};

HousingHotels.propTypes = {
  forceUpdate: PropTypes.any.isRequired
};

export default HousingHotels;
