import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

import { alertError, alertSuccess } from "@shared/Alerts";

import HousingBlankBooking from "./HousingBlankBooking";
import HousingBookingForm from "./HousingBookingForm";

const HousingBookingModal = props => {
  const {
    blocks,
    booking,
    bookings,
    config,
    deleteBooking,
    hotels,
    instructions,
    modalVisible,
    participants,
    resetModal,
    title,
    updateFunc
  } = props;

  Modal.setAppElement("#root");

  const success = response => {
    alertSuccess("Booking updated successfully");
    updateFunc(response.data.booking);
    resetModal();
  };

  const failure = response => {
    alertError(response.data.error);
    updateFunc(response.data.booking);
    resetModal();
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingBookingForm
            blocks={blocks}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            config={config}
            deleteBooking={deleteBooking}
            booking={booking}
            bookings={bookings}
            hotels={hotels}
            instructions={instructions}
            participants={participants}
          />
        </div>
      </div>
    </Modal>
  );
};

HousingBookingModal.defaultProps = {
  booking: HousingBlankBooking,
  deleteBooking: () => { }
};

HousingBookingModal.propTypes = {
  blocks: PropTypes.array.isRequired,
  booking: PropTypes.object,
  bookings: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  deleteBooking: PropTypes.func,
  hotels: PropTypes.array.isRequired,
  instructions: PropTypes.array.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  participants: PropTypes.array.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default HousingBookingModal;
