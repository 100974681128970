import { Field, Formik, Form } from "formik";
import React, {useContext, useEffect, useState } from "react";
import {
  renderTextField,
} from "@shared/FormUtils";
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';

import {CSS} from '@dnd-kit/utilities';
import { getParticipantFields, getQuestionTypes } from "./utils/QuestionUtils";
import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
const FormsFormPageQuestionForm = ({
  question,
  option,
  index,
  question_key,
  removeOption,
  formikProps
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: option.id});
    
  useEffect(() => {
    if(!option.id){
      option.id = uuidv4();
      formikProps.setFieldValue(`${question_key}[options][${index}]`, option);
    }
  },[]);
    
  function uuidv4() {
    return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
      (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
  }

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const handleDragOptionsEnd = (event) => {
    const {active, over} = event;
    if (active.id !== over.id) {
      const items = question.options;
      const oldIndex = items.indexOf(x=> x.id == active.id);
      const newIndex = items.indexOf(x=> x.id == over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      console.log(newArray)
    }
  }
  return (
    <div
      ref={setNodeRef}
      style={style}
      {...attributes}
    >
        <div>
          <div className="flex">
            <img src="/images/forms/drag_dots.svg" class="pl-0 sg-mgmt-forms-question-drag-icon" {...listeners} />
            <div className="w-1/2 mr-2">
              {renderTextField("", `${question_key}[options][${index}][label]`, [],false, false,{
                placeholder:"Label"
              })}
            </div>
            <div className="w-1/2">
              {renderTextField(
                "",
                `${question_key}[options][${index}][value]`, 
                [],
                false,
                false,
                {fieldProperties: {
                  InputProps: {
                    endAdornment: option.value ? "" : <InputSlugGenerator formikProps={formikProps} name={`${question_key}[options][${index}][value]`} associatedValue={option.label}/> 
                  }
                }}
              )}
            </div>
            <button style={{padding: "10px"}} type="button" onClick={()=>{removeOption(index)}}> ✖ </button>
          </div>
        </div>
    </div>
  )
};

export default FormsFormPageQuestionForm;
