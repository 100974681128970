import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const Button = (props) => {
  const { className, children, disabled, format, label, onClick, submit } = props;

  const baseClasses = "inline-block text-base leading-4 p-3 border-sg-orange border border-solid h-[40px]";
  let buttonStyles = "";

  switch(format) {
    case "select":
      buttonStyles = "bg-sg-orange text-white";
      break;
    case "cancel":
      buttonStyles = "bg-transparent text-sg-orange";
      break;
  }

  return (
    <button
      className={clsx(baseClasses, buttonStyles, className)}
      disabled={disabled}
      onClick={onClick}
      type={submit ? "submit" : "button"}
    >
      {label}
      {children}
    </button>
  );
};

Button.defaultProps = {
  className: "",
  disabled: false,
  format: "select",
  onClick: () => {},
  submit: false,
  label: ""
};

Button.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  format: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  submit: PropTypes.bool
};

export default Button;
