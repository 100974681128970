import React, { useContext } from "react";

import EventUserContext from "@event/EventUserContext";
import TabbedPage from "@shared/TabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

import PeopleEventParticipantIndex from "./PeopleEventParticipantIndex";
import PeopleEventParticipantSettings from "./PeopleEventParticipantSettings";

const PeopleEventParticipants = () => {
  const { user } = useContext(EventUserContext);

  const getTabs = () => {
    let tabs = [
      {
        label: "Participants",
        component: PeopleEventParticipantIndex,
        componentProps: {}
      }
    ];

    if (user && (user.role != "basic" || user.permission.participants_edit)) {
      tabs.push({
        label: "Settings",
        component: PeopleEventParticipantSettings,
        componentProps: {},
        icon: TabSettingsIcon
      });
    }
    return tabs;
  };

  return <TabbedPage tabs={getTabs()} />;
};

export default PeopleEventParticipants;
