import React, { useContext, useEffect } from "react";

import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError } from "@shared/Alerts";

const PostmarkApiValidator = () => {
  const { apiRoot } = useContext(EventContext).values;

  useEffect(() => {
    const fetchPostmarkApiStatus = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/communications/communications_status"));
        if (!result.data.connected) {
          alertError(
            "Communications API is not set up correctly. Communications will not send. Please contact the adminstrator."
          );
        }
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchPostmarkApiStatus();
  }, [apiRoot]);

  return <></>;
};

export default PostmarkApiValidator;
