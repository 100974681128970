import React from "react";

const EventUserContext = React.createContext({
  user: {
    email: null,
    name_first: "",
    name_last: ""
  }
});

export default EventUserContext;
