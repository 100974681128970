import React, { useContext } from "react";
import PropTypes from "prop-types";

import Stack from '@mui/material/Stack';
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError } from "@shared/Alerts";
import InputSlugGenerator from "@shared/forms/InputSlugGenerator";
import RequiredAsterisk from "@shared/forms/RequiredAsterisk";
import {
  renderRegexField,
  renderCancelButton,
  renderTextField,
  renderCheckField,
  renderSubmitButton
} from "@shared/FormUtils";
import HelperIcon from "@shared/helpers/HelperIcon";
import RadioField from "@shared/RadioField";
import SelectField from "@shared/SelectField";

import MetadataDataTypeHelperContent from "./helpers/MetadataDataTypeHelperContent";
import MetadataBlankField from "./MetadataBlankField";
import MetadataFieldFormOptions from "./MetadataFieldFormOptions";

const MetadataFieldForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const {
    callbackFailure,
    callbackSuccess,
    cancelButton,
    field,
    fieldType
  } = props;

  const isEdit = () => {
    return field && field.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/metadata/fields", `/${field.id}`),
        method: "PATCH",
        saveButton: "Update",
        saveButtonColor: "primary",
        title: "Edit Field"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/metadata/fields"),
      method: "POST",
      saveButton: "Create",
      saveButtonColor: "secondary",
      title: "Create Field"
    };
  })();

  const renderTypeField = () => {
    const typeOptions = [
      { label: "Text", value: "text" },
      { label: "Dropdown", value: "dropdown" },
      { label: "Checkboxes", value: "checkboxes" },
      { label: "Date", value: "date" }
    ];
    return <RadioField fieldName="field[field_format]" options={typeOptions} />;
  };

  const renderOptionsFields = (format, formikProps) => {
    switch (format) {
      case "dropdown":
        return <MetadataFieldFormOptions formikProps={formikProps} />;
      case "checkboxes":
        return <MetadataFieldFormOptions formikProps={formikProps} />;
    }
  }

  const renderSelectionMinMaxField = format => {
    if (format === "checkboxes") {
      const countOptions = [
        [0, 0],
        [1, 1],
        [2, 2],
        [3, 3],
        [4, 4],
        ["No Limit", 999]
      ];
      return (
        <>
          <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-reg-checkbox-selection">
            <label>Required selections:</label>
            <SelectField
              fieldName="field[selection_min]"
              options={countOptions}
            />
          </div>
          <div className="sg-mgmt-form-input-container sg-mgmt-form-input-container-reg-checkbox-selection">
            <label>Maximum selections:</label>
            <SelectField
              fieldName="field[selection_max]"
              autoComplete="off"
              options={countOptions}
            />
          </div>
        </>
      );
    }
    return <></>;
  };

  const renderRegistrationField = () => {
    if (fieldType === "event_participant") {
      return (
        <div className="sg-mgmt-form-row -ml-2">
          {renderCheckField("Registration Flag (optional)", "field[registration]")}
        </div>
      );
    }
    return <></>;
  };

  const formInitialValues = () => {
    if (field) {
      return {
        label: field.label || "",
        slug: field.slug || "",
        field_format: field.field_format || "text",
        options: field.options,
        registration: field.registration || false,
        sort_order: field.sort_order || 0,
        selection_min: field.selection_min || 1,
        selection_max: field.selection_max || 1
      };
    }
    return MetadataBlankField;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          field: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          // set field type from prop
          formData.set("field[field_type]", fieldType);

          // validate slug format
          const slugRegex = new RegExp("^[0-9A-Za-z_-]{3,32}$");
          if (!slugRegex.test(values.field.slug)) {
            alertError(
              "Slug must have letters, numbers, hyphens, and underscores only, and must be between 3 and 32 characters."
            );
            setSubmitting(false);
            return;
          }

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          }).catch(error => {
            alertError(error.response.data.error);
            setSubmitting(false);
          });
        }}
      >
        {(formikProps) => {
          const { values, isSubmitting } = formikProps;
          return (
            <Form className="sg-mgmt-form" id={formConfig.formId}>
              <div className="sg-mgmt-form-container">
                <div className="sg-mgmt-form-row">
                  {renderTextField("Data label", "field[label]", [], false, true)}
                </div>
                <div className="sg-mgmt-form-row">
                  {renderRegexField(
                    "Identifier (lowercase letters, numbers, hyphens only)",
                    "field[slug]",
                    RegExp("^[0-9A-Za-z_-]{0,32}$"),
                    [],
                    {
                      required: true, fieldProperties: {
                        InputProps: {
                          endAdornment: values.field.slug ? "" : <InputSlugGenerator formikProps={formikProps} name={`field[slug]`} associatedValue={values.field.label} maxSlugLength={30} />
                        }
                      }
                    }
                  )}
                </div>
                <div className="sg-mgmt-form-input-container mb-0">
                  <label>Select Data Type<RequiredAsterisk />
                    <HelperIcon
                      modalProps={{
                        header: "Data Types",
                        content: <MetadataDataTypeHelperContent />
                      }}
                    />
                  </label>
                </div>
                <div className="sg-mgmt-form-row">{renderTypeField()}</div>
                {renderRegistrationField()}
                {renderOptionsFields(values.field.field_format, formikProps)}
                <div className="sg-mgmt-form-row">
                  {renderSelectionMinMaxField(values.field.field_format)}
                </div>
              </div>
              <Stack direction="row" spacing={2}>
                {renderSubmitButton(
                  formConfig.saveButton,
                  isSubmitting,
                  {
                    type: "submit",
                    color: formConfig.saveButtonColor
                  }
                )}
                {renderCancelButton("Cancel", cancelButton, {
                  color: formConfig.saveButtonColor
                })}

              </Stack>
            </Form>
          )
        }}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

MetadataFieldForm.defaultProps = {
  callbackFailure: () => { },
  callbackSuccess: () => { },
  cancelButton: () => { }
};

MetadataFieldForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  field: PropTypes.object.isRequired,
  fieldType: PropTypes.string.isRequired
};

export default MetadataFieldForm;
