import React, { memo, forwardRef, useCallback } from "react";
import PropTypes from "prop-types";
import { Alert } from "@mui/material";
import { SnackbarContent, useSnackbar } from "notistack";

// eslint-disable-next-line react/display-name
const MuiAlert = forwardRef((props, forwardedRef) => {
  const { message, muiVariant, muiSeverity } = props;
  const { closeSnackbar } = useSnackbar();
  const bgColor = (muiVariant === "outlined") ? "background.paper" : "";

  const handleDismiss = useCallback(() => {
    closeSnackbar();
  }, [closeSnackbar]);

  return (
    <SnackbarContent
      ref={forwardedRef}
    >
      <Alert
        className="cursor-pointer"
        onClick={handleDismiss}
        variant={muiVariant}
        severity={muiSeverity}
        sx={{ bgcolor: bgColor }}
      >

        {message}
      </Alert>
    </SnackbarContent>
  );
});

MuiAlert.defaultProps = {
  muiVariant: "outlined",
  muiSeverity: "info"
};

MuiAlert.propTypes = {
  message: PropTypes.string.isRequired,
  muiVariant: PropTypes.string,
  muiSeverity: PropTypes.string
};

export default memo(MuiAlert);
