import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";

import HousingBookingsFinalize from "./HousingBookingsFinalize";
import HousingBookingsList from "./HousingBookingsList";

const HousingBookings = () => {
  const { apiRoot } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [blocks, setBlocks] = useState({});
  const [config, setConfig] = useState({});
  const [hotels, setHotels] = useState([]);
  const [page, setPage] = useState("index");
  const [editBooking, setEditBooking] = useState(null);
  const [fetchedBookings, setFetchedBookings] = useState(false);
  const [fetchedBlocks, setFetchedBlocks] = useState(false);
  const [fetchedHotels, setFetchedHotels] = useState(false);
  const [fetchedInstructions, setFetchedInstructions] = useState(false);
  const [fetchedParticipants, setFetchedParticipants] = useState(false);
  const [instructions, setInstructions] = useState([]);
  const [participants, setParticipants] = useState([]);

  useEffect(() => {
    const fetchBookings = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/bookings"));
        setBookings(result.data.bookings);
        setConfig(result.data.configuration);
        setFetchedBookings(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchHotels = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/hotels"));
        setHotels(result.data.hotels);
        setFetchedHotels(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchInstructions = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/billing_instructions")
        );
        setInstructions(sortInstructions(result.data));
        setFetchedInstructions(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchParticipants = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "participants/table"));
        setParticipants(result.data.participants);
        setFetchedParticipants(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchBlocks = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/blocks"));
        setBlocks(result.data.blocks);
        setFetchedBlocks(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchBlocks();
    fetchBookings();
    fetchHotels();
    fetchInstructions();
    fetchParticipants();
  }, [apiRoot]);

  const sortBookings = bookingsToSort => sortBy(bookingsToSort, ["name"]);
  const sortInstructions = instructionsToSort =>
    sortBy(instructionsToSort, ["sort_order"]);

  const addBooking = booking => {
    setBookings(sortBookings(bookings.concat(booking)));
  };

  const deleteBooking = deletedBookingId => {
    setBookings(sortBookings(bookings.filter(b => b.id !== deletedBookingId)));
  };

  const updateBookings = booking => {
    const newBookings = bookings.map(b =>
      b.gid === booking.gid ? booking : b
    );
    setBookings(newBookings);
  };

  const isReady = () => {
    return (
      fetchedBookings && fetchedHotels && fetchedParticipants && fetchedBlocks
    );
  };

  const renderList = () => {
    if (isReady()) {
      if (page === "index") {
        return (
          <HousingBookingsList
            addBooking={addBooking}
            bookings={bookings}
            blocks={blocks}
            config={config}
            deleteBooking={deleteBooking}
            editBooking={editBooking}
            fetched
            goToFinalize={() => setPage("finalize")}
            hotels={hotels}
            instructions={instructions}
            modalAddVisible={modalAddVisible}
            participants={participants}
            setEditBooking={setEditBooking}
            updateBookings={updateBookings}
          />
        );
      }
      if (page === "finalize") {
        return (
          <HousingBookingsFinalize
            bookings={bookings}
            fetched
            goToIndex={() => setPage("index")}
            updateBookings={updateBookings}
          />
        );
      }
    }
    return <Loading />;
  };

  return (
    <div>
      <PageHeader text="Bookings" />
      {renderList()}
    </div>
  );
};

export default HousingBookings;
