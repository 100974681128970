import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const CheckFieldDisabled = ({ label, checked }) => {
  const checkMark = () => {
    return checked ? (
      <div className="sg-mgmt-checkbox-disabled-checked" />
    ) : (
      <></>
    );
  };

  return (
    <label className="sg-mgmt-checkbox-container">
      {label}
      <span
        className={clsx("sg-mgmt-checkbox", "sg-mgmt-checkbox-disabled")}
      >
        {checkMark()}
      </span>
    </label>
  );
};

CheckFieldDisabled.defaultProps = {
  checked: true
};

CheckFieldDisabled.propTypes = {
  checked: PropTypes.bool,
  label: PropTypes.string.isRequired
};

export default CheckFieldDisabled;
