import React, { useContext } from "react";

import TabbedPage from "@shared/TabbedPage";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";

import HousingBookings from "./bookings/HousingBookings";
import HousingConfiguration from "./configuration/HousingConfiguration";
import HousingHotels from "./hotels/HousingHotels";
import HousingOverview from "./overview/HousingOverview";
import HousingReports from "./reports/HousingReports";
import HousingRoomBlocks from "./room_blocks/HousingRoomBlocks";

const Housing = () => {
  const [forceUpdate, setForceUpdate] = React.useState(Date.now());

  return (
    <TabbedPage
      tabs={[
        { label: "Overview", component: HousingOverview, componentProps: { forceUpdate: forceUpdate } },
        { label: "Hotels", component: HousingHotels, componentProps: { forceUpdate: forceUpdate } },
        { label: "Bookings", component: HousingBookings, componentProps: { forceUpdate: forceUpdate } },
        { label: "Room Blocks", component: HousingRoomBlocks, componentProps: { forceUpdate: forceUpdate } },
        { label: "Reports", component: HousingReports, componentProps: { forceUpdate: forceUpdate } },
        { label: "Settings", component: HousingConfiguration, componentProps: { forceUpdate: forceUpdate }, icon: TabSettingsIcon }
      ]}
    />
  );
};

export default Housing;
