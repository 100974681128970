import React, { useContext, useState } from "react";

import { DateTimePicker } from "@mui/x-date-pickers";

import EventContext from "@event/EventContext";

const StandardReportsParticipants = () => {
  const { event } = useContext(EventContext).values;
  const [historyDateTime, setHistoryDateTime] = useState(new Date());
  const reports = [
    { label: "All Participants", path: `/reporting/events/${event.slug}/participants` },
    { label: "Attendees", path: `/reporting/events/${event.slug}/participants?filter_type=attendee` },
    { label: "Speakers", path: `/reporting/events/${event.slug}/participants?filter_type=speaker` },
    { label: "Staff", path: `/reporting/events/${event.slug}/participants?filter_type=staff` },
    { label: "Exhibitors", path: `/reporting/events/${event.slug}/participants?filter_type=exhibitor` },
  ];

  const renderReport = (label, path) => {
    const url = new URL(path, window.location.origin);
    url.searchParams.append("format", "csv");

    return (
      <div className="flex my-1" key={label}>
        <div className="w-[300px]">{label}</div>
        <div className="px-4">
          <a className="sg-mgmt-link" href={path}>
            XLSX
          </a>
        </div>
        <div className="px-4">
          <a className="sg-mgmt-link" href={url}>
            CSV
          </a>
        </div>
      </div>
    );
  };

  const renderParticipantChanges = () => {
    return (
      <div className="my-2">
        {renderReport("Participant Changes (all)", `/reporting/events/${event.slug}/participants/changes`)}
        {renderReport(
          <>
            Participant Changes (since date):<br />
            <div className="w-[200px]">
              <DateTimePicker
                className="sg-mgmt-form-input"
                selected={historyDateTime}
                onChange={(date) => {
                  setHistoryDateTime(date);
                }}
                dateFormat="MMMM d, yyyy - h:mm aa"
              />
            </div>
          </>,
          `/reporting/events/${event.slug}/participants/changes?from=${Date.parse(historyDateTime)}`
        )}
      </div>
    );
  };

  return (
    <div className="my-4">
      <h2>Participants</h2>
      {reports.map((report) => renderReport(report.label, report.path))}
      {renderParticipantChanges()}
    </div>
  );
};

export default StandardReportsParticipants;
