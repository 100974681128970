import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import axios from "axios";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import HousingRoomTypeInventoryForm from "./HousingHotelRoomTypeInventoryForm";

const HousingHotelRoomTypeInventoryModal = props => {
  const {
    config,
    hotel,
    modalVisible,
    resetModal,
    roomType,
    title,
    updateFunc
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [dates, setDates] = useState([]);
  const [fetched, setFetched] = useState(false);

  Modal.setAppElement("#root");

  useEffect(() => {
    setFetched(false);

    const fetchRoomTypes = async () => {
      try {
        const result = await axios(
          urljoin(
            apiRoot,
            "/housing/hotels",
            `/${hotel.id}`,
            "/room_types",
            `/${roomType.id}`,
            "/dates"
          )
        );
        setDates(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    if (roomType.id) {
      fetchRoomTypes();
    }
  }, [apiRoot, hotel.id, roomType.id]);

  const success = response => {
    alertSuccess("Inventory updated successfully");
    updateFunc(response.data.dates);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to update inventory");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomTypeInventoryForm
            config={config}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            dates={dates}
            fetched={fetched}
            hotel={hotel}
            roomType={roomType}
          />
        </div>
      </div>
    </Modal>
  );
};

HousingHotelRoomTypeInventoryModal.propTypes = {
  config: PropTypes.object.isRequired,
  hotel: PropTypes.object.isRequired,
  roomType: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default HousingHotelRoomTypeInventoryModal;
