import React, { useEffect, useState } from "react";
import CommunicationsEmail from "./CommunicationsEmail";
import CommunicationsEmails from "./CommunicationsEmails";
import CommunicationsEmailForm from "./CommunicationsEmailForm";
import CommunicationsEmailTransactional from "./CommunicationsEmailTransactional";

const CommunicationsEmailsApp = props => {
  const [editEmail, setEditEmail] = useState(null);
  const [viewEmail, setViewEmail] = useState(null);
  const [viewTransactional, setViewTransactional] = useState(null);
  const [page, setPage] = useState("home");
  const { forceUpdate, setTab } = props;

  // Track which link (View or Tracking) was clicked in email list,
  // then pass value to Email component to set default section collapse/expand
  const [clickedTracking, setClickedTracking] = useState(false);
  const [clickedView, setClickedView] = useState(false);

  const SETTINGS_TAB_IDX = 3;

  const goToPage = p => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("home");
  }, [forceUpdate]);

  const goToSettings = () => { setTab(SETTINGS_TAB_IDX); }

  const renderPage = () => {
    switch (page) {
      case "home":
        return (
          <CommunicationsEmails
            goNew={() => goToPage("new")}
            goEdit={() => goToPage("edit")}
            goView={() => goToPage("view")}
            goViewTransactional={() => goToPage("view-transactional")}
            goSettings={goToSettings}
            setEditEmail={setEditEmail}
            setViewEmail={setViewEmail}
            setViewTransactional={setViewTransactional}
            setClickedTracking={setClickedTracking}
            setClickedView={setClickedView}
          />
        );
      case "new":
        return (
          <CommunicationsEmailForm
            callbackSuccess={() => goToPage("home")}
            cancel={() => goToPage("home")}
          />
        );
      case "edit":
        return (
          <CommunicationsEmailForm
            callbackSuccess={() => goToPage("home")}
            cancel={() => goToPage("home")}
            email={editEmail}
          />
        );
      case "view":
        return (
          <CommunicationsEmail
            back={() => goToPage("home")}
            emailId={viewEmail.id}
            clickedTracking={clickedTracking}
            clickedView={clickedView}
            editClick={email => {
              setEditEmail(email);
              goToPage("edit");
            }}
          />
        );
      case "view-transactional":
        return (
          <CommunicationsEmailTransactional
            back={() => goToPage("home")}
            templateId={viewTransactional.id}
            clickedTracking={clickedTracking}
            clickedView={clickedView}
          />
        );
      default:
        throw new Error("invalid emails page");
    }
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">{renderPage()}</div>
  );
};

export default CommunicationsEmailsApp;
