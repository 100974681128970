import React from "react";
import PropTypes from "prop-types";

const CustomReportType = (props) => {
  const { reportType, reportTypes, setReportType, submit } = props;

  const renderReportType = () => {
    const renderedTypes = reportTypes.map((type) => (
      <div
        key={`report-type-${type.id}`}
        className={`report-select-key-type ${type.id === reportType.id ? "report-select-key-type-selected" : ""}`}
        onClick={() => setReportType(type)}
      >
        <div className="report-select-key-type-title">{type.label}</div>
      </div>
    ));
    return <div className="report-select-key-type-wrapper">{renderedTypes}</div>;
  };

  const renderSelectReportType = () => {
    const objects = reportTypes.map((object) => {
      const obj = { value: object, label: object };
      return obj;
    });
    return (
      <div>
        <div className="report-select-keys">
          <label>Select a report type</label>
        </div>
        {renderReportType(objects)}
      </div>
    );
  };

  const renderSubmit = () => {
    if (!reportType) {
      return <button className="sg-mgmt-btn sg-mgmt-btn-cancel">Continue</button>;
    }
    return (
      <button className="sg-mgmt-btn" onClick={submit}>
        Continue
      </button>
    );
  };

  const renderButtons = () => {
    return (
      <div className="report-select-keys report-select-keys-divider">
        {renderSubmit()}
        <button
          className="sg-mgmt-btn sg-mgmt-btn-cancel"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            history.back();
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  return (
    <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
      <div className="report-tool-page-header">
        <h1 className="cnt-page-header">New Report</h1>
      </div>
      <div style={{ clear: "both" }} />
      {renderSelectReportType()}
      {renderButtons()}
    </div>
  );
};

CustomReportType.defaultProps = {
  reportType: { id: null }
};

CustomReportType.propTypes = {
  reportType: PropTypes.object,
  reportTypes: PropTypes.array.isRequired,
  setReportType: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default CustomReportType;
