import React, { useContext } from "react";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";

import OverviewCommunications from "./OverviewCommunications";
import OverviewTickets from "./OverviewTickets";
import OverviewWidgetSettings from "./OverviewWidgetSettings";

const OverviewWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);

  const renderContent = () => {
    switch (widget.widget_data_set) {
      case "commsOverview":
      case "commsOverviewByTemplate":
      case "commsOverviewByTag":
        return <OverviewCommunications />;
      case "ticketsOverview":
        return <OverviewTickets />;
      default:
        return <></>;
    }
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Overview Settings" settingsComponent={OverviewWidgetSettings} />;
  };

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="pt-4">{renderContent()}</div>
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default OverviewWidget;
