import React from "react";

const FormsFormDataPointHelperContentNonReg = ({openMetaDataModal}) => {
  return (
    <div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        Select a data point to link to. This will be used as the key and header in reports.
      </div>
    </div>
  )
}

export default FormsFormDataPointHelperContentNonReg