import React from "react";

const WidgetBoardContext = React.createContext({
  metadataFields: [],
  regFields: [],
  statuses: [],
  setWidgetSettingsVisible: () => {},
  tags: [],
  types: [],
  widgetBoard: {
    id: null
  }
});

export default WidgetBoardContext;
