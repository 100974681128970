import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { useTheme } from "@mui/material/styles";
import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertHttpError } from "@shared/Alerts";
import {
  renderCreateButton,
  renderCancelButton,
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

import HousingBlankRoomType from "./HousingBlankRoomType";
import HousingHotelRoomTypeInventoryModal from "./HousingHotelRoomTypeInventoryModal";
import HousingHotelRoomTypeModal from "./HousingHotelRoomTypeModal";

const HousingHotelRoomTypes = props => {
  const { config, goIndex, hotel, setEditHotelId } = props;
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const muiTheme = useTheme();

  const [editRoomType, setEditRoomType] = useState(HousingBlankRoomType);
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [modalInventoryVisible, setModalInventoryVisible] = useState(false);
  const [roomTypes, setRoomTypes] = useState([]);
  const [manageRoomType, setManageRoomType] = useState(HousingBlankRoomType);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    const fetchRoomTypes = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/hotels", `/${hotel.id}`, "/room_types")
        );
        setRoomTypes(result.data);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchRoomTypes();
  }, [apiRoot, hotel.id]);

  const sortRooms = toSort => sortBy(toSort, ["name"]);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const modalInventoryReset = () => {
    setModalInventoryVisible(false);
  };

  const addRoomType = roomType => {
    setRoomTypes(sortRooms(roomTypes.concat(roomType)));
  };

  const updateRoomType = roomType => {
    const newRoomTypes = roomTypes.map(rm =>
      rm.gid === roomType.gid ? roomType : rm
    );
    setRoomTypes(newRoomTypes);
  };

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const renderRoomTypes = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <div className="mb-2">
        <table
          className="w-full table-fixed"
          style={{
            fontFamily: muiTheme.typography.fontFamily,
            fontSize: muiTheme.typography.fontSize
          }}
        >
          <thead>
            <tr>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Room Type Name
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-left text-white">
                Description
              </th>
              <th className="w-1/6 bg-ui-6 px-4 py-4 text-right text-white">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {roomTypes.map(rt => (
              <tr key={rt.gid}>
                <td className="border-t px-4 py-4 font-bold">{rt.name}</td>
                <td className="border-t px-4 py-4">{rt.description}</td>
                <td className="border-t px-4 py-4 text-right leading-none">
                  <span
                    className="inline-block cursor-pointer border-r border-r-ui-7 pr-2 uppercase text-ui-7"
                    onClick={() => {
                      setManageRoomType(rt);
                      setModalInventoryVisible(true);
                    }}
                  >
                    Manage Inventory
                  </span>
                  <span
                    className="inline-block cursor-pointer pl-2 uppercase text-ui-7"
                    onClick={() => {
                      setEditRoomType(rt);
                      setModalEditVisible(true);
                    }}
                  >
                    Edit Room Type
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderNewRoomTypeButton = () => {
    return renderCreateButton("Create New Room Type", () => {
      setModalAddVisible(true);
    }, false, { className: "my-8 mr-4" })
  };

  const renderBackButton = () => {
    return renderCancelButton("Back", () => {
      setEditHotelId(null);
      goIndex();
    });
  };

  return (
    <div>
      <h1>{`Room Types for ${hotel.name}`}</h1>
      {renderRoomTypes()}
      {renderNewRoomTypeButton()}
      <HousingHotelRoomTypeModal
        config={config}
        hotel={hotel}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        roomType={HousingBlankRoomType}
        title="Create Room Type"
        updateFunc={addRoomType}
      />
      <HousingHotelRoomTypeModal
        config={config}
        hotel={hotel}
        modalVisible={modalEditVisible}
        resetModal={modalEditReset}
        roomType={editRoomType}
        title="Update Room Type"
        updateFunc={updateRoomType}
      />
      <HousingHotelRoomTypeInventoryModal
        config={config}
        hotel={hotel}
        modalVisible={modalInventoryVisible}
        resetModal={modalInventoryReset}
        roomType={manageRoomType}
        title="Set Inventory"
        updateFunc={addRoomType}
      />
      {renderBackButton()}
    </div>
  );
};

HousingHotelRoomTypes.propTypes = {
  config: PropTypes.object.isRequired,
  goIndex: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  setEditHotelId: PropTypes.func.isRequired
};

export default HousingHotelRoomTypes;
