import React from "react";
import { useParams } from "react-router-dom";
import StaticFilesIndex from "./StaticFilesIndex";
import ContentFrame from "@shared/ContentFrame";

const StaticFiles = () => {
  return (
    <ContentFrame>
      <StaticFilesIndex key={`static-files-index`}/>
    </ContentFrame>
  );
};

export default StaticFiles;
