import React, { useContext, useEffect, useState } from "react";

import axios from "axios";
import { useConfirm } from "material-ui-confirm";
import { useHistory } from "react-router-dom";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatTime } from "@shared/TimeUtils";

import { packageTotalAssigned } from "./PackageUtils";
import TicketingPackageTypeFormModal from "./TicketingPackageTypeFormModal";

const TicketingPackages = () => {
  const { user } = useContext(EventUserContext);
  const confirm = useConfirm();

  const [packageTypes, setPackageTypes] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [packageTypeModalOpen, setPackageTypeModalOpen] = useState(false);
  const [packageTypeModalTarget, setPackageTypeModalTarget] = useState(null);
  const { apiRoot, event } = useContext(EventContext).values;
  const history = useHistory();

  const performDelete = (id) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/ticketing/package_types/", `${id}`),
      method: "DELETE"
    })
      .then((response) => {
        if (response.data.error === null) {
          deletePackageTypes(id);
          alertSuccess("Package deleted successfully");
        } else {
          alertError(response.data.error);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderViewAction = (id) => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            viewPackage(id);
          }}
        >
          View Assignments
        </span>
      </>
    );
  };

  const viewPackage = (packageType) => {
    //TODO go to edit page
    history.push(`/ticketing/packages/${packageType}`);
  };

  const renderEditAction = (id) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              openEditPackageTypesModal(id);
            }}
          >
            Edit Package
          </span>
        </>
      );
    }
    return <></>;
  };

  const renderDeleteAction = (id) => {
    if (editEnabled()) {
      return (
        <>
          <span
            className="cursor-pointer"
            onClick={() => {
              confirm({
                title: "Confirm delete",
                description: "Are you sure you want to delete this package?"
              })
                .then(() => {
                  performDelete(id);
                })
                .catch((err) => {
                  if (err) alertError(err);
                });
            }}
          >
            Delete
          </span>
        </>
      );
    }
    return <></>;
  };

  const columns = [
    {
      headerName: "Package Name",
      field: "name"
    },
    {
      headerName: "Total Count",
      field: "total_count"
    },
    {
      headerName: "Total Assigned",
      field: "total_assigned",
      renderCell: (params) => packageTotalAssigned(params.row)
    },
    {
      headerName: "Updated At",
      field: "updated_at",
      minWidth: 200,
      renderCell: (params) => {
        return formatTime(params.value, event.time_zone);
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      flex: 1,
      minWidth: 320,
      getActions: (params) => [renderViewAction(params.id), renderEditAction(params.id), renderDeleteAction(params.id)]
    }
  ];

  useEffect(() => {
    const fetchForms = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "ticketing/package_types"));
        setPackageTypes(result.data["package_types"]);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchForms();
  }, []);

  const deletePackageTypes = (id) => {
    setPackageTypes(packageTypes.filter((f) => f.id != id));
  };

  const renderPackagesTable = () => {
    if (fetched) {
      return (
        <GrowlTable
          columns={columns}
          items={packageTypes}
          sortField="name"
          sortDirection="asc"
          tableName={`${event.slug}-ticket-packages`}
        />
      );
    }
    return <Loading />;
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.ticketing_edit) {
      return false;
    }
    return true;
  };

  const renderNewPackageButton = () => {
    return renderCreateButton("Create New Package Type", openNewPackageTypesModal);
  };

  const renderPackageTypesModal = () => {
    return (
      <TicketingPackageTypeFormModal
        packageTypeId={packageTypeModalTarget}
        packageTypes={packageTypes}
        modalVisible={packageTypeModalOpen}
        resetModal={closePackageTypesModal}
        callbackFailure={() => {}}
        callbackSuccess={(response) => {
          updatePackageTypes(response.data.package_type);
          closePackageTypesModal();
        }}
      />
    );
  };

  const openNewPackageTypesModal = () => {
    setPackageTypeModalTarget(null);
    setPackageTypeModalOpen(true);
  };

  const openEditPackageTypesModal = (id) => {
    setPackageTypeModalTarget(id);
    setPackageTypeModalOpen(true);
  };

  const updatePackageTypes = (packageType) => {
    setPackageTypes(packageTypes.filter((x) => x.id != packageType.id).concat([packageType]));
  };

  const closePackageTypesModal = () => {
    setPackageTypeModalOpen(false);
    setPackageTypeModalTarget(null);
  };

  return (
    <>
      <div>
        <PageHeader text="Packages Overview" />
        <p className="mb-4">
          Group multiple ticket types into packages, if you want to assign a set combination of tickets.
          <br />
          Note: In order to create a package, you need to create the ticket types first.
        </p>
        {renderNewPackageButton()}
        <br />
        <br />
        {renderPackagesTable()}
        {renderPackageTypesModal()}
      </div>
    </>
  );
};

export default TicketingPackages;
