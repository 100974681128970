import React from "react";
import PropTypes from "prop-types";
import {
  Route,
  Redirect,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
  withRouter
} from "react-router-dom";

import MainMenu from "../main/MainMenu";

import People from "./People";
import PeopleHeartbeat from "./PeopleHeartbeat";
import PeopleIndividual from "./PeopleIndividual";

const PeopleApp = props => {
  const { rootUrl } = props;
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  // const history = useHistory();

  //   const redirectOneUp = () => {
  //     history.push(".");
  //   };
  //
  //   const redirectTwoUp = () => {
  //     history.push("..");
  //   };

  return (
    <div className="sg-mgmt-content-frame">
      <MainMenu />
      <PeopleHeartbeat rootUrl={rootUrl} />
      <Switch>
        <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
        <Route path={`${path}/individual/:individualId`}>
          <PeopleIndividual rootUrl={rootUrl} />
        </Route>
        <Route exact path={`${path}`}>
          <People rootUrl={rootUrl} />
        </Route>
      </Switch>
    </div>
  );
};

PeopleApp.propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default withRouter(PeopleApp);
