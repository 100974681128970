import React from "react";

const FormsFormOptionsHelperContent = ({}) => {
  return (
    <div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Required Field</div>
        This field will be required to submit the form. Field label will be marked with a red asterisk.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Default Value
        </div>
        This field will start with a defined value already input. The default value is editable.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Hide from Report
        </div>
        When checked, this field will not be included in the report. (ex: read only text entries, dividers etc.) 
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Child Field
        </div>
        This field (child) will only show up depending on the values of another field set (parent).
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Hidden Field
        </div>
        This input field will not show up visibly on the form, but may still collect information. Primarily used for collecting analytics. (ex: browser data)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Disabled Field
        </div>
        This field does not allow the user to edit or input any additional information. Examples would be if the info already exists in the data base and is just being displayed to the attendee. (ex: attendee full name) Or if an attendee entered something on a previous page, and it is being displayed on another page. (ex: attendee email)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Custom Classes (HMTL) <span className="sg-mgmt-modal-helper-content-section-header-advanced">Advanced</span>
        </div>
        Add an additional CSS styling class to the field.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Custom Styles (CSS) <span className="sg-mgmt-modal-helper-content-section-header-advanced">Advanced</span>
        </div>
        Add additional CSS stylesheets to this field.
      </div>
    </div>
  )
}

export default FormsFormOptionsHelperContent