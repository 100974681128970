import React from "react";

const EventContext = React.createContext({
  apiRoot: null,
  event: null,
  rootUrl: null,
  urlBase: null,
  webRoot: null
});

export default EventContext;
