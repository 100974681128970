import React from "react";

const FormsFormDataPointHelperContent = ({openMetaDataModal}) => {
  return (
    <div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        Select a data point to link this field to a data field in the attendee record. This is a necessary step if you want this field to collect data. If you don't see a data point, add a new one by clicking on the plus sign.
      </div>
    </div>
  )
}

export default FormsFormDataPointHelperContent