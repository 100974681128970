export default {
  email_tempalte_id: "0",
  email_mailing_list_id: "0",
  recipients: "",
  immediate: true,
  scheduled_time: null,
  subject: "",
  preview_text: "",
  from_name: "",
  from_username: "",
  reply_address: "",
  tracking_tag: "",
  name: ""
};
