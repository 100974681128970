import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import axios from "axios";
import { toDate } from "date-fns-tz";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetColorThemeField from "@dashboard/form/WidgetColorThemeField";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const CountdownWidgetSettings = props => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_theme: widget?.widget_theme || "#1A206C",
      widget_config: {
        date: toDate(widget?.widget_config?.date || event?.date_begin || new Date(), { timeZone: event.time_zone })
      }
    }
  });

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = submitData => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    submitData.widget_type = "countdown";

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then(result => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} placeholder={"i.e. Countdown to Event"} />
          <div className="mb-4 mt-4">
            <FormControl>
              <FormLabel>Select date and time</FormLabel>
              <Controller
                name="widget_config[date]"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <DateTimePicker value={value} onChange={onChange} />
                )}
              />
            </FormControl>
          </div>
          <WidgetColorThemeField control={control} fieldName="widget_theme" errors={errors} />
          <WidgetSizeOptions control={control} />
          <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
        </div>
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Countdown widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

CountdownWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default CountdownWidgetSettings;
