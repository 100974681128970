import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import CustomReportFilterField from "./CustomReportFilterField";

const CustomReportFilterChain = (props) => {
  const { chain, count, id, operations, options, updateChain } = props;
  const [filters, setFilters] = useState([]);
  const [filterCount, setFilterCount] = useState(0);

  useEffect(() => {
    if (chain && Array.isArray(options) && options.length > 0) {
      const newFilters = chain.filters;
      newFilters.forEach((filter, index) => {
        const matchingOption = options.find((ele) => ele.value === filter.selectedOption.value);
        filter.count = index;
        filter.selectedOption = matchingOption;
      });
      setFilters(newFilters);
      setFilterCount(newFilters.length);
    }
  }, [chain]);

  const update = (filter) => {
    const newFilters = filters;
    newFilters.forEach((item) => {
      if (item.count === filter.count) {
        const matchingOption = options.find((ele) => ele.value === filter.selectedOption.value);
        item.operation = filter.operation;
        item.operationValue = filter.operationValue;
        item.selectedOption = matchingOption;
      }
    });
    updateChain({ id: id, filters: newFilters });
    setFilters([...newFilters]);
  };

  const deleteFilterAtIndex = (index) => {
    const { filters } = this.state;
    let newFilters = [];
    filters.forEach((filter) => {
      if (filter.count !== index) {
        newFilters.push(filter);
      }
    });
    updateChain({ id: id, filters: newFilters });
    setFilters(newFilters);
  };

  const addFilter = ({ count = 1, filter = null }) => {
    const newFilters = filters;
    if (!filter) {
      newFilters.push({
        selectedOption: { label: "Select a column", value: "select a field" },
        selectedOperation: { label: "operation", value: "operation" },
        operationValue: "",
        count: count === -1 ? filterCount : count
      });
    } else {
      newFilters.push(filter);
    }
    setFilters([...newFilters]);
    setFilterCount(filterCount + 1);
  };

  const renderFilters = () => {
    return filters.map((filter, index) => {
      return (
        <div key={`filter-item-wrapper-${filter.count}`}>
          <div
            key={`filter-item-${filter.count}`}
            style={{ display: "flex", alignItems: "center" }}
            className="report-tool-filter-item"
          >
            <CustomReportFilterField
              key={`filter-${filter.count}`}
              options={options}
              operationOptions={operations}
              index={filter.count}
              filter={filter}
              updateAllCriteria={update}
              deleteIndex={deleteFilterAtIndex}
            />
          </div>
          {renderAnd()}
        </div>
      );
    });
  };

  const renderAnd = () => {
    if (filters.length > 0) {
      return <div className="report-tool-filter-chain-and">AND</div>;
    }
    return <></>;
  };

  const renderFilterChainLabel = () => {
    return <div className="report-tool-filter-chain-title">{`Chain ${count}`}</div>;
  };

  return (
    <div className="report-tool-filter-chain-wrapper">
      {renderFilterChainLabel()}
      {renderFilters()}
      <div
        className="report-tool-modifier-link"
        onClick={() => {
          addFilter({ count: filterCount + 1 });
        }}
      >
        Add filter to chain
      </div>
    </div>
  );
};

export default CustomReportFilterChain;
