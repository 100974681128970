import React from "react";
import PropTypes from "prop-types";

import Stack from "@mui/material/Stack";

import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";

const WidgetSettingsButtons = props => {
  const { isSubmitDisabled, closeModal, isEdit } = props;
  const color = isEdit ? "primary" : "secondary";
  const submitText = isEdit ? "Save" : "Add Widget";

  return (
    <div className="mb-4 mt-4">
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(submitText, isSubmitDisabled, { color: color })}
        {renderCancelButton("Cancel", closeModal, { color: color })}
      </Stack>
    </div>
  );
};

WidgetSettingsButtons.defaultProps = {
  isEdit: false
};

WidgetSettingsButtons.propTypes = {
  isSubmitDisabled: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  isEdit: PropTypes.bool
};

export default WidgetSettingsButtons;
