import React, { useContext } from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

import WidgetContext from "@dashboard/WidgetContext";

const WidgetContainer = props => {
  const { editMode, size, widgetComponent } = props;
  const { dnd } = useContext(WidgetContext);
  const editModeCls = editMode ? "cursor-move border-rose-400 hover:ring-rose-400 hover:ring-2 shadow-widget-draggable" : "";

  switch (size) {
    case 1:
      return (
        <div
          ref={dnd.setNodeRef}
          style={dnd.sortableStyle}
          {...dnd.attributes}
          {...dnd.listeners}
          className={clsx("w-full border border-bg-2 bg-white overflow-auto shadow-widget", editModeCls)}
        >
          {widgetComponent}
        </div>
      );
    case 2:
      return (
        <div
          ref={dnd.setNodeRef}
          style={dnd.sortableStyle}
          {...dnd.attributes}
          {...dnd.listeners}
          className={clsx("w-full border border-bg-2 bg-white overflow-auto shadow-widget lg:col-span-2", editModeCls)}
        >
          {widgetComponent}
        </div>
      );
    default:
      console.log(`Invalid widget container size: ${size}`);
      return <></>;
  }
};

WidgetContainer.propTypes = {
  editMode: PropTypes.bool.isRequired,
  size: PropTypes.number.isRequired,
  widgetComponent: PropTypes.element.isRequired
};

export default WidgetContainer;
