import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import { DataGrid } from '@mui/x-data-grid';
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { formatDateFriendly } from "@shared/TimeUtils";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { renderCreateButton } from "@shared/FormUtils";

const HousingBookingsFinalize = props => {
  const {
    bookings,
    fetched,
    goToIndex,
    updateBookings
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [selectedIds, setSelectedIds] = useState([]);

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const finalize = () => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, "/housing/bookings/finalize"),
      method: "PATCH",
      data: {
        ids: selectedIds
      }
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Bookings finalized successfully");
          updateBookings(response.data.bookings);
          goToIndex();
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  /* eslint-disable react/prop-types, react/display-name */
  const columns = [
    {
      field: "guest_name",
      headerName: "Participant Name",
      flex: 1
    },
    {
      field: "event_participant_type",
      headerName: "Participant Type",
      valueGetter: params => {
        return params.row.event_participant?.event_participant_type
      },
      flex: 1
    },
    {
      flex: 1,
      field: "room_block_name",
      headerName: "Room Block"
    },
    {
      flex: 1,
      field: "hotel_name",
      headerName: "Hotel"
    },
    {
      flex: 1,
      field: "room_type_name",
      headerName: "Room Type"
    },
    {
      flex: 1,
      field: "check_in_date",
      headerName: "Check-in Date",
      renderCell: params => <span>{formatDateFriendly(params.value, null)}</span>
    },
    {
      flex: 1,
      field: "check_out_date",
      headerName: "Check-out Date",
      renderCell: params => <span>{formatDateFriendly(params.value, null)}</span>
    },
    {
      flex: 1,
      field: "status",
      headerName: "Status"
    },
    {
      flex: 1,
      headerName: "Finalized?",
      field: "paid",
      searchable: false,
      renderCell: params => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
    }
  ];
  /* eslint-enable react/prop-types, react/display-name */

  const filteredBookings = () => {
    if (fetched && bookings) {
      let filtered = bookings.filter(b => b.status === "booked");
      return filtered;
    }
    return [];
  };

  const renderFinalizeButton = () => {
    return (
      renderCreateButton("Finalize Selected", finalize)
    );
  };

  return (
    <div>
      <h2>Finalize Bookings</h2>
      <p>Select bookings to finalize</p>
      <div className="mt-2 mb-4">
        <DataGrid
          columns={columns}
          rows={filteredBookings()}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={ids => setSelectedIds(ids)}
        />
      </div>
      {renderFinalizeButton()}
    </div>
  );
};

HousingBookingsFinalize.propTypes = {
  bookings: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  goToIndex: PropTypes.func.isRequired,
  updateBookings: PropTypes.func.isRequired
};

export default HousingBookingsFinalize;
