import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { formatDateFriendly } from "@shared/TimeUtils";
import { alertError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import { renderTextField, renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";
const HousingRoomBlockRoomTypeInventoryForm = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    callbackFailure,
    callbackSuccess,
    cancelButton,
    blocks,
    config,
    fetched,
    block,
    roomBlockRoomType
  } = props;

  const formConfig = {
    alert: "updated",
    formId: "sg-mgmt-form-token-edit",
    formUrl: urljoin(
      apiRoot,
      "/housing/blocks",
      `/${block.id}`,
      `/room_types/${roomBlockRoomType.id}`,
      "/dates/update_all"
    ),
    method: "PATCH",
    saveButton: "Update",
    title: "Update Inventory"
  };

  const formInitialValues = () => {
    const inventoryDates = [];
    const dates = roomBlockRoomType.room_block_room_type_dates;
    dates.forEach(date => {
      const values = {
        unit_count: date.unit_count,
        rate_in_cents: date.rate_in_cents
      };
      inventoryDates[date.id] = values;
    });
    return inventoryDates;
  };

  const renderForm = () => {
    const dates = roomBlockRoomType.room_block_room_type_dates;
    const mainBlock = blocks.find(blk => blk.main);
    const mainBlockRoomBlockRoomType = mainBlock.room_block_room_types.find(
      rbrt => rbrt.room_type_id == roomBlockRoomType.room_type_id
    );
    return (
      <Formik
        initialValues={{
          dates: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              alertSuccess("Room inventory updated.");
              cancelButton();
            } else {
              alertError(
                `Room inventory update failed: ${response.data.error}`
              );
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              {sortBy(dates, d => d.date).map(date => {
                // corresponding main block date
                // TODO: find here
                return (
                  <div key={`room-type-date-${date.id}`}>
                    <div className="sg-mgmt-form-row">
                      <h3>{formatDateFriendly(date.date, null)}</h3>
                    </div>
                    <div className="sg-mgmt-form-row">
                      <div className="flex justify-start">
                        <div className="mr-4">
                          {renderTextField(
                            "Inventory (# of available rooms)",
                            `dates[${date.id}][unit_count]`
                          )}
                        </div>
                        <div className="mr-4">
                          {renderTextField(
                            "Rate",
                            `dates[${date.id}][rate_in_cents]`
                          )}
                        </div>
                        <div>
                          <div className="sg-mgmt-form-input-container">
                            <label>Available in MAIN block</label>
                            <div className="mt-1 text-center">
                              <span className="text-lg font-bold">
                                {
                                  mainBlockRoomBlockRoomType.room_block_room_type_dates.find(
                                    rbrtd => rbrtd.date === date.date
                                  ).units_available
                                }
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  if (!fetched) {
    return <Loading />;
  }

  if (!config.date_begin || !config.date_end) {
    return (
      <div>
        You must set the check in/out date ranges in Settings before you can
        manage room inventory.
      </div>
    );
  }

  return <div>{renderForm()}</div>;
};

HousingRoomBlockRoomTypeInventoryForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

HousingRoomBlockRoomTypeInventoryForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  config: PropTypes.object.isRequired,
  fetched: PropTypes.bool.isRequired,
  block: PropTypes.object.isRequired,
  blocks: PropTypes.array.isRequired,
  roomBlockRoomType: PropTypes.object.isRequired
};

export default HousingRoomBlockRoomTypeInventoryForm;
