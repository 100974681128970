import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

import axios from "axios";
import urljoin from "url-join";

import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";

import PeopleEventParticipantsViewModal from "./event_participants/PeopleEventParticipantsViewModal";
import PeopleIndividualForm from "./PeopleIndividualForm";

const PeopleIndividual = props => {
  const { rootUrl } = props;
  const [individual, setIndividual] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [participantId, setParticipantId] = useState(null);
  const [viewParticipant, setViewParticipant] = useState(false);
  const { individualId } = useParams();
  const history = useHistory();

  useEffect(() => {
    const fetchIndividual = async () => {
      try {
        const result = await axios(
          urljoin(rootUrl, "/-/individuals/", individualId)
        );
        setIndividual(result.data.individual);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchIndividual();
  }, [individualId, rootUrl]);

  const updateIndividual = ind => {
    setIndividual(ind);
  };

  const viewEventParticipant = part => {
    setParticipantId(part.id);
    setViewParticipant(true);
  };

  const resetViewParticipant = () => {
    setParticipantId(null);
    setViewParticipant(false);
  };

  const cancelButton = () => {
    history.push("..");
  };

  const renderField = (label, value) => {
    return (
      <div key={label} className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const renderIndividualForm = () => {
    return (
      <PeopleIndividualForm
        cancelButton={cancelButton}
        fetched={fetched}
        individual={individual}
        rootUrl={rootUrl}
        updateIndividual={updateIndividual}
      />
    );
  };

  const renderEvents = () => {
    return (
      <div className="pl-2">
        {individual.event_participants.map(ep => (
          <div key={ep.gid}>
            &bull;&nbsp;
            {ep.event.name}
            &nbsp; (
            <a className="sg-mgmt-link" href={ep.event.path}>
              view event
            </a>
            ) &nbsp; (
            <span
              className="sg-mgmt-link cursor-pointer"
              onClick={() => {
                viewEventParticipant(ep);
              }}
            >
              view participant
            </span>
            )
          </div>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-content sg-mgmt-content-full flex">
          <div className="flex-auto pr-4">{renderIndividualForm()}</div>
          <div className="flex-auto">
            <h2>Events Participated</h2>
            {renderEvents()}
          </div>
        </div>
      );
    }
    return <Loading />;
  };

  const renderParticipantModal = () => {
    if (participantId) {
      const participant = individual.event_participants.find(
        e => e.id === participantId
      );
      const apiRoot = urljoin(
        rootUrl,
        "-/events/",
        `${participant.event.slug}`
      );
      return (
        <PeopleEventParticipantsViewModal
          apiRoot={apiRoot}
          participantId={participantId}
          modalVisible={viewParticipant}
          regFields={[]}
          resetModal={resetViewParticipant}
        />
      );
    }
    return <></>;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <div className="sg-mgmt-index-header">Individual</div>
      <div>{renderField("GID", individual.gid)}</div>
      {renderContent()}
      {renderParticipantModal()}
    </div>
  );
};

PeopleIndividual.propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default PeopleIndividual;
