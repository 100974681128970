import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const UiToggle = ({flag, toggleFunc, label}) => {
  const icon = flag ? <FontAwesomeIcon icon={faCaretDown} />
                    : <FontAwesomeIcon icon={faCaretRight} />
  return (
    <div onClick={toggleFunc} className="text-sg-orange font-bold cursor-pointer mb-1 flex">
      <div className="w-3 text-center">{icon}</div>
      <div><span className="ml-1">{label}</span></div>
    </div>
  );
};

UiToggle.propTypes = {
  flag: PropTypes.bool.isRequired,
  toggleFunc: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired
};

export default UiToggle;
