import React from "react";
import PropTypes from "prop-types";

import { ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Route, Switch, withRouter } from "react-router-dom";

import ContentFrame from "@shared/ContentFrame";
import Footer from "@shared/Footer";
import MaterialTheme from "@shared/MaterialTheme";
import Tabs from "@shared/tabs/Tabs";

import MainIndex from "./index/MainIndex";
import MainBanner from "./MainBanner";
import MainEventForm from "./MainEventForm";
import PeopleApp from "../people/PeopleApp";

const Main = ({ config, location, rootUrl, user }) => {

  const getActiveTab = () => {
    if (location && location.pathname && location.pathname.includes("/people")) {
      return "people";
    }
    return "events";
  }

  return (
    <ThemeProvider theme={MaterialTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="sg-mgmt-container">
          <MainBanner config={config} rootUrl={rootUrl} user={user} />
          <Tabs
            tabs={[
              { id: 'events', name: "Events", href: "/events" },
              { id: 'people', name: "People", href: "/people" },
            ]}
            classNames={{
              outerContainer: "pr-[24px] pl-[24px]"
            }}
            activeTabId={getActiveTab()}
          />
          <ContentFrame className="p-[4.5rem]">
            <Switch location={location}>
              <Route exact path="/events/new">
                <MainEventForm rootUrl={rootUrl} />
              </Route>
              <Route exact path="/events">
                <MainIndex rootUrl={rootUrl} />
              </Route>
              <Route path="/people">
                <PeopleApp rootUrl={rootUrl} />
              </Route>
            </Switch>
          </ContentFrame>
          <Footer />
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
Main.propTypes = {
  config: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default withRouter(Main);
