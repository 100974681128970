import React from "react";
import PropTypes from "prop-types";
import { useTheme } from '@mui/material/styles';
import sortBy from "lodash/sortBy";
import sum from "lodash/sum";
import { formatDateFriendlyShort } from "@shared/TimeUtils";

const HousingOverviewRoomType = props => {
  const { roomType } = props;
  const muiTheme = useTheme();

  const renderTableHead = () => (
    <thead>
      <tr>
        <th className="py-4 px-4 w-1/6 text-left text-white bg-ui-6">&nbsp;</th>
        {sortBy(roomType.room_type_dates, date => date.date).map(date => (
          <th
            className="py-4 px-4 w-1/6 text-left text-white bg-ui-6"
            key={`rt-th-${date.gid}`}
          >
            {formatDateFriendlyShort(date.date)}
          </th>
        ))}
        <th className="py-4 px-4 w-1/6 text-right text-white bg-ui-6">Total</th>
      </tr>
    </thead>
  );

  const renderTableBody = () => (
    <tbody>
      <tr>
        <td className="py-4 px-4 border-t text-ui-4">Total Inventory</td>
        {sortBy(roomType.room_type_dates, date => date.date).map(date => (
          <td className="py-4 px-4 border-t" key={`rt-total-${date.gid}`}>
            {date.unit_count}
          </td>
        ))}
        <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4">
          {sum(roomType.room_type_dates.map(date => date.unit_count))}
        </td>
      </tr>
      {sortBy(roomType.room_block_room_types, rbrt => !rbrt.main).map(rbrt => (
        <tr key={rbrt.gid}>
          <td className="py-4 px-4 border-t text-ui-4">
            <span className="pl-2 italic">{rbrt.block_name}</span>
          </td>
          {sortBy(rbrt.room_block_room_type_dates, date => date.date).map(
            date => (
              <td
                className="py-4 px-4 border-t"
                key={`rt-occupancy-${date.gid}`}
              >
                {`${date.unit_count} (${date.units_available})`}
              </td>
            )
          )}
          <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4">
            {sum(rbrt.room_block_room_type_dates.map(date => date.unit_count))}
          </td>
        </tr>
      ))}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Booked</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-occupancy-${date.gid}`}> */}
      {/*       {date.occupancy_count} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     {sum(roomType.room_type_dates.map(date => date.occupancy_count))} */}
      {/*   </td> */}
      {/* </tr> */}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Available</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-available-${date.gid}`}> */}
      {/*       {date.units_available} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     {sum(roomType.room_type_dates.map(date => date.units_available))} */}
      {/*   </td> */}
      {/* </tr> */}
      {/* <tr> */}
      {/*   <td className="py-4 px-4 border-t text-ui-4">Rate</td> */}
      {/*   {sortBy(roomType.room_type_dates, date => date.date).map(date => ( */}
      {/*     <td className="py-4 px-4 border-t" key={`rt-rate-${date.gid}`}> */}
      {/*       {config.currency_symbol} */}
      {/*       {date.rate_in_cents} */}
      {/*     </td> */}
      {/*   ))} */}
      {/*   <td className="py-4 px-4 font-bold text-right border-t bg-bg-2 text-ui-4"> */}
      {/*     &nbsp; */}
      {/*   </td> */}
      {/* </tr> */}
    </tbody>
  );

  const renderRoomTypeTable = () => (
    <table
      className="w-full table-fixed"
      style={{
        fontFamily: muiTheme.typography.fontFamily,
        fontSize: muiTheme.typography.fontSize
      }}
    >
      {renderTableHead()}
      {renderTableBody()}
    </table>
  );

  const renderRoomTypeName = () => (
    <div>
      Room Type:&nbsp;
      <span className="font-bold text-sg-orange">{roomType.name}</span>
    </div>
  );

  const renderRoomDescription = () => (
    <div>
      Room description:&nbsp;
      {roomType.description}
    </div>
  );

  const renderRoomTypeDetails = () => (
    <div>
      {renderRoomTypeName()}
      {renderRoomDescription()}
    </div>
  );

  const renderRoomType = () => {
    return (
      <div className="py-4 sg-mgmt-hotel-room-type">
        {renderRoomTypeDetails()}
        {renderRoomTypeTable()}
      </div>
    );
  };

  console.log(muiTheme);

  return (
    <>
      {renderRoomType()}
    </>
  );
};

HousingOverviewRoomType.propTypes = {
  roomType: PropTypes.object.isRequired
};

export default HousingOverviewRoomType;
