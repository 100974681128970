import React, { useMemo, useRef } from "react";
import PropTypes from "prop-types";

import JoditEditor from "jodit-pro-react";

const RichTextEditor = (props) => {
  const { config, onChange, value } = props;
  const editorRef = useRef(null);

  const defaultConfig = useMemo(
    () => ({
      editorClassName: "prose prose-neutral text-black",
      license: ESB_JODIT_LICENSE_KEY || "1BF1T-3D8K3-CKM6M-LG11X",
      addNewLine: false, // disabled floating break widget
      readonly: false,
      sourceEditor: "area",
      toolbarAdaptive: false,
      buttons: [
        "bold",
        "italic",
        "underline",
        "|",
        "ul",
        "ol",
        "|",
        "font",
        "fontsize",
        "brush",
        "paragraph",
        "|",
        "left",
        "center",
        "right",
        "justify",
        "|",
        "table",
        "link",
        "|",
        "undo",
        "redo",
        "|",
        "source"
      ]
    }),
    []
  );

  const editorConfig = useMemo(() => Object.assign(defaultConfig, config), [defaultConfig, config]);

  return <JoditEditor ref={editorRef} config={editorConfig} onChange={onChange} tabIndex={1} value={value} />;
};

RichTextEditor.defaultProps = {
  config: {}
};

RichTextEditor.propTypes = {
  config: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.any.isRequired
};

export default RichTextEditor;
