import React, { useContext } from "react";
import PropTypes from "prop-types";

import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import { useForm } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetEmailRecipientSelect from "@dashboard/form/WidgetEmailRecipientSelect";
import WidgetEmailSelect from "@dashboard/form/WidgetEmailSelect";
import WidgetEmailTemplateSelect from "@dashboard/form/WidgetEmailTemplateSelect";
import WidgetEmailTemplateTagSelect from "@dashboard/form/WidgetEmailTemplateTagSelect";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";

const EmailWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "emailStatsTemplate",
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 2,
      widget_config: widget?.widget_config || {
        email_id: null,
        email_template_id: null,
        email_template_tag_id: null
      }
    }
  });

  const emailQuery = useQuery({
    queryKey: ["email_stats"],
    staleTime: 10000, // 10 seconds
    cacheTime: 36000, // 10 minutes
    queryFn: ({ signal }) =>
      axios
        .get(urljoin(apiRoot, "/communications/dashboard"), { signal })
        .then((res) => res.data)
        .catch((error) => {
          alertHttpError(error);
        })
  });

  const dataSetWatch = watch("widget_data_set");

  const dataSets = [
    { value: "emailStats", label: "Email" },
    { value: "emailStatsTemplate", label: "Email Template" },
    { value: "emailStatsTag", label: "Email Template Tag" }
  ];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type and size
    submitData.widget_type = "email";
    submitData.widget_size = 2;

    // purge email IDs for non-active datasets
    if (dataSetWatch !== "emailStats") {
      delete submitData.widget_config.email_id;
    }

    if (dataSetWatch !== "emailStatsTemplate") {
      delete submitData.widget_config.email_template_id;
    }

    if (dataSetWatch !== "emailStatsTag") {
      delete submitData.widget_config.email_template_tag_id;
    }

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderDataSetConfig = () => {
    switch (dataSetWatch) {
      default:
        return <></>;
    }
  };

  const renderEmailSelect = () => {
    if (dataSetWatch === "emailStats") {
      // filter out unnamed emails, email must have a name to be identifiable
      const emails = emailQuery.data?.emails.filter((email) => email.name);
      return <WidgetEmailSelect control={control} emails={emails || []} />;
    }
  };

  const renderEmailRecipientSelect = () => {
    return <WidgetEmailRecipientSelect control={control} />
  }

  const renderTemplateSelect = () => {
    if (dataSetWatch === "emailStatsTemplate") {
      return <WidgetEmailTemplateSelect control={control} templates={emailQuery.data?.templates || []} />;
    }
  };

  const renderTemplateTagSelect = () => {
    if (dataSetWatch === "emailStatsTag") {
      return <WidgetEmailTemplateTagSelect control={control} tags={emailQuery.data?.tags || []} />;
    }
  };

  const renderForm = () => {
    if (emailQuery.isLoading || emailQuery.isPending) return <div>Loading...</div>;
    if (emailQuery.isError) return <div>Error: {emailQuery.error.message}</div>;

    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Email Stats"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderDataSetConfig()}
          {renderEmailSelect()}
          {renderTemplateSelect()}
          {renderEmailRecipientSelect()}
          {renderTemplateTagSelect()}
        </div>
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Email Statistics widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

EmailWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default EmailWidgetSettings;
