import React from "react";
import PropTypes from "prop-types";

import CheckIcon from "@mui/icons-material/Check";

const WidgetColorPicker = props => {
  const { circleSize, circleSpacing, color, colors, indicator, onChange } = props;
  const selectedColor = color.toUpperCase().trim();

  const renderFill = circleSize => {
    if (indicator === "circle") {
      return (
        <div
          className="rounded-full bg-white"
          style={{
            border: `1px solid ${selectedColor}`,
            margin: `${circleSize * 0.25}px auto 0`,
            height: circleSize * 0.5,
            width: circleSize * 0.5,
            opacity: 0.9
          }}
        />
      );
    }

    if (indicator === "check") {
      return (
        <CheckIcon
          className="text-white"
          sx={{ height: "100%", margin: "auto", fontSize: circleSize * 0.8, verticalAlign: "baseline" }}
        />
      );
    }

    console.log("Invalid indicator type: ", indicator);
  };

  const renderColor = circleColor => {
    return (
      <div
        key={circleColor}
        className={"cursor-pointer rounded-full bg-gray-200 text-center align-top"}
        onClick={() => {
          console.log("selecting color: ", circleColor);
          onChange(circleColor);
        }}
        style={{
          backgroundColor: circleColor,
          height: circleSize,
          marginRight: circleSpacing,
          width: circleSize
        }}
      >
        {circleColor === selectedColor && renderFill(circleSize)}
      </div>
    );
  };

  return <div className="flex flex-row items-center">{colors.map((clr, _index) => renderColor(clr))}</div>;
};

WidgetColorPicker.defaultProps = {
  circleSize: 40,
  circleSpacing: 10,
  indicator: "circle"
};

WidgetColorPicker.propTypes = {
  circleSize: PropTypes.number,
  circleSpacing: PropTypes.number,
  color: PropTypes.string.isRequired,
  colors: PropTypes.array.isRequired,
  indicator: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

export default WidgetColorPicker;
