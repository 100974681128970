import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";

import Cookies from "universal-cookie";
import { v4 as uuidv4 } from "uuid";

import { renderButton } from "@shared/FormUtils";

const PeopleEventParticipantsImportResult = props => {
  const { pollUrl, callbackSuccess } = props;
  const [importResults, setImportResults] = useState(null);
  const [results, setResults] = useState([]);

  const fetchResults = useCallback(() => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(pollUrl, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error !== null) {
          console.log(json.error);
        } else {
          if (json.complete) {
            setImportResults(json.import);
          } else {
            setTimeout(fetchResults, 10000);
          }
        }
      });
  }, [pollUrl]);

  useEffect(() => {
    fetchResults();
  }, []);

  const generateKey = (prefix = "") => {
    return `${prefix}-${uuidv4()}`;
  };

  const renderSuccess = () => {
    // const success = results.success;
    const success = importResults.import_success;
    if (success.length === 0) return <div>No successful imports</div>;
    return success.map(result => (
      <div key={generateKey(result)}>
        <span className="text-green-500">{result}</span>
      </div>
    ));
  };

  const renderFailed = () => {
    // const failed = results.failed;
    const failed = importResults.import_failed;
    if (failed.length === 0) return <div>No failed imports</div>;
    return failed.map(result => (
      <div key={generateKey(result)}>
        <span className="text-red-500">{result}</span>
      </div>
    ));
  };

  const renderErrors = () => {
    // const errors = results.errors;
    const errors = importResults.import_errors;
    if (errors.length === 0) return <div>No errors</div>;
    return errors.map(result => (
      <div key={generateKey(result.row_number)}>
        <span className="text-red-500">{`Row ${result.row_number}: `}</span>
        <span>{`${result.email}: `}</span>
        {result.error}
      </div>
    ));
  };

  if (!importResults) {
    return (
      <div>Processing import, please wait...</div>
    );
  };

  return (
    <div className="mb-8 w-full">
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Imported Successfully"}
        </div>
        {renderSuccess()}
      </div>
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Failed to Import"}
        </div>
        {renderFailed()}
      </div>
      <div className="my-4">
        <div className="text-sg-orange text-lg font-normal mt-4 mb-2">
          {"Error Log"}
        </div>
        {renderErrors()}
      </div>
      <div className="my-4">{renderButton("Done", callbackSuccess)}</div>
    </div>
  );
};

PeopleEventParticipantsImportResult.propTypes = {
  callbackSuccess: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired
};

export default PeopleEventParticipantsImportResult;
