import { enqueueSnackbar } from 'notistack';

const httpErrorMessage = status => {
  switch (status) {
    case 403:
      return "Unauthorized";
    default:
      return "Unknown error";
  }
};

export const alertInfo = msg => {
  enqueueSnackbar(msg, { variant: "mui", muiSeverity: "info" });
};

export const alertSuccess = msg => {
  enqueueSnackbar(msg, { variant: "mui", muiSeverity: "success" });
};

export const alertError = msg => {
  enqueueSnackbar(msg, { variant: "mui", muiSeverity: "error" });
};

export const alertHttpError = error => {
  if (error?.response) {
    const msg = httpErrorMessage(error.response.status);
    alertError(msg);
  } else if (error?.request) {
    // no response from server
  } else {
    // unknown error
    alertError(`Error: ${error?.message}`);
  }
};

export default {
  alertError,
  alertInfo,
  alertHttpError,
  alertSuccess
};
