export default {
  name_last: "",
  name_first: "",
  job_title: "",
  company: "",
  email: "",
  personal_address_street_1: "",
  personal_address_street_2: "",
  personal_address_city: "",
  personal_address_state: "",
  personal_address_postcode: "",
  personal_phone: "",
  test_flag: false,
  custom: {}
};
