import React, {useState} from 'react';
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  horizontalListSortingStrategy,
  useSortable
} from '@dnd-kit/sortable';

import FormsFormsFormPagesPage from "./FormsFormsFormPagesPage"

const FormsFormsFormPages = ({pages,changePage,addPage, sortPages, currentPage}) => {
  const sensors = useSensors(
    useSensor(PointerSensor),
  );

  const handleDragEnd = (event) =>{
    const {active, over} = event;
    if (active.id !== over.id) {
        const items = getSortedPages().map(x=>x.id);
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        const newArray = arrayMove(items, oldIndex, newIndex);
        sortPages(newArray);
    }
  }

  const getSortedPages = () => {
    return pages.sort((a,b)=> a.sort_order - b.sort_order);
  }

  const renderAddNewPage = () => {
    return (
      <div onClick={addPage} className="sg-mgmt-forms-tab sg-mgmt-new-page">
        <img className="inline-block" src="/images/icons/add-filled-white.svg" />
      </div>
    )
  }

  const renderGeneralInfo = () => {
    return (
      <div onClick={()=>changePage(null)} className={`sg-mgmt-forms-tab ${currentPage == null ? "active" : ""}`}>
        <img
          className="w-18 h-18 inline-block -mt-[3px] mr-1"
          src={"/images/icons/tab-settings.svg"}
          alt={`Form Settings tab icon`}
        />
        Form Settings
      </div>
    )
  }

  return (
    <DndContext 
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
    >
      <div className="sg-mgmt-forms-tabs mb-4 no-scrollbar">
        {renderGeneralInfo()}

        <SortableContext 
          items={getSortedPages().map(x=>x.id)}
          strategy={horizontalListSortingStrategy}
        >
          {getSortedPages().map(page => <FormsFormsFormPagesPage active={currentPage == page.id} changePage={changePage} page={page} key={page.id} id={page.id} />)}
        </SortableContext>

        {renderAddNewPage()}
      </div>
    </DndContext>
  );
  

}

export default FormsFormsFormPages;