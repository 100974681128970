/* eslint-disable react/no-unknown-property */
import React, { useContext } from "react";

import clsx from "clsx";

import WidgetContext from "@dashboard/WidgetContext";
import WidgetSettings from "@dashboard/WidgetSettings";

import IframeWidgetSettings from "./IframeWidgetSettings";

const IframeWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const url = widget?.widget_config?.url || "";
  const scrolling = widget?.widget_config?.scrolling || "no";

  const scrollClasses = {
    yes: "",
    no: "overflow-hidden"
  };

  const renderContent = () => {
    return (
      <iframe
        src={url}
        loading="lazy"
        scroll={scrolling}
        scrolling={scrolling}
        className={clsx(
          "h-widget w-full",
          scrollClasses[scrolling],
          editMode ? "pointer-events-none" : "pointer-events-auto"
        )}
        frameBorder="0"
      ></iframe>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Iframe Settings" settingsComponent={IframeWidgetSettings} />;
  };

  return (
    <div className="group relative h-widget">
      <div>{renderContent()}</div>
      {renderControls()}
    </div>
  );
};

export default IframeWidget;
