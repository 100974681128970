import React, { useContext } from "react";

import clsx from "clsx";

import WidgetContext from "@dashboard/WidgetContext";
import WidgetSettings from "@dashboard/WidgetSettings";

import ImageWidgetSettings from "./ImageWidgetSettings";

const ImageWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);

  const imageContent = () => {
    if (widget.image_data_cropped) {
      return widget.image_data_cropped;
    }

    if (widget.image_url) {
      return widget.image_url;
    }

    return null;
  };

  const renderImage = () => {
    if (!widget.image_url) return <></>;

    return <img className={clsx("h-auto w-full", `object-${widget.widget_config.image_fit}`)} src={imageContent()} />;
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Image Settings" settingsComponent={ImageWidgetSettings} />;
  };

  return (
    <div className="group relative flex h-widget">
      {renderImage()}
      {renderControls()}
    </div>
  );
};

export default ImageWidget;
