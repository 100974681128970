import React from "react";

const ReportCheckBox = (props) => {
  const {
    label,
    images,
    inline,
    onClick,
    value,
    disabled
  } = props;

  const getCheckboxImage = () => {
    const cbImages = getCheckboxImages();
    if (disabled) {
      return value ? cbImages.disabled.checked : cbImages.disabled.unchecked;
    }
    return value ? cbImages.standard.checked : cbImages.standard.unchecked;
  }

  const getCheckboxImages = () => {
    const defaultCheckboxImages = {
      standard: {
        checked: "/images/reporting/form-icons/checkbox-checked.svg",
        unchecked: "/images/reporting/form-icons/checkbox-unchecked.svg"
      },
      disabled: {
        checked: "/images/reporting/form-icons/checkbox-checked.svg",
        unchecked:"/images/reporting/form-icons/checkbox-unchecked.svg"
      }
    }

    return {...defaultCheckboxImages, ...images};
  }

  return (
    <div
      className={`align-bottom cnt-default-layout-form-checkbox ${inline ? 'cnt-default-layout-form-checkbox-inline':''}`}
    >
      <img
        src={getCheckboxImage()}
        style={{cursor:"pointer", margin: "0 10px"}}
        onClick={disabled ? ()=>{} : onClick}
      />
      <label className="checkbox-label" htmlFor="topic_ids">{label}</label>
    </div>
  );
}

export default ReportCheckBox;
