import React from "react";
import PropTypes from "prop-types";

import clsx from "clsx";

const ContentFrame = (props) => {
  const {
    className,
    id,
    children
  } = props;

  return (
    <div
      className={clsx("flex-1", "p-12", className)}
      id={id}
    >
      {children}
    </div>
  )
};

ContentFrame.defaultProps = {
  className: "",
  id: "content-frame"
};

ContentFrame.propTypes = {
  className: PropTypes.string,
  id: PropTypes.any,
  children: PropTypes.any.isRequired
};

export default ContentFrame;
