import React, { useEffect, useState } from "react";
import CommunicationsEmailTemplates from "./CommunicationsEmailTemplates";
import CommunicationsEmailTemplateTags from "./CommunicationsEmailTemplateTags";
import CommunicationsEmailTemplateForm from "./CommunicationsEmailTemplateForm";

const CommunicationsEmailTemplatesApp = props => {
  const [page, setPage] = useState("home");
  const [editTemplate, setEditTemplate] = useState(null);
  const { forceUpdate } = props;

  const goToPage = p => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("home");
  }, [forceUpdate]);

  const renderPage = () => {
    switch (page) {
      case "home":
        return (
          <CommunicationsEmailTemplates
            goNew={() => goToPage("new")}
            goEdit={() => goToPage("edit")}
            goTags={() => goToPage("tags")}
            setEditTemplate={setEditTemplate}
          />
        );
      case "new":
        return (
          <CommunicationsEmailTemplateForm
            cancel={() => goToPage("home")}
            callbackSuccess={() => goToPage("home")}
          />
        );
      case "edit":
        return (
          <CommunicationsEmailTemplateForm
            cancel={() => goToPage("home")}
            callbackSuccess={() => goToPage("home")}
            template={editTemplate}
          />
        );
      case "tags":
        return (
          <CommunicationsEmailTemplateTags
            back={() => goToPage("home")}
            callbackSuccess={() => goToPage("home")}
          />
        );
      default:
        throw new Error("invalid templates page");
    }
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">{renderPage()}</div>
  );
};

export default CommunicationsEmailTemplatesApp;
