import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { formatDateFriendly } from "@shared/TimeUtils";
import { alertError, alertSuccess } from "@shared/Alerts";
import Loading from "@shared/Loading";
import { renderTextField, renderSubmitButton, renderCancelButton} from "@shared/FormUtils";
import { Stack } from "@mui/material";

const HousingHotelRoomTypeInventoryForm = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const {
    callbackFailure,
    callbackSuccess,
    cancelButton,
    config,
    dates,
    fetched,
    hotel,
    roomType
  } = props;

  const formConfig = {
    alert: "updated",
    formId: "sg-mgmt-form-token-edit",
    formUrl: urljoin(
      apiRoot,
      "/housing/hotels",
      `/${hotel.id}`,
      `/room_types/${roomType.id}`,
      "/dates/update_all"
    ),
    method: "PATCH",
    saveButton: "Update",
    title: "Update Inventory"
  };

  const formInitialValues = () => {
    const inventoryDates = [];
    dates.forEach(date => {
      const values = {
        unit_count: date.unit_count,
        rate_in_cents: date.rate_in_cents
      };
      inventoryDates[date.id] = values;
    });
    return inventoryDates;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          dates: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              alertSuccess("Room inventory updated.");
              cancelButton();
            } else {
              alertError(
                `Room inventory update failed: ${response.data.error}`
              );
              setSubmitting(false);
            }
          });
        }}
      >
        {({ values, isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              {sortBy(dates, d => d.date).map(date => {
                return (
                  <div key={`room-type-date-${date.id}`}>
                    <div className="sg-mgmt-form-row">
                      <h3>{formatDateFriendly(date.date, null)}</h3>
                    </div>
                    <div className="sg-mgmt-form-row">
                      <div className="flex justify-start">
                        <div className="mr-4">
                          {renderTextField(
                            "Inventory (# of available rooms)",
                            `dates[${date.id}][unit_count]`
                          )}
                        </div>
                        <div>
                          {renderTextField(
                            "Rate",
                            `dates[${date.id}][rate_in_cents]`
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}
            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  if (!fetched) {
    return <Loading />;
  }

  if (!config.date_begin || !config.date_end) {
    return (
      <div>
        You must set the check in/out date ranges in Settings before you can
        manage room inventory.
      </div>
    );
  }

  return <div>{renderForm()}</div>;
};

HousingHotelRoomTypeInventoryForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

HousingHotelRoomTypeInventoryForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  config: PropTypes.object.isRequired,
  dates: PropTypes.array.isRequired,
  fetched: PropTypes.bool.isRequired,
  hotel: PropTypes.object.isRequired,
  roomType: PropTypes.object.isRequired
};

export default HousingHotelRoomTypeInventoryForm;
