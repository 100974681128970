import React, { useContext } from "react";

import { SnackbarProvider } from 'notistack';

import EventContext from "@event/EventContext";
import ContentFrame from "@shared/ContentFrame";
import Loading from "@shared/Loading";
import MuiAlert from "@shared/MuiAlert";

import MainEventForm from "../../main/MainEventForm";

const EventApis = () => {
  const { event } = useContext(EventContext).values;

  return (
    <ContentFrame>
      <SnackbarProvider
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3500}
        Components={{
          mui: MuiAlert
        }}
      >
        {event ? (
          <MainEventForm
            event={event}
            rootUrl="/"
            isEdit
          />
        ) : (
            <Loading />
          )}
      </SnackbarProvider>
    </ContentFrame>
  );
};

export default EventApis;
