export default {
  color: "#1A206C",
  name: "",
  event_type: "physical",
  location: "",
  date_begin: null,
  date_end: null,
  time_zone: "",
  slug: "",
  tile_url: "",
  gid: null,
  archived: false
};
