import React, { useContext, useEffect, useState } from "react";

import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess, alertInfo } from "@shared/Alerts";
import {
  renderButton,
  renderCancelButton,
  renderSelectField,
  renderSubmitButton,
  renderTextField,
  renderIntegerField
} from "@shared/FormUtils";
import Loading from "@shared/Loading";

const TicketingPackageTypeForm = ({ packageTypeId, cancel, callbackSuccess, callbackFailure }) => {
  const [packageType, setPackageType] = useState(null);
  const [ticketTypes, setTicketsTypes] = useState([]);
  const { apiRoot } = useContext(EventContext).values;

  const isEdit = () => {
    return packageTypeId;
  };

  const alertIfNeeded = () => {
    if (packageType.total_assigned > 0) {
      alertInfo(
        `Warning: ${packageType.name} is currently assigned to participants. Modifying assigned tickets will not be reflected on those already assigned this package.`
      );
    }
  };

  const addTicketType = (ticketType, formikProps) => {
    const { values, setFieldValue } = formikProps;
    alertIfNeeded();
    let tickets = values["package_tickets"];
    tickets.push(ticketType);
    setFieldValue("package_tickets", tickets);
  };

  const removeTicketType = (ticketType, formikProps) => {
    const { values, setFieldValue } = formikProps;
    alertIfNeeded();
    let tickets = values["package_tickets"];
    let filteredTickets = tickets.filter((ticket) => ticket.id !== ticketType.id);
    setFieldValue("package_tickets", filteredTickets);
  };

  useEffect(() => {
    const fetchPackageType = async () => {
      try {
        if (packageTypeId) {
          const result = await axios(urljoin(apiRoot, `ticketing/package_types/${packageTypeId}`));
          setPackageType(result.data["package_type"]);
        } else {
          const result = await axios(urljoin(apiRoot, `ticketing/package_types/new`));
          setPackageType(result.data["package_type"]);
        }
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchTickets = async () => {
      try {
        const result = await axios(urljoin(apiRoot, `ticketing/ticket_types/`));
        setTicketsTypes(result.data["ticket_types"]);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTickets();
    fetchPackageType();
  }, []);

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-forms-edit",
        formUrl: urljoin(apiRoot, "/ticketing/package_types", `/${packageTypeId}`),
        method: "PATCH",
        saveButton: "Save",
        buttonProperties: { color: "primary" },
        title: "Edit " + ((packageType || {}).name || "")
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-forms-add",
      formUrl: urljoin(apiRoot, "/ticketing/package_types/"),
      method: "POST",
      saveButton: "Create",
      buttonProperties: { color: "secondary" },
      title: "Create New Package"
    };
  })();

  const packageTypeInitialValues = () => {
    delete packageType["total_assigned"];
    return packageType;
  };
  const ticketTypeInitialValues = () => {
    return packageType.package_tickets.map((pt) => pt.ticket_type);
  };

  const formValidation = (values) => {
    const errors = {};
    if (!values.package_type.name) {
      alert("You must enter a unique ticket name.");
      errors.package_type = errors.package_type || {};
      errors.package_type.name = "Required";
    }
    return errors;
  };

  const renderFormFields = (formikProps) => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-form-section">
          <div className="flex">
            <div className="mr-4 w-full">{renderTextField("Package Name", "package_type[name]", [], false, true)}</div>
          </div>

          <div className="flex">
            <div className="mr-4 w-full">
              {renderSelectField(
                "Ticket Count",
                "package_type[total_count_type]",
                [
                  { value: "automatic", label: "Automatic (determined by tickets)" },
                  { value: "limited", label: "Limited" }
                ],
                false,
                true
              )}
            </div>
          </div>

          {formikProps.values.package_type.total_count_type == "limited" ? (
            <div className="flex">
              <div className="mr-4 w-full">
                {renderIntegerField(
                  "Total Available Packages",
                  "package_type[total_count]",
                  [],
                  0,
                  1,
                  10000000,
                  false,
                  true
                )}
              </div>
            </div>
          ) : (
            ""
          )}

          {renderCurrentTickets(formikProps)}
          {renderAvailableTickets(formikProps)}
        </div>
      </div>
    );
  };

  const renderCurrentTickets = (formikProps) => {
    const { values } = formikProps;
    return (
      <div className="flex">
        <div className="sg-mgmt-form-input-container sg-ticketing-package-form-available-tickets-container mr-4 w-full">
          <label>Assigned Tickets</label>
          {values["package_tickets"].map((ticketType) => renderCurrentTicketType(ticketType, formikProps))}
        </div>
      </div>
    );
  };

  const renderCurrentTicketType = (ticketType, formikProps) => {
    return (
      <div
        key={`sg-ticketing-${ticketType.id}`}
        className="sg-ticketing-package-form-available-tickets-container mb-2 mr-4 flex w-full items-center justify-between"
      >
        {ticketType.name}
        {renderCancelButton(
          "Remove",
          () => {
            removeTicketType(ticketType, formikProps);
          },
          {
            color: "error"
          }
        )}
      </div>
    );
  };

  const renderAvailableTickets = (formikProps) => {
    const { values } = formikProps;
    const availableTickets = ticketTypes.filter((ticket) => {
      let assignedTickets = values["package_tickets"];
      return !assignedTickets.filter((aTicket) => aTicket.id === ticket.id).length > 0;
    });
    return (
      <div className="flex">
        <div className="sg-mgmt-form-input-container sg-ticketing-package-form-available-tickets-container mr-4 w-full">
          <label>Available Tickets</label>
          {availableTickets.map((ticketType) => renderAvailableTicketType(ticketType, formikProps))}
        </div>
      </div>
    );
  };

  const renderAvailableTicketType = (ticketType, formikProps) => {
    return (
      <div
        key={`sg-ticketing-${ticketType.id}`}
        className="sg-ticketing-package-form-available-tickets-container mb-2 mr-4 flex w-full items-center justify-between"
      >
        {ticketType.name}
        {renderButton(
          "Add",
          () => {
            addTicketType(ticketType, formikProps);
          },
          {
            color: "secondary"
          }
        )}
      </div>
    );
  };

  const renderForm = () => {
    return (
      <Formik
        enableReinitialize
        initialValues={{
          package_type: packageTypeInitialValues(),
          package_tickets: ticketTypeInitialValues()
        }}
        validate={formValidation}
        validateOnChange={false}
        validateOnBlur={true}
        onSubmit={(values, { setSubmitting }) => {
          const token = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: values
          })
            .then((response) => {
              console.log({
                response
              });
              if (response.data.error == null) {
                callbackSuccess ? callbackSuccess(response) : () => {};
                alertSuccess("Ticket Type Saved");
                setPackageType(response.data.package_type);
                setSubmitting(false);
              } else {
                callbackFailure ? callbackFailure(response) : () => {};
                alertError(`Unable to save package: ${response.data.error}`);
                setSubmitting(false);
              }
            })
            .catch((error) => {
              alertHttpError(error);
              setSubmitting(false);
            });
        }}
      >
        {(formikProps) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            {renderFormFields(formikProps)}
            {renderButtons(formikProps)}
          </Form>
        )}
      </Formik>
    );
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting, formConfig.buttonProperties)}
        {renderCancelButton("Cancel", cancel, formConfig.buttonProperties)}
      </Stack>
    );
  };

  console.log(packageType);

  return (
    <div className="sg-mgmt-modal-frame">
      <div className={`sg-mgmt-modal-title ${isEdit() ? "sg-mgmt-modal-title-edit" : ""}`}>{formConfig.title}</div>
      <div className="sg-mgmt-modal-content">
        <div>{packageType ? renderForm() : <Loading />}</div>
      </div>
    </div>
  );
};

export default TicketingPackageTypeForm;
