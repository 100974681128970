import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";

const HelperModal = props => {
  const {
    modalOpen,
    content,
    header,
    closeModal
  } = props;

  Modal.setAppElement("#root");

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalOpen}
      onRequestClose={closeModal}
      contentLabel={header}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-close-button">
            <button onClick={closeModal}>X</button>
          </div>
          <div className="sg-mgmt-modal-helper-header">
            {header}
          </div>
          <div className="sg-mgmt-modal-helper-content">
            {content}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default HelperModal;
