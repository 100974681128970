import React from "react";
import PropTypes from "prop-types";

const PageHeader = (props) => {
  const { text } = props;
  return (
    <div className="text-sg-orange text-2xl font-normal">{text}</div>
  );
};

PageHeader.propTypes = {
  text: PropTypes.string.isRequired
};

export default PageHeader;
