import React, { useState } from "react";
import clsx from "clsx";
import FormsFormsForm from "./forms/FormsFormsForm"
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import ContentFrame from "@shared/ContentFrame";
const FormsEdit = () => {
  const history = useHistory();
  const { id, form_type } = useParams();

  return (
    <ContentFrame>
      <FormsFormsForm 
        formId={id != "new" ? id : null}
        cancel={() => history.push(`/forms/${form_type}`)}
        form_type={form_type}
      />
    </ContentFrame>
  );
};

export default FormsEdit;
