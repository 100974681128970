import React, { useState } from "react";
import PropTypes from "prop-types";
import CommunicationsEmailMailingListImportForm from "./CommunicationsEmailMailingListImportForm";
import CommunicationsEmailMailingListImportMapping from "./CommunicationsEmailMailingListImportMapping";

const CommunicationsEmailMailingListImport = props => {
  const { back, goEdit, listId } = props;
  const [stage, setStage] = useState(0);
  const [sheet, setSheet] = useState(null);

  const successStage1 = sh => {
    setSheet(sh);
    setStage(1);
  };

  const successStage2 = () => {
    goEdit();
  };

  const renderStage = () => {
    switch (stage) {
      case 0:
        return (
          <CommunicationsEmailMailingListImportForm
            back={back}
            listId={listId}
            callbackSuccess={successStage1}
          />
        );
      case 1:
        return (
          <CommunicationsEmailMailingListImportMapping
            listId={listId}
            sheet={sheet}
            callbackSuccess={successStage2}
          />
        );
      default:
        console.log("Invalid stage");
        return <></>;
    }
  };

  return <div>{renderStage()}</div>;
};

CommunicationsEmailMailingListImport.defaultProps = {
  back: () => {}
};

CommunicationsEmailMailingListImport.propTypes = {
  back: PropTypes.func,
  listId: PropTypes.number.isRequired,
  goEdit: PropTypes.func.isRequired
};

export default CommunicationsEmailMailingListImport;
