import React from "react";
import PropTypes from "prop-types";

// import SelectField from "@shared/SelectField";

const CustomReportSave = (props) => {
  const {
    report,
    reportName,
    // reportLocation,
    // reportEditable,
    setReportName,
    // setReportLocation,
    // setReportEditable,
    submit
  } = props;

  const renderButtons = () => {
    return (
      <div className="report-select-keys report-select-keys-divider">
        <button className="sg-mgmt-btn" onClick={submit}>
          Save
        </button>
        <button
          className="sg-mgmt-btn sg-mgmt-btn-cancel"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            history.back();
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  // const renderReportLocation = () => {
  //   let locationObjects = [
  //     { value: false, label: "Reports (shared)" },
  //     { value: true, label: "My Reports (private)" }
  //   ];
  //   return (
  //     <>
  //       <div className="report-select-keys">
  //         <label>Report Location:</label>
  //         <SelectField
  //           value={reportLocation}
  //           options={locationObjects}
  //           onChange={(location) => {
  //             setReportLocation(location);
  //           }}
  //         />
  //       </div>
  //       {reportLocation.value === false ? (
  //         <>
  //           <div className="report-select-keys">
  //             <label>Editable by others:</label>
  //             <input
  //               type="checkbox"
  //               checked={reportEditable}
  //               onChange={(e) => {
  //                 setReportEditable(e);
  //               }}
  //               style={{ width: "auto" }}
  //             />
  //           </div>
  //         </>
  //       ) : (
  //           ""
  //         )}
  //     </>
  //   );
  // };

  const renderSaveAs = () => {
    return (
      <>
        <label>Report Title:</label>
        <input
          type="text"
          value={reportName}
          onChange={(e) => setReportName(e.target.value)}
          className="report-tool-input"
        />
        <br />
      </>
    );
  };

  return (
    <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
      <div className="report-tool-page-header">
        <h1 className="cnt-page-header">{report === undefined ? "New Report" : report.name}</h1>
      </div>
      <div style={{ clear: "both" }} />
      {renderSaveAs()}
      {renderButtons()}
    </div>
  );
};

CustomReportSave.propTypes = {
  // reportLocation: PropTypes.object.isRequired,
  reportName: PropTypes.string.isRequired,
  // reportEditable: PropTypes.bool.isRequired,
  // setReportLocation: PropTypes.func.isRequired,
  setReportName: PropTypes.func.isRequired,
  // setReportEditable: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired
};

export default CustomReportSave;
