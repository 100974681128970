import React from "react";

import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const CustomReportKey = (props) => {
  const { item, removeKey } = props;

  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = useSortable({
    id: item.value
  });

  const sortableStyle = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
    zIndex: isDragging ? 1 : 0
  };

  return (
    <div ref={setNodeRef} style={sortableStyle} {...attributes} {...listeners} className="inline-block">
      <div className="report-key-wrapper">
        {item.label}
        <div onClick={() => removeKey(item)}>
          <img src="/images/reporting/form-icons/icon-close.svg" />
        </div>
      </div>
    </div>
  );
};

export default CustomReportKey;
