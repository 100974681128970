import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import FormCopyForm from "./FormCopyForm";

const FormCopyModal = props => {
  const {
    modalVisible,
    resetModal,
    callbackFailure,
    callbackSuccess,
    formType
  } = props;

  Modal.setAppElement("#root");

  const cancelButton = () => {
    resetModal();
  };

  renderCopyForm = () => {
    return (
      <FormCopyForm
        cancel={cancelButton}
        callbackSuccess={callbackSuccess}
        formType={formType}
        callbackFailure={callbackFailure}
      />
    )
  }

  return (
    <Modal
      className="sg-mgmt-modal"
      overlayClassName="sg-mgmt-modal-overlay"
      isOpen={modalVisible}
      onRequestClose={resetModal}
      contentLabel="Copy Form"
    >
      <div className="sg-mgmt-modal-frame">
        <div class="sg-mgmt-modal-title">Copy Form</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            {modalVisible ? renderCopyForm() : ""}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default FormCopyModal;
