import React, { useContext } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import { useForm } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const OverviewWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "commsOverviewByTemplate",
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 2
    }
  });
  const dataSetWatch = watch("widget_data_set");

  const dataSets = [
    { value: "commsOverviewByTemplate", label: "Communications Overview (by Template)" },
    { value: "commsOverviewByTag", label: "Communications Overview (by Tag)" },
    { value: "ticketsOverview", label: "Tickets Overview" }
  ];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type
    submitData.widget_type = "overview";

    // set fixed size
    submitData.widget_size = 2;

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((result) => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderDataSetConfig = () => {
    switch (dataSetWatch) {
      default:
        return <></>;
    }
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Communications Overview"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderDataSetConfig()}
        </div>
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Overview widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

OverviewWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default OverviewWidgetSettings;
