import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import PropTypes from "prop-types";

import {
  DataGridPremium,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridToolbarQuickFilter,
  useGridApiRef,
  GRID_CHECKBOX_SELECTION_COL_DEF
} from "@mui/x-data-grid-premium";

// eslint-disable-next-line react/display-name
const GrowlTableComponent = forwardRef((props, _ref) => {
  const {
    checkboxSelection,
    columns,
    defaultColumnVisibility,
    disableColumnMenu,
    disableColumnReorder,
    disableColumnResize,
    disableRowSelectionOnClick,
    items,
    pageSizeInitial,
    pageSizeOptions,
    sortField,
    sortDirection,
    tableName,
    toolbarControls,
    ...restProps
  } = props;
  const apiRef = useGridApiRef();
  const [selectedRows, setSelectedRows] = useState([]);

  useImperativeHandle(
    _ref,
    () => ({
      getSelectedRows: () => selectedRows
    }),
    [selectedRows]
  );

  // we'll automatically apply flex: 1 and minWidth: 100 to each
  // column unless different values are provided
  const tableColumns = useMemo(() => {
    return columns.map(col => {
      col.headerClassName = "bg-ui-6 text-white";
      (col.flex = col.flex || 1), (col.minWidth = col.minWidth || 100);
      return col;
    });
  }, [columns]);

  const pinnedColumns = useMemo(() => {
    return {
      "left": [GRID_CHECKBOX_SELECTION_COL_DEF.field],
      "right": ["actions"]
    }
  }, [])

  const saveSnapshot = useCallback(() => {
    if (ESB_NODE_ENV === "development") {
      console.log("saving table state");
    }
    if (apiRef?.current?.exportState && localStorage) {
      // state always saved with preferencePanel still with "open" values, override
      // also don't save column dimensions (TODO: Remove if we start offering column resizing)
      const currentState = {
        ...apiRef.current.exportState(),
        preferencePanel: { open: false }
      };
      currentState.columns.dimensions = {};
      localStorage.setItem(tableName, JSON.stringify(currentState));
    }
  }, [apiRef, tableName]);

  // not currently offering a manual state reset button, but it might come
  // uncomment and attach to a button if/when the time comes
  // const clearSavedState = () => {
  //   if (ESB_NODE_ENV === "development") { console.log(`Clearing saved state for ${tableName}`); }
  //   localStorage?.setItem(tableName, JSON.stringify({}));
  // };

  const handleSelection = rows => {
    setSelectedRows(rows);
  };

  const DataGridToolbar = () => {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
        {toolbarControls}
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          sx={{
            marginLeft: "auto"
          }}
        />
      </GridToolbarContainer>
    );
  };

  const slots = {
    toolbar: DataGridToolbar
  };

  const slotProps = {
    baseSelect: { variant: "outlined" },
    baseTextField: { variant: "outlined" },
    gridFilterPanel: { sx: { margin: "80px" } },
    filterPanel: {
      filterFormProps: {
        logicOperatorInputProps: {
          variant: "outlined",
          size: "small",
          mr: 2
        },
        columnInputProps: {
          variant: "outlined",
          size: "small",
          sx: { mt: "auto", mr: 2 }
        },
        operatorInputProps: {
          variant: "outlined",
          size: "small",
          sx: { mt: "auto", mr: 2 }
        },
        valueInputProps: {
          InputComponentProps: {
            variant: "outlined",
            size: "small",
            width: 200,
            mr: 2
          }
        }
      },
      sx: {
        "& .MuiDataGrid-filterForm": { p: 2 }
      }
    }
  };

  const actionStyle = {
    color: "#2196F3",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "13px",
    fontWeight: "500",
    letterSpacing: "0.02857em",
    lineHeight: "1em",
    textTransform: "uppercase"
  };

  const checkboxHeaderStyle = {
    backgroundColor: "#1A206C",
    color: "#FFFFFF",
    "& .MuiCheckbox-root": {
      color: "#FFFFFF"
    }
  };

  const sx = {
    border: 0,
    "& .MuiDataGrid-overlayWrapper": {
      minHeight: 100
    },
    "& .MuiDataGrid-columnHeader svg": { color: "#FFFFFF" },
    '& .MuiDataGrid-cell[data-field="actions"]': actionStyle,
    "& .MuiDataGrid-columnHeaderCheckbox": checkboxHeaderStyle,

    // disable cell outline when clicked - might need to remove if
    // we start using cell selection
    "& .MuiDataGrid-cell:focus-within": { outline: "none" },
    "& .MuiDataGrid-columnHeader:focus-within": { outline: "none" },

    // action separators
    // NOTE: action elements are expected to be spans in our styling.
    // If you need another element type (eg. <a>), wrap it inside a span.
    '& .MuiDataGrid-cell[data-field="actions"] span': {
      borderRight: "1px #2196F3 solid",
      paddingRight: "8px" // equal to action container's "grid-gap"
    },
    '& .MuiDataGrid-cell[data-field="actions"] span:last-of-type': {
      borderRight: "0px"
    },

    // keep column separators visible at all times, not just on hover
    "& .MuiDataGrid-columnSeparator": {
      visibility: "visible"
    },

    // darken hover color of toolbar buttons
    "& .MuiDataGrid-toolbarContainer .MuiButtonBase-root:hover": {
      backgroundColor: "#EEEEEE"
    },

    // try to fix misalignment of final column when checkboxes are
    // enabled - TODO: figure out where the 15px of padding
    // comes from. It disappears on screen/table resize, only
    // appears on initial render. It looks dynamically inserted.
    "& .MuiDataGrid-pinnedColumnHeaders--right": {
      paddingRight: "0px !important"
    }
    // width: "100%",
    // overflow: "hidden"
  };

  // defaults, to be overridden by saved state
  const defaultState = {
    columns: {
      columnVisibilityModel: defaultColumnVisibility
    },
    pagination: { paginationModel: { pageSize: pageSizeInitial } },
    sorting: {
      sortModel: [{ field: sortField, sort: sortDirection }]
    }
  };

  // saved state
  const restoredState = () => {
    const savedState = localStorage?.getItem(tableName);
    if (savedState) return JSON.parse(savedState);
    return {};
  };

  // enforced state, overrides saved state
  // used to supercede any undesirable behavior being saved in saved state
  const enforcedState = {
    // do not use saved column orders or dimensions
    // remove if we start using column reordering or manual sizing
    columns: {
      ...defaultState.columns,
      ...restoredState().columns,
      dimensions: {},
      orderedFields: []
    },

    // ensure preference panel does not start open
    preferencePanel: {
      open: false
    }
  };

  if (!tableName) {
    return <div>GrowlTable must be provided a tableName prop.</div>;
  }

  return (
    // some container bullshit needed to make shrinking fluid width table work right
    // TODO: Figure out exactly what's going on
    // ref: https://github.com/mui/mui-x/issues/9532#issuecomment-1614930604
    // ref: https://codesandbox.io/p/sandbox/condescending-noyce-w2dknc?
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr 20rem",
        width: `calc(100% + 20rem)`,
        transition: "width 0.2s ease-out"
      }}
    >
      <div style={{ minWidth: 0 }}>
        <DataGridPremium
          {...restProps}
          apiRef={apiRef}
          checkboxSelection={checkboxSelection}
          columns={tableColumns}
          disableColumnMenu={disableColumnMenu}
          disableColumnReorder={disableColumnReorder}
          disableColumnResize={disableColumnResize}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          initialState={{
            ...defaultState,
            ...restoredState(),
            ...enforcedState
          }}
          onColumnVisibilityModelChange={saveSnapshot}
          onFilterModelChange={saveSnapshot}
          onPaginationModelChange={saveSnapshot}
          onPreferencePanelClose={saveSnapshot}
          onRowSelectionModelChange={handleSelection}
          onSortModelChange={saveSnapshot}
          pagination
          pageSizeOptions={pageSizeOptions}
          pinnedColumns={pinnedColumns}
          rows={items}
          slots={slots}
          slotProps={slotProps}
          sx={sx}
        />
      </div>
    </div>
  );
});

GrowlTableComponent.defaultProps = {
  columns: [],
  checkboxSelection: false,
  defaultColumnVisibility: {},
  disableColumnMenu: true,
  disableColumnReorder: true,
  disableColumnResize: true,
  disableRowSelectionOnClick: true,
  pageSizeInitial: 25,
  pageSizeOptions: [25, 50, 100],
  items: [],
  sortField: "",
  sortDirection: "asc",
  toolbarControls: []
};

GrowlTableComponent.propTypes = {
  checkboxSelection: PropTypes.bool,
  columns: PropTypes.array,
  defaultColumnVisibility: PropTypes.object,
  disableColumnMenu: PropTypes.bool,
  disableColumnReorder: PropTypes.bool,
  disableColumnResize: PropTypes.bool,
  disableRowSelectionOnClick: PropTypes.bool,
  items: PropTypes.array,
  pageSizeOptions: PropTypes.array,
  pageSizeInitial: PropTypes.number,
  sortField: PropTypes.string,
  sortDirection: PropTypes.string,
  tableName: PropTypes.string.isRequired,
  toolbarControls: PropTypes.array
};

export default GrowlTableComponent;
