import React from "react";

const WidgetContext = React.createContext({
  dnd: {},
  editMode: false,
  onUpdate: () => {},
  values: {},
  widget: {
    id: null
  }
});

export default WidgetContext;
