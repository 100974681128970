import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetFilterByStatus = (props) => {
  const { control, statuses } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Filter by Status (optional)</FormLabel>
          <Controller
            name="widget_config[filters][status]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={""}>(none)</MenuItem>
                {statuses.map((status) => (
                  <MenuItem key={`status-${status.id}`} value={status.id}>
                    {status.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

WidgetFilterByStatus.propTypes = {
  control: PropTypes.object.isRequired,
  statuses: PropTypes.array.isRequired
};

export default WidgetFilterByStatus;
