import React, { useContext, useEffect, useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import { alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import HousingOverviewHotel from "./HousingOverviewHotel";

const HousingOverview = () => {
  const { apiRoot, event } = useContext(EventContext).values;
  const [bookings, setBookings] = useState([]);
  const [hotels, setHotels] = useState([]);
  const [config, setConfig] = useState({});
  const [fetched, setFetched] = useState(false);
  const { user } = useContext(EventUserContext);

  useEffect(() => {
    const fetchOverview = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/overview"));
        setHotels(result.data.hotels);
        setConfig(result.data.configuration);
        setBookings(result.data.bookings);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchOverview();
  }, [apiRoot]);

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const renderHotels = () => {
    if (hotels.length > 0) {
      return hotels.map((hotel, idx) => (
        <HousingOverviewHotel
          key={hotel.gid}
          config={config}
          hotel={hotel}
          index={idx}
        />
      ));
    }
    return (
      <div className="sg-mgmt-content sg-mgmt-content-full">
        <h1>Housing</h1>
        <p>To begin using the Housing module:</p>
        <ol className="ml-2 mt-1">
          <li>1. Configure dates and other settings in the SETTINGS tab</li>
          <li>2. Create and manage hotels in the HOTELS tab</li>
        </ol>
      </div>
    );
  };

  const renderOverview = () => {
    if (fetched) {
      return (
        <div>
          {renderHotels()}
        </div>
      );
    }
    return <Loading />
  }

  return (
    <div>
      {renderOverview()}
    </div>
  );
};

export default HousingOverview;
