import React, { useContext, useMemo } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";
import clsx from "clsx";
import { toDate } from "date-fns-tz";
import Countdown from "react-countdown";

import WidgetContext from "@dashboard/WidgetContext";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";

import CountdownWidgetSettings from "./CountdownWidgetSettings";

const CountdownWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const { event } = useContext(EventContext).values;
  const countdownDate = useMemo(
    () => toDate(widget?.widget_config?.date, { timeZone: event.time_zone }),
    [widget, event]
  );
  const color = widget?.widget_theme || event?.color;
  const large = useMediaQuery("(min-width:1280px)");

  const numberBox = (number, label, alternateColor = false) => {
    const colorCls = alternateColor ? "text-ui-8" : "text-ui-6";
    return (
      <div className="flex flex-col items-center">
        <div
          className={clsx(
            "rounded bg-white px-2 py-2 text-center text-xl text-ui-6 md:text-2xl lg:px-3 lg:py-3 lg:text-4xl xl:px-4 xl:py-4 xl:text-5xl",
            colorCls
          )}
        >
          {number.toString().padStart(2, "0")}
        </div>
        <div className="text-sm text-white">{label}</div>
      </div>
    );
  };

  const colon = () => (
    <div className="flex flex-col items-center">
      <div className="rounded px-1 py-2 text-center text-xl text-white md:text-2xl lg:py-3 lg:text-4xl xl:py-4 xl:text-5xl">
        :
      </div>
    </div>
  );

  const spacer = () => (
    <div className="flex flex-col items-center">
      <div className="rounded px-1 py-2 text-center text-xl md:text-2xl lg:py-3 lg:text-4xl xl:py-4 xl:text-5xl">
        &nbsp;
      </div>
    </div>
  );

  const renderClock = (days, hours, minutes, seconds) => {
    return (
      <>
        {numberBox(days, "Days", true)}
        {spacer()}
        {numberBox(hours, "Hours")}
        {colon()}
        {numberBox(minutes, "Mins")}
        {colon()}
        {numberBox(seconds, "Secs")}
      </>
    );
  }

  const renderStandard = (days, hours, minutes, seconds, completed) => {
    return (
      <div className="m-auto w-full pb-4 text-center">
        <div className="pb-4">
          <span className="text-xl font-bold text-white md:text-2xl lg:text-2xl xl:text-2xl">
            {widget?.widget_header}
          </span>
        </div>
        <div>
          <div className="align-center align-start mx-auto mt-2 flex flex-row justify-center">
            {renderClock(days, hours, minutes, seconds, completed)}
          </div>
        </div>
      </div>
    );
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (large && widget.widget_size == 2) {
      return renderWide(days, hours, minutes, seconds, completed);
    }

    return renderStandard(days, hours, minutes, seconds, completed);
  };

  const renderCountdown = () => {
    return <Countdown date={countdownDate} renderer={renderer} />;
  };

  const renderWide = (days, hours, minutes, seconds, completed) => {
    return (
      <div className="flex w-full items-center">
        <div className="w-1/2 flex-1">
          <div className="text-right align-middle">
            <span className="text-xl font-bold text-white pr-4 md:text-2xl lg:text-4xl xl:text-5xl">
              {widget?.widget_header}
            </span>
          </div>
        </div>
        <div className="w-1/2 flex-1">
          <div className="mx-auto text-center align-left">
            <div className="align-center align-start pl-4 mt-2 flex flex-row justify-left">
              {renderClock(days, hours, minutes, seconds, completed)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderContent = () => {
    if (large && widget.widget_size == 2) {
      return renderCountdown();
    }
    return renderCountdown();
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Countdown Settings" settingsComponent={CountdownWidgetSettings} />;
  };

  return (
    <div className="group relative flex h-widget" style={{ backgroundColor: color }}>
      {renderContent()}
      {renderControls()}
    </div>
  );
};

export default CountdownWidget;
