import React from "react";

import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useField, useFormikContext } from "formik";

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);

  const onChange = (val) => {
    setFieldValue(field.name, val);
  };

  return (
    <DatePicker
      {...field}
      {...props}
      dateFormat="yyyy-MM-dd"
      selected={(field.value && new Date(field.value)) || null}
      onChange={onChange}
    />
  );
};

export default DatePickerField;
