import React from "react";

const Footer = () => (
  <footer className="sg-mgmt-footer">
    <div className="sg-mgmt-footer-logo-container">
      <img src="/images/silentgrowl-logo-footer.svg" alt="SilentGrowl logo" className="mx-auto" />
    </div>
  </footer>
);

export default Footer;
