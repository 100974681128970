import React, { useContext } from "react";

import EventContext from "@event/EventContext";

const StandardReportsSessions = () => {
  const { event } = useContext(EventContext).values;
  const reports = [
    { label: "All Sessions", path: `/reporting/events/${event.slug}/sessions` },
  ];

  const renderReport = (label, path) => (
    <div className="flex my-1" key={label}>
      <div className="w-[300px]">{label}</div>
      <div className="px-4">
        <a className="sg-mgmt-link" href={path}>
          XLSX
        </a>
      </div>
      <div className="px-4">
        <a className="sg-mgmt-link" href={`${path}?format=csv`}>
          CSV
        </a>
      </div>
    </div>
  );

  return (
    <div className="my-4">
      <h2>Sessions</h2>
      {reports.map((report) => renderReport(report.label, report.path))}
    </div>
  );
};

export default StandardReportsSessions;
