import React, { useContext } from "react";

import { LineChart } from "@mui/x-charts/LineChart";
import { DateTime } from "luxon";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import EventContext from "@event/EventContext";

import GraphTotal from "./GraphTotal";
import GraphWidgetSettings from "./GraphWidgetSettings";

const GraphWidget = () => {
  const { editMode, values, widget } = useContext(WidgetContext);
  const { event } = useContext(EventContext).values;
  const dataSet = widget?.widget_data_set;
  const color = widget?.widget_theme;

  const xAxisTimeFormat = () => {
    const timePeriod = widget?.widget_config?.time_period;

    if (timePeriod === "month") {
      return "LLL";
    } else {
      return "LLL dd";
    }
  };

  const renderGraph = () => {
    if (dataSet === "registrationActivity") {
      const xAxisFormat = xAxisTimeFormat();

      return (
        <LineChart
          xAxis={[
            {
              data: values?.series?.map(value => value.x),
              scaleType: "point",
              highlightScope: {
                highlighted: "item"
              },
              valueFormatter: val => {
                return DateTime.fromISO(val, { zone: event.time_zone }).toFormat(xAxisFormat);
              }
            }
          ]}
          series={[
            {
              area: true,
              curve: "linear",
              color: color,
              data: values?.series?.map(value => value.y),
              highlightScope: {
                highlighted: "item"
              },
              showMark: false,
              label: "Registrations"
            }
          ]}
          sx={{
            "& .MuiAreaElement-root": {
              fill: `${color}66`
            }
          }}
          grid={{ vertical: false, horizontal: true }}
          slotProps={{ legend: { hidden: true } }}
        />
      );
    }
  };

  const graphTotalValue = () => {
    return values.total || 0;
  };

  const renderContent = () => {
    if (!dataSet) {
      return <div className="mt-2">No data set selected</div>;
    }

    if (!values?.series) {
      return <div className="mt-2">No graphable data with these settings/filters.</div>;
    }

    return (
      <div className="justify-begin relative mt-2 flex" style={{ height: "210px" }}>
        {renderGraph()}
        <GraphTotal color={color} total={graphTotalValue()} />
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Graph Settings" settingsComponent={GraphWidgetSettings} />;
  };

  return (
    <WidgetContentFrame>
      <WidgetHeader />
      {renderContent()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default GraphWidget;
