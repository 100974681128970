import React from "react";
            
const FormsFormFieldTypeHelperContent = ({}) => {
  return (
    <div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">Text Input - Single Line</div>
        An input field that accepts a single line of text input. (ex: first name)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Text Input - Multiple Line
        </div>
        An input field that accepts multiple lines of text input. (ex: additional comments, share your experience)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Text - Read Only
        </div>
        A non-input section of text for providing information to the attendee or marking a section. (ex: headers, intros, descriptions for data fields)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Divider
        </div>
        A line to divide different sections of content.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Date
        </div>
        An input field that only accepts a date, which can be selected from a date picker. 
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Dropdown List
        </div>
        An input field in the form of a dropdown list of options where only one can be selected. (ex: select your shoe size).
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Radio List
        </div>
        An input field in the form of multiple bubbles where only one can be selected. (ex: select your age range) 
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Phone Number
        </div>
        An input field for phone numbers including country code, area code, and the number.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Checkbox List
        </div>
        An Input field in the form of multiple boxes where multiple options can be selected. (ex: choose all that apply)
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
          Ticket List
        </div>
         Multiple boxes to display all available tickets for an event.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Button
        </div>
        A button that allows attendees to navigate between pages or submit the form.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Page Signifiers
        </div>
        A visual indicator of which page you are currently on.
      </div>
      <div className="sg-mgmt-modal-helper-content-section-body">
        <div className="sg-mgmt-modal-helper-content-section-header">
        Custom HTML <span className="sg-mgmt-modal-helper-content-section-header-advanced">Advanced</span>
        </div>
        A place to add custom HTML code that is not otherwise available in the builder.
      </div>
    </div>
  )
}

export default FormsFormFieldTypeHelperContent