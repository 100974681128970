import React from "react";
import PropTypes from "prop-types";

import CustomReportFieldsModal from "./CustomReportFieldsModal";
import CustomReportFilters from "./CustomReportFilters";
import CustomReportKeys from "./CustomReportKeys";

// import ReportSortingCriteria from "../ReportSortingCriteria";
// import ReportPreview from "../ReportPreview";

const CustomReportCustomize = (props) => {
  const {
    edit,
    filterChains,
    initialFilterChains,
    objectKeys,
    report,
    selectedKeys,
    setFilterChains,
    setSelectedKeys,
    // setSortingCriteria,
    // sortingCriteria,
    submit
  } = props;
  console.log(`Render CustomReportCustomize with ${selectedKeys}`);

  const keySelected = (key) => {
    // skip if empty key
    if (!key.value || key.value === "") { return; }

    // add id to key for dnd-kit sorting animation
    const newKey = { ...key, id: key.value };

    let newSelectedKeys = selectedKeys;
    if (selectedKeys.some((e) => e.value === newKey.value)) {
      newSelectedKeys = newSelectedKeys.filter(function(e) {
        return e.value !== newKey.value;
      });
    } else {
      newSelectedKeys.push(newKey);
    }
    setSelectedKeys(newSelectedKeys);
  };

  const renderReportFields = () => {
    const keys = Object.entries(objectKeys).map((key) => {
      return {
        value: key[0],
        label: key[1]["label"]
      };
    });
    return (
      <>
        <div className="report-select-keys">
          <label>Columns:</label>
          <div>
            <div>
              <p className="report-keys-note">Drag and drop tags to change column order</p>
            </div>
            <div>
              <CustomReportKeys keys={keys} updateKeys={setSelectedKeys} selectedKeys={selectedKeys} />
            </div>
            <div>
              <CustomReportFieldsModal
                keys={keys}
                selectedKeys={selectedKeys}
                updateKeys={setSelectedKeys}
                keySelected={keySelected}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  // const renderSortingCriteria = () => {
  //   return (
  //     <div className="report-select-keys report-select-keys-divider">
  //       <label>Sorting Criteria:</label>
  //       <ReportSortingCriteria
  //         options={selectedKeys}
  //         updateSorting={setSortingCriteria}
  //         initialCriteria={sortingCriteria}
  //         report={report}
  //       />
  //     </div>
  //   );
  // }

  const renderFilters = () => {
    return (
      <div className="report-select-keys">
        <label>Filter(s):</label>
        <CustomReportFilters
          edit={edit}
          options={selectedKeys}
          updateFilterChains={setFilterChains}
          initialFilterChains={initialFilterChains}
          report={report}
        />
      </div>
    );
  };

  const renderButtons = () => {
    return (
      <div className="report-select-keys report-select-keys-divider">
        <button className="sg-mgmt-btn" onClick={submit}>
          Continue
        </button>
        <button
          className="sg-mgmt-btn-cancel"
          style={{ marginLeft: "10px" }}
          onClick={() => {
            history.back();
          }}
        >
          Cancel
        </button>
      </div>
    );
  };

  // const renderPreview = () => {
  //   return (
  //     <ReportPreview
  //       objectKeys={selectedKeys}
  //       keysWithDataType={objectKeys}
  //       filterChains={filterChains}
  //       sortingCriteria={sortingCriteria}
  //     />
  //   );
  // };

  return (
    <div className="report-tool-form-wrapper" style={{ margin: "0px" }}>
      <div className="report-tool-page-header">
        <h1 className="cnt-page-header">{report === undefined ? "New Report" : report.name}</h1>
      </div>
      <div style={{ clear: "both" }} />
      <div className="report-tool-page-sub-header">Customize your report</div>
      {renderReportFields()}
      <div className="report-select-keys-divider" />
      {renderFilters()}
      {/* renderSortingCriteria() */}
      {renderButtons()}
    </div>
  );
};

CustomReportCustomize.propTypes = {
  edit: PropTypes.bool.isRequired,
  filterChains: PropTypes.array.isRequired,
  objectKeys: PropTypes.object.isRequired,
  selectedKeys: PropTypes.array.isRequired,
  setFilterChains: PropTypes.func.isRequired,
  setSelectedKeys: PropTypes.func.isRequired,
  setSortingCriteria: PropTypes.func.isRequired,
  sortingCriteria: PropTypes.array.isRequired,
  submit: PropTypes.func.isRequired
};

export default CustomReportCustomize;
