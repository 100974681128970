import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlTable from "@shared/GrowlTable";
import Loading from "@shared/Loading";
import PageHeader from "@shared/PageHeader";
import { formatDate } from "@shared/TimeUtils";

import CommunicationsEmailTemplatePreviewModal from "./CommunicationsEmailTemplatePreviewModal";

const CommunicationsEmailTemplates = props => {
  const { apiRoot, event } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const { goEdit, goNew, goTags, setEditTemplate } = props;
  const [templates, setTemplates] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [previewTemplateId, setPreviewTemplateId] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [showPreview, setShowPreview] = useState(false);

  useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/communications/email_templates")
        );
        setTemplates(result.data.templates);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchTemplates();
  }, [apiRoot]);

  const toggleArchived = () => {
    setShowArchived(!showArchived);
  };

  const editEnabled = () => {
    if (user.role === "basic" && !user.permission.communications_edit) {
      return false;
    }
    return true;
  };

  const updateEmailTemplates = template => {
    const newTemplates = templates.map(em =>
      em.gid === template.gid ? template : em
    );
    setTemplates(newTemplates);
  };

  const archive = (id, unarchive = false) => {
    let verb = "archive";
    if (unarchive) {
      verb = "unarchive";
    }
    const postData = { template_id: id };
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(
        apiRoot,
        "/communications/email_templates/",
        `/${id}`,
        `/${verb}`
      ),
      method: "PATCH",
      data: postData
    }).then(response => {
      if (response.data.error === null) {
        updateEmailTemplates(response.data.template);
        alertSuccess(`Email template ${verb}ed`);
      } else {
        alertError(`Failed to ${verb} email template`);
      }
    });
  };

  const archiveToggle = (id, arch) => {
    if (arch) {
      archive(id, true);
    }
    archive(id, false);
  };

  const archiveLabel = arch => {
    if (arch) {
      return "Unarchive";
    }
    return "Archive";
  };

  const renderTemplateId = template => {
    return template.message_type === "broadcast" ? "n/a" : template.template_id;
  };

  const renderTemplateType = template => {
    return template.message_type === "broadcast" ? "Direct" : "Transactional";
  };

  const renderTemplateTags = tags => {
    return tags?.map(tag => tag.name).join(", ");
  };

  const renderArchivedToggle = () => {
    const icon = showArchived ? (
      <FontAwesomeIcon icon={faCaretDown} />
    ) : (
      <FontAwesomeIcon icon={faCaretRight} />
    );
    return (
      <div
        onClick={toggleArchived}
        className="mb-1 cursor-pointer font-bold text-sg-orange"
      >
        {icon}
        <span className="ml-1">Archive</span>
      </div>
    );
  };

  const renderArchiveLink = template => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            archiveToggle(template.id, template.archived);
          }}
        >
          {archiveLabel(template.archived)}
        </span>
      </>
    );
  };

  const renderEditLink = template => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setEditTemplate(template);
            goEdit();
          }}
        >
          Edit
        </span>
      </>
    );
  };

  const renderPreviewLink = template => {
    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            setPreviewTemplateId(template.id);
            setShowPreview(true);
          }}
        >
          Preview
        </span>
      </>
    );
  };

  const columns = [
    {
      headerName: "Template Name",
      field: "name",
      flex: 1
    },
    {
      headerName: "Template Type",
      field: "template_type",
      flex: 1,
      renderCell: params => renderTemplateType(params.row)
    },
    {
      headerName: "Created By",
      field: "created_by",
      flex: 1,
      renderCell: params =>
        params.row.user
          ? `${params.row.user.name_first} ${params.row.user.name_last}`
          : "(system)"
    },
    {
      headerName: "Last Modified",
      field: "updated_at",
      flex: 1,
      renderCell: params => formatDate(params.value, event.time_zone)
    },
    {
      headerName: "Template ID",
      field: "template_id",
      flex: 1,
      renderCell: params => renderTemplateId(params.row)
    },
    {
      headerName: "Tags",
      field: "tags",
      flex: 1,
      renderCell: params => renderTemplateTags(params.value)
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      align: "right",
      minWidth: 200,
      flex: 1,
      getActions: params => [
        renderPreviewLink(params.row),
        renderEditLink(params.row),
        renderArchiveLink(params.row)
      ]
    }
  ];

  const renderEmailTemplatesTable = (templateSet, tableName) => {
    return (
      <div>
        <GrowlTable
          columns={columns}
          items={templateSet}
          tableName={tableName}
          sortColumn="name"
          sortDirection="asc"
        />
      </div>
    );
  };

  const renderArchived = archivedTemplates => {
    if (!showArchived) {
      return <></>;
    }

    return (
      <>
        {renderEmailTemplatesTable(
          archivedTemplates,
          `${event.slug}-email-templates-archived`
        )}
      </>
    );
  };

  const renderEmailTemplates = () => {
    if (!fetched) {
      return <Loading />;
    }

    return (
      <div>
        {renderEmailTemplatesTable(
          templates.filter(e => e.archived === false),
          `${event.slug}-email-templates`
        )}
        <div className="py-3" />
        {renderArchivedToggle()}
        {renderArchived(templates.filter(e => e.archived === true))}
      </div>
    );
  };

  const renderNewTemplateButton = () => {
    if (editEnabled()) {
      return renderCreateButton("Create New Template", goNew);
    }
  };

  const renderTemplatePreviewModal = () => {
    if (!fetched || !previewTemplateId) {
      return <></>;
    }

    return (
      <CommunicationsEmailTemplatePreviewModal
        templateId={previewTemplateId}
        modalVisible={showPreview}
        modalClose={() => {
          setShowPreview(false);
          setPreviewTemplateId(null);
        }}
      />
    );
  };

  const renderSettingsButton = () => {
    if (!editEnabled()) {
      return <></>;
    }

    return (
      <div className="sg-mgmt-content-header-button">
        <img
          src="/images/icons/gear-grey-round.svg"
          alt="Edit Email Template Tags"
          type="button"
          onClick={goTags}
        />
      </div>
    );
  };

  return (
    <div>
      {renderSettingsButton()}
      <PageHeader text="Email Templates" />
      {renderNewTemplateButton()}
      {renderEmailTemplates()}
      {renderTemplatePreviewModal()}
    </div>
  );
};

CommunicationsEmailTemplates.defaultProps = {
  back: () => {}
};

CommunicationsEmailTemplates.propTypes = {
  back: PropTypes.func,
  goNew: PropTypes.func.isRequired,
  goEdit: PropTypes.func.isRequired,
  goTags: PropTypes.func.isRequired,
  setEditTemplate: PropTypes.func.isRequired
};

export default CommunicationsEmailTemplates;
