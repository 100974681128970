import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import axios from "axios";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import HousingRoomBlockRoomTypeInventoryForm from "./HousingRoomBlockRoomTypeInventoryForm";

const HousingRoomBlockRoomTypeInventoryModal = props => {
  const {
    config,
    block,
    blocks,
    modalVisible,
    resetModal,
    roomBlockRoomType,
    title,
    updateFunc
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [dates, setDates] = useState([]);
  const [fetched, setFetched] = useState(false);

  Modal.setAppElement("#root");

  // useEffect(() => {
  //   setFetched(false);
  //
  //   const fetchRoomTypes = async () => {
  //     try {
  //       const result = await axios(
  //         urljoin(
  //           apiRoot,
  //           "/housing/blocks",
  //           `/${block.id}`,
  //           "/room_types",
  //           `/${roomType.id}`,
  //           "/dates"
  //         )
  //       );
  //       setDates(result.data);
  //       setFetched(true);
  //     } catch (error) {
  //       alertHttpError(error);
  //     }
  //   };
  //
  //   if (roomType.id) {
  //     fetchRoomTypes();
  //   }
  // }, [apiRoot, block.id, roomType.id]);

  const success = response => {
    alertSuccess("Inventory updated successfully");
    updateFunc(response.data.block);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to update inventory");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomBlockRoomTypeInventoryForm
            config={config}
            blocks={blocks}
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            fetched
            block={block}
            roomBlockRoomType={roomBlockRoomType}
          />
        </div>
      </div>
    </Modal>
  );
};

HousingRoomBlockRoomTypeInventoryModal.propTypes = {
  config: PropTypes.object.isRequired,
  block: PropTypes.object.isRequired,
  blocks: PropTypes.array.isRequired,
  roomBlockRoomType: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default HousingRoomBlockRoomTypeInventoryModal;
