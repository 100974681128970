import React, { useEffect, useState } from "react";
import CommunicationsEmailMailingList from "./CommunicationsEmailMailingList";
import CommunicationsEmailMailingLists from "./CommunicationsEmailMailingLists";
import CommunicationsEmailMailingListImport from "./import/CommunicationsEmailMailingListImport";
import CommunicationsEmailMailingListImportLog from "./import/CommunicationsEmailMailingListImportLog";
import CommunicationsEmailMailingListForm from "./CommunicationsEmailMailingListForm";

const CommunicationsEmailMailingListsApp = props => {
  const [page, setPage] = useState("home");
  const [editMailingList, setEditMailingList] = useState(null);
  const { forceUpdate } = props;

  const goToPage = p => {
    setPage(p);
  };

  const reset = () => {
    setEditMailingList(null);
    goToPage("home");
  };

  useEffect(() => {
    reset();
  }, [forceUpdate]);

  const renderPage = () => {
    switch (page) {
      case "home":
        return (
          <CommunicationsEmailMailingLists
            goNew={() => goToPage("new")}
            goEdit={() => goToPage("edit")}
            setEditMailingList={setEditMailingList}
          />
        );
      case "new":
        return (
          <CommunicationsEmailMailingListForm
            cancel={reset}
            callbackSuccess={() => goToPage("home")}
          />
        );
      case "edit":
        return (
          <CommunicationsEmailMailingList
            back={reset}
            listId={editMailingList.id}
            goImport={() => goToPage("import")}
            goLog={() => goToPage("log")}
          />
        );
      case "import":
        return (
          <CommunicationsEmailMailingListImport
            back={reset}
            goEdit={() => goToPage("edit")}
            listId={editMailingList.id}
          />
        );
      case "log":
        return (
          <CommunicationsEmailMailingListImportLog
            back={() => goToPage("edit")}
            listId={editMailingList.id}
          />
        );
      default:
        throw new Error("invalid mailing lists page");
    }
  };

  return (
    <div className="sg-mgmt-content sg-mgmt-content-full">{renderPage()}</div>
  );
};

export default CommunicationsEmailMailingListsApp;
