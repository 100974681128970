import React, { useContext } from "react";

import clsx from "clsx";
import sortBy from "lodash/sortBy";

import WidgetDownloadIcon from "@dashboard/form/WidgetDownloadIcon";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import StatTile from "@shared/StatTile";

const OverviewTickets = () => {
  const { widget, values } = useContext(WidgetContext);
  const { event } = useContext(EventContext).values;
  const tableCls = "pb-4 pr-4 text-black";
  const tableHeaderStyle = { width: "120px", fontWeight: "400" };

  const renderItemTypeName = () => {
    return "Ticket Type";
  };

  const reportUrl = (gid) => {
    switch (widget.widget_data_set) {
      case "ticketsOverview":
        return `/reports/events/${event.slug}/ticket_stats/${gid}`;
      default:
        return "";
    }
  };

  const renderReportLink = (gid) => {
    return (
      <div className="mx-auto" style={{ width: "fit-content" }}>
        <a href={reportUrl(gid)}>
          <WidgetDownloadIcon size={36} />
        </a>
      </div>
    );
  };

  const renderTableHeaders = () => {
    return (
      <thead>
        <tr className="text-xs font-normal uppercase tracking-widest">
          <th className={clsx(tableCls, "text-left")} style={{ width: "240px", fontWeight: "400" }}>
            {renderItemTypeName()}
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Available Total
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Assigned
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Assigned %
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Available
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Report
          </th>
          <th className={tableCls}>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const renderRows = () => sortBy(values, ["delivered", "opened"]).map((item, _idx) => renderRow(item));

  const renderRow = (item) => {
    return (
      <tr key={item.gid}>
        <td className={tableCls}>{item.name}</td>
        <td className={tableCls}>
          <StatTile color={"#1A206C"} value={item.total_count} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#1B5EC1"} value={item.total_assigned} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#2196F3"} value={item.total_assigned_percent} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#F89728"} value={item.available_tickets} />
        </td>
        <td className={tableCls}>{renderReportLink(item.gid)}</td>
      </tr>
    );
  };

  return (
    <table className="w-full">
      {renderTableHeaders()}
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export default OverviewTickets;
