export default {
  id: null,
  name: "",
  address_street_1: "",
  address_street_2: "",
  address_city: "",
  address_state: "",
  address_postcode: "",
  address_country: "",
  phone: ""
};
