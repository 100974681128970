export default {
  individual_gid: "",
  override_name_first: "",
  override_name_last: "",
  bio: "",
  tags: [],
  role: "attendee",
  status: "preregistered",
  data: {},
  gid: "",
  sort_order: 0,
  individual: { gid: "" }
};
