import React, { useState } from "react";
import PropTypes from "prop-types";

import { FormControl, Autocomplete, Stack, TextField } from "@mui/material";
import Modal from "react-modal";

import BubbleWidgetSettings from "@dashboard/widgets/bubble/BubbleWidgetSettings";
import CountdownWidgetSettings from "@dashboard/widgets/countdown/CountdownWidgetSettings";
import EmailWidgetSettings from "@dashboard/widgets/email/EmailWidgetSettings";
import GraphWidgetSettings from "@dashboard/widgets/graph/GraphWidgetSettings";
import IframeWidgetSettings from "@dashboard/widgets/iframe/IframeWidgetSettings";
import ImageWidgetSettings from "@dashboard/widgets/image/ImageWidgetSettings";
import MicroformWidgetSettings from "@dashboard/widgets/microform/MicroformWidgetSettings";
import OverviewWidgetSettings from "@dashboard/widgets/overview/OverviewWidgetSettings";
import PieWidgetSettings from "@dashboard/widgets/pie/PieWidgetSettings";
import TextWidgetSettings from "@dashboard/widgets/text/TextWidgetSettings";
import { renderButton, renderCancelButton } from "@shared/FormUtils";

import WidgetContext from "./WidgetContext";

const WidgetNewModal = props => {
  const { onUpdate } = props;
  const [visible, setVisible] = useState(false);
  const [widgetType, setWidgetType] = useState("");
  const [widgetTypeSelected, setWidgetTypeSelected] = useState(false);

  Modal.setAppElement("#root");

  const widgetTypes = ["Bubble", "Countdown", "Email Statistics", "Graph", "Iframe", "Image", "Microform", "Overview", "Pie", "Text"];

  const widgetContext = {
    widget: {},
    onUpdate: onUpdate
  };

  const resetModal = () => {
    setVisible(false);
    setWidgetType("");
    setWidgetTypeSelected(false);
  };

  const toggleModal = () => {
    setVisible(!visible);
  };

  const renderWidgetTypeSelect = () => {
    return (
      <div className="my-4">
        <FormControl fullWidth>
          <Autocomplete
            className="cursor-pointer"
            label={"Widget Type"}
            options={widgetTypes}
            onChange={(_evt, value) => {
              setWidgetType(value);
            }}
            renderInput={params => <TextField {...params} autoComplete="off" placeholder="Widget type" />}
            variant={"outlined"}
          />
        </FormControl>
      </div>
    );
  };

  const renderWidgetSettings = () => {
    // not working, play around with esbuild and dynamic imports later
    // const componentPath = `./widgets/${widgetType.toLowerCase()}/${widgetType}WidgetSettings`;
    // console.log(componentPath);
    // const SettingsComponent = lazy(() => import(componentPath));
    //
    // return (
    //   <Suspense fallback={<div>Loading...</div>}>
    //     <SettingsComponent closeModal={resetModal} />
    //   </Suspense>
    // );

    switch (widgetType) {
      case "Bubble":
        return <BubbleWidgetSettings closeModal={resetModal} />;
      case "Countdown":
        return <CountdownWidgetSettings closeModal={resetModal} />;
      case "Email Statistics":
        return <EmailWidgetSettings closeModal={resetModal} />;
      case "Graph":
        return <GraphWidgetSettings closeModal={resetModal} />;
      case "Iframe":
        return <IframeWidgetSettings closeModal={resetModal} />;
      case "Image":
        return <ImageWidgetSettings closeModal={resetModal} />;
      case "Microform":
        return <MicroformWidgetSettings closeModal={resetModal} />;
      case "Overview":
        return <OverviewWidgetSettings closeModal={resetModal} />;
      case "Pie":
        return <PieWidgetSettings closeModal={resetModal} />;
      case "Text":
        return <TextWidgetSettings closeModal={resetModal} />;
      default:
        return <div>Invalid widget type selected: {`${widgetType}`}</div>;
    }
  };

  const renderStepOne = () => {
    return (
      <div className="sg-mgmt-modal-view">
        <h2>Select a widget type</h2>
        {renderWidgetTypeSelect()}
        <Stack spacing={2} direction="row">
          {renderButton(
            "Continue",
            () => {
              setWidgetTypeSelected(true);
            },
            { color: "secondary" }
          )}
          {renderCancelButton("Close", toggleModal, { color: "secondary" })}
        </Stack>
      </div>
    );
  };

  const renderStepTwo = () => {
    return <div className="sg-mgmt-modal-view">{renderWidgetSettings()}</div>;
  };

  const renderContent = () => {
    return <div>{widgetTypeSelected ? renderStepTwo() : renderStepOne()}</div>;
  };

  const renderModal = () => {
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Add Widget</div>
        <div className="sg-mgmt-modal-content">{renderContent()}</div>
      </div>
    );
  };

  return (
    <WidgetContext.Provider value={widgetContext}>
      <Modal
        className="sg-mgmt-modal"
        overlayClassName="sg-mgmt-modal-overlay"
        isOpen={visible}
        onRequestClose={resetModal}
        contentLabel={"Add Widget"}
      >
        {renderModal()}
      </Modal>
      {renderButton("Add Widget", toggleModal, { color: "secondary" })}
    </WidgetContext.Provider>
  );
};

WidgetNewModal.propTypes = {
  onUpdate: PropTypes.func.isRequired
};

export default WidgetNewModal;
