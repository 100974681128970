import React, { useEffect, useRef, useState } from "react";

import Modal from "react-modal";
import Cookies from "universal-cookie";

const modalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    padding: "20px",
    minWidth: "400px"
  },
  overlay: {
    zIndex: 1000
  }
};

Modal.setAppElement("#root");

const CustomReportDownload = (props) => {
  const { url } = props;
  const downloadUrlRef = useRef(null);
  const pollUrlRef = useRef(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [pollUrl, setPollUrl] = useState(null);

  useEffect(() => {
    pollUrlRef.current = pollUrl;
    downloadUrlRef.current = downloadUrl;
  }, [downloadUrl, pollUrl]);

  const openModal = () => {
    setModalOpen(true);
    generateDownloadRequest();
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const generateDownloadRequest = () => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(url, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error == null) {
          const poll_url = json.fetch_path;
          setPollUrl(poll_url);
          setTimeout(fetchDownload, 10000);
        } else {
          console.log(json.error);
        }
      });
  };

  const fetchDownload = () => {
    const cookies = new Cookies();
    const token = cookies.get("X-CSRF-Token");

    fetch(pollUrlRef.current, {
      method: "GET",
      redirect: "manual",
      headers: {
        "Content-Type": "application/json",
        "X-CSRF-Token": token
      }
    })
      .then((response) => {
        return response.json();
      })
      .then((json) => {
        if (json.error !== null) {
          console.log(json.error);
        } else {
          if (json.complete) {
            setDownloadUrl(json.url);
          } else {
            setTimeout(fetchDownload, 10000);
          }
        }
      });
  };

  const renderModal = () => {
    if (downloadUrl) {
      return (
        <>
          <p>Your report is ready.</p>
          <a className="sg-mgmt-link cursor-pointer" href={downloadUrl} target="_blank" rel="noreferrer">
            Click here to download report
          </a>
        </>
      );
    }
    return (
      <>
        <p>Generating the requested report</p>
        <p>Please wait...</p>
      </>
    );
  };

  return (
    <>
      <span className="cursor-pointer" onClick={openModal}>
        Download
      </span>
      <Modal isOpen={modalOpen} onRequestClose={closeModal} style={modalStyles} contentLabel="pdfs">
        {renderModal()}
      </Modal>
    </>
  );
};

export default CustomReportDownload;
