import React, { useContext } from "react";
import TabbedPage from "@shared/TabbedPage";
import CommunicationsEmailsApp from "./emails/CommunicationsEmailsApp";
import CommunicationsEmailTemplatesApp from "./templates/CommunicationsEmailTemplatesApp";
import CommunicationsEmailMailingListsApp from "./mailing_lists/CommunicationsEmailMailingListsApp";
import CommunicationsEmailSettings from "./settings/CommunicationsEmailSettings";
import { TabSettingsIcon } from "@shared/tabs/TabIcons";
import EventUserContext from "@event/EventUserContext";
import PostmarkApiValidator from "./postmark/PostmarkApiValidator";
const Communications = () => {
  const { user } = useContext(EventUserContext);


  const getTabs = () => {
    let tabs = [
      { label: "Email",           component: CommunicationsEmailsApp,            componentProps: {}},
      { label: "Email Templates", component: CommunicationsEmailTemplatesApp,    componentProps: {}},
      { label: "Mailing Lists",   component: CommunicationsEmailMailingListsApp, componentProps: {}},
    ]

    if (user && (user.role != "basic" || user.permission.communications_edit)) {
      tabs.push(
        { label: "Settings", component: CommunicationsEmailSettings, componentProps: {}, icon: TabSettingsIcon},
      )
    }
    return tabs
  }

  return (
    <>
      <PostmarkApiValidator />
      <TabbedPage
        tabs={getTabs()}
      />
    </>
  )
};

export default Communications;
