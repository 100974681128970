import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { alertError, alertSuccess } from "@shared/Alerts";
import HousingRoomBlockForm from "./HousingRoomBlockForm";

const HousingRoomBlockModal = props => {
  const {
    block,
    modalVisible,
    resetModal,
    title,
    updateFunc
  } = props;

  Modal.setAppElement("#root");

  const success = response => {
    alertSuccess("Room Block saved successfully");
    updateFunc(response.data.block);
    resetModal();
  };

  const failure = () => {
    alertError("Failed to save room block");
  };

  return (
    <Modal
      className="sg-mgmt-modal"
      contentLabel={title}
      isOpen={modalVisible}
      overlayClassName="sg-mgmt-modal-overlay"
      onRequestClose={resetModal}
    >
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">{title}</div>
        <div className="sg-mgmt-modal-content">
          <HousingRoomBlockForm
            callbackFailure={failure}
            callbackSuccess={success}
            cancelButton={resetModal}
            block={block}
          />
        </div>
      </div>
    </Modal>
  );
};

HousingRoomBlockModal.propTypes = {
  block: PropTypes.object.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  resetModal: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default HousingRoomBlockModal;
