import { renderSelectField } from "@shared/FormUtils";

const TimeZonePickerField = ({ ...props }) => {
  const zones = [
    { label: "Eastern Time (America/New_York)", value: "America/New_York" },
    { label: "Central Time (America/Chicago)", value: "America/Chicago" },
    { label: "Mountain Time (America/Denver)", value: "America/Denver" },
    {
      label: "Pacific Time (America/Los_Angeles)",
      value: "America/Los_Angeles"
    },
    { label: "America/Phoenix", value: "America/Phoenix" },
    { label: "GMT (Europe/London)", value: "Europe/London" },
    { label: "CET (Europe/Paris)", value: "Europe/Paris" },
    { label: "EET (Europe/Athens)", value: "Europe/Athens" },
    { label: "CST (Asia/Shanghai)", value: "Asia/Shanghai" },
    { label: "JST (Asia/Tokyo)", value: "Asia/Tokyo" },
    { label: "AWT (Australia/Perth)", value: "Australia/Perth" },
    { label: "AET (Australia/Sydney)", value: "Australia/Sydney" }
  ];

  return renderSelectField(props.label, props.name, zones, props.formatClasses || [], props.required, props.properties);
};

export default TimeZonePickerField;
