import React, { useEffect } from 'react'

const FormikObserver = ({formikProps, onChange}) => {
  useEffect(() => {
    onChange(formikProps)
  }, [JSON.stringify(formikProps.values)])

  return <></>
}

export default FormikObserver

