import React, { useContext } from "react";

import WidgetContext from "@dashboard/WidgetContext";

const WidgetHeader = () => {
  const { widget } = useContext(WidgetContext);
  return (
    <div className="h-widget-head">
      <span className="text-xl text-sg-orange font-medium tracking-[0.01em]">{widget?.widget_header}</span>
    </div>
  );
};

export default WidgetHeader;
