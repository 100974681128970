import React from 'react';
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';

const FormsFormsFormPagesPage = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({id: props.id});
  const { active } = props ;
  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <li onClick={()=>props.changePage(props.id)}  {...attributes} className={`sg-mgmt-forms-tab ${active ? 'active' : ""}`} ref={setNodeRef} style={style}>
     {props.page.name ? props.page.name : `Page ${props.page.sort_order + 1}`}
     <img className='sg-mgmt-forms-page-drag-icon' src="/images/icons/drag_dots.svg" {...listeners} />
    </li>
  );
}

export default FormsFormsFormPagesPage
