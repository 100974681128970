import React, { useEffect, useState } from "react";
const FormsFormContentDropdown = ({
  onMount,
  header,
  children,
  defaultOpen
}) => {
  const [open, setOpen] = useState(defaultOpen || false);

  useEffect(() => {
    onMount ? onMount() : "";
  }, []);

  const toggleOpen = () => {
    setOpen(!open);
  }

  return (
    <div className={`sg-mgmt-forms-content-dropdown ${open ? "open" : "closed"}`}>
      <div className="sg-mgmt-forms-content-dropdown-header" onClick={toggleOpen}>
        <img
          src="/images/icons/dropdown-arrow-down.png"
          className="sg-mgmt-forms-content-dropdown-header-signifier"
        />
        <div className="sg-mgmt-forms-content-dropdown-header-copy">
          {header}
        </div>
      </div>
      <div className="sg-mgmt-forms-content-dropdown-inner">
        {children}
      </div>
    </div>
  )
};

export default FormsFormContentDropdown;
