import React, { useContext } from "react";

import sanitizeHtml from "sanitize-html";

import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";

import TextWidgetSettings from "./TextWidgetSettings";

const TextWidget = () => {
  const { editMode, widget } = useContext(WidgetContext);
  const text = widget?.widget_config?.text || "";

  const renderHeader = () => {
    if (!widget?.widget_header) return <></>;

    return (
      <div className="mb-2">
        <WidgetHeader />
      </div>
    );
  };

  const renderContent = () => {
    const cleanHtml = sanitizeHtml(text, {
      allowedTags: ["a", "b", "br", "em", "i", "img", "li", "ol", "p", "span", "strong", "u", "ul"],
      allowedAttributes: {
        a: ["href", "name", "target", "style"],
        img: ["src"],
        li: ["style"],
        p: ["style"],
        span: ["style"]
      }
    });
    return (
      <div className="prose prose-neutral text-left text-black">
        <div dangerouslySetInnerHTML={{ __html: cleanHtml }} />
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Text Settings" settingsComponent={TextWidgetSettings} />;
  };

  return (
    <div className="group relative h-widget p-7">
      {renderHeader()}
      {renderContent()}
      {renderControls()}
    </div>
  );
};

export default TextWidget;
