export default {
  name: "",
  html: "",
  default_subject: "",
  default_preview_text: "",
  default_from_name: "",
  default_from_username: "",
  default_reply_address: "",
  message_type: "broadcast",
  archived: false
};
