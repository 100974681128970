import React from "react";
import PropTypes from "prop-types";

import { FormControl } from "@mui/material";
import { Controller } from "react-hook-form";

import WidgetColorPicker from "./WidgetColorPicker";

const WidgetColorThemeField = props => {
  const { control, fieldName } = props;

  const selectorColors = [
    "#1A206C",
    "#1B5EC1",
    "#2196F3",
    "#FDB92A",
    "#F89728",
    "#D2450F",
    "#96BC33",
    "#557218",
    "#737373"
  ];

  return (
    <div className="mt-2 w-full align-bottom">
      <FormControl fullWidth>
        <div className="mb-2 text-lg text-sg-orange">Select color theme:</div>
        <Controller
          name={fieldName}
          control={control}
          defaultValue={"#1A206C"}
          render={({ field: { value, onChange } }) => (
            <WidgetColorPicker color={value} colors={selectorColors} onChange={onChange} circleSize={40} />
          )}
        />
      </FormControl>
    </div>
  );
};

WidgetColorThemeField.propTypes = {
  control: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  errors: PropTypes.object.isRequired
};

export default WidgetColorThemeField;
