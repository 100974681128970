import React, { useContext, useState } from "react";

import { closestCenter, DndContext, PointerSensor, useSensor, useSensors } from "@dnd-kit/core";
import { arrayMove, SortableContext, verticalListSortingStrategy } from "@dnd-kit/sortable";
import Stack from "@mui/material/Stack";
import axios from "axios";
import { Formik, Form } from "formik";
import { useConfirm } from "material-ui-confirm";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError, alertError, alertSuccess } from "@shared/Alerts";
import FormikObserver from "@shared/FormikObserver";
import {
  renderCancelButton,
  renderSubmitButton,
  renderTextField,
  renderButton,
  renderDeleteButton
} from "@shared/FormUtils";

import FormsFormPageQuestionForm from "./FormsFormPageQuestionForm";
import { previewById } from "./utils/FormPreviewUtils";

const FormsFormsFormPageForm = ({
  setFormPage,
  setDirty,
  formPage,
  form,
  cancel,
  callbackSuccess,
  callbackFailure,
  removeFormPage,
  metaData,
  ticketTypes,
  openMetaDataModal,
  isFormEditedSinceEditingTime,
  resetEditingTime
}) => {
  const confirm = useConfirm();
  const sensors = useSensors(useSensor(PointerSensor));
  const [expandRetractSettings, setExpandRetractSettings] = useState({
    value: "retract",
    updatedAt: new Date()
  });

  const { apiRoot } = useContext(EventContext).values;
  const pageInitialValues = () => {
    let initial_values = {
      name: formPage.name
    };
    return initial_values;
  };

  const questionsInitialValues = () => {
    return formPage.questions || [];
  };

  const renderForm = () => {
    return (
      <div className="sg-mgmt-form-container">
        <div className="sg-mgmt-forms-form-section">
          <div className="flex">
            <div className="mr-4 w-full" style={{ marginRight: "33.3%" }}>
              {renderTextField("Page Name", "page[name]", [], false, true)}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const formValidation = (values) => {
    const errors = {};

    if (!values.form.form_id) {
      alert("You must enter a unique form id.");
      errors.form = errors.form || {};
      errors.form.form_id = "Required";
    }
    if (!values.form.name) {
      alert("You must enter a unique form id.");
      errors.form = errors.form || {};
      errors.form.name = "Required";
    }

    return errors;
  };

  const previewPage = (formikProps) => {
    const temporary_page = { ...formPage, ...JSON.parse(JSON.stringify(formikProps.values.page)) };
    const temporary_questions = JSON.parse(JSON.stringify(formikProps.values.questions.filter((x) => !x.archived)));
    temporary_page.questions = temporary_questions;
    previewById(form.id, apiRoot, {}, [temporary_page]);
  };

  const renderButtons = (formikProps) => {
    const { isSubmitting } = formikProps;
    return (
      <Stack direction="row" spacing={2}>
        {renderSubmitButton(formConfig.saveButton, isSubmitting)}
        {form && form.data && form.data.custom_preview_link
          ? renderButton("Preview", () => previewPage(formikProps), {
              color: "preview"
            })
          : ""}
        {renderDeleteButton(formConfig.deleteButton, deletePage, isSubmitting)}
        {renderCancelButton("Cancel", cancel)}
      </Stack>
    );
  };

  const deletePage = () => {
    confirm({
      title: "Delete Page",
      description: "Are you sure you wish to delete this page? This cannot be undone."
    }).then(() => {
      const token = document.querySelector("[name=csrf-token]").content;
      axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

      axios({
        url: formConfig.formUrl,
        method: "DELETE"
      })
        .then((response) => {
          if (response.data.error === null) {
            removeFormPage(formPage.gid);
          } else {
            callbackFailure ? callbackFailure(response) : () => {};
            alertError(`Unable to delete form page: ${response.data.error}`);
          }
        })
        .catch((error) => {
          alertHttpError(error);
        });
    });
  };

  const formConfig = {
    alert: "updated",
    formId: "sg-mgmt-form-forms-edit",
    formUrl: urljoin(apiRoot, "/forms", `${form.id}`, "/pages", `/${formPage.id}`),
    createQuestionUrl: urljoin(apiRoot, "/forms", `${form.id}`, "/pages", `/${formPage.id}`, "/questions"),
    method: "PATCH",
    saveButton: "Save",
    deleteButton: "Delete",
    title: "Edit Form Page"
  };

  const renderQuestions = (formikProps) => {
    const { values } = formikProps;
    const { questions } = values;

    const sortedQuestions = getSortedQuestions(questions);

    const rendered_questions = sortedQuestions.map((q, i) => renderQuestion(q, i, formikProps));
    return (
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragEnd={(event) => handleQuestionDragEnd(event, formikProps)}
      >
        <SortableContext items={sortedQuestions.map((x) => x.gid)} strategy={verticalListSortingStrategy}>
          {rendered_questions}
        </SortableContext>
      </DndContext>
    );
  };

  const getSortedQuestions = (questions) => {
    return questions.sort((a, b) => a.sort_order - b.sort_order);
  };

  const handleQuestionDragEnd = (event, formikProps) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const items = getSortedQuestions(formikProps.values.questions).map((x) => x.gid);
      const oldIndex = items.indexOf(active.id);
      const newIndex = items.indexOf(over.id);
      const newArray = arrayMove(items, oldIndex, newIndex);
      let questions = formikProps.values.questions;
      newArray.forEach((new_array_gid, new_array_index) => {
        const questionIndex = questions.findIndex((x) => x.gid == new_array_gid);
        questions[questionIndex].sort_order = new_array_index;
      });
      formikProps.values.questions = questions;
      formikProps.setValues(formikProps.values);
    }
  };

  const renderQuestion = (question, index, formikProps) => {
    if (question.archived) {
      return "";
    }
    return (
      <FormsFormPageQuestionForm
        question={question}
        form={form}
        metaData={metaData}
        ticketTypes={ticketTypes}
        formikProps={formikProps}
        expandRetractSettings={expandRetractSettings}
        index={index}
        id={question.id}
        key={`questions-form-${question.id}`}
        question_key={`questions[${index}]`}
        openMetaDataModal={openMetaDataModal}
      />
    );
  };

  const createQuestion = (formikProps) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    axios({
      url: formConfig.createQuestionUrl,
      method: "POST"
    })
      .then((response) => {
        if (response.data.error === null) {
          const newQuestion = response.data.question;
          const questions = formikProps.values.questions;
          newQuestion.sort_order = questions.length;
          formikProps.setFieldValue("questions", questions.concat([newQuestion]));
        } else {
          callbackFailure ? callbackFailure(response) : () => {};
          alertError(`Unable to create form question: ${response.data.error}`);
        }
      })
      .catch((error) => {
        alertHttpError(error);
      });
  };

  const renderAddQuestionButton = (formikProps, showExandOptions) => {
    const { isSubmitting } = formikProps;
    return (
      <div className="sg-mgmt-form-actions pb-4">
        {renderButton("Add Field", () => createQuestion(formikProps), { color: "secondary", disabled: isSubmitting })}

        {showExandOptions ? (
          <div className="float-right mt-1">
            <button
              type="button"
              className="sg-mgmt-forms-link-button mr-1"
              onClick={() =>
                setExpandRetractSettings({
                  value: "retract",
                  updatedAt: new Date()
                })
              }
            >
              MINIMIZE ALL
            </button>
            |
            <button
              type="button"
              className="sg-mgmt-forms-link-button ml-1"
              onClick={() =>
                setExpandRetractSettings({
                  value: "expand",
                  updatedAt: new Date()
                })
              }
            >
              EXPAND ALL
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  };

  const formOnChange = (formikProps) => {
    if (formikProps.dirty) {
      setDirty(true);
    }
  };

  const attemptSavePage = async (values, setSubmitting) => {
    setSubmitting(true);
    const result = await isFormEditedSinceEditingTime();
    console.log({
      result
    });
    if (!result.value) {
      savePage(values, setSubmitting);
    } else {
      setSubmitting(false);
      confirm({
        description: `This form has been saved by ${result.user ? `${result.user.name_first} ${result.user.name_last}` : "another user"} since you started editing. Saving will discard their changes. Are you sure you want to save?`
      }).then(() => {
        setSubmitting(true);
        savePage(values, setSubmitting);
      });
    }
  };

  const savePage = (values, setSubmitting) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: formConfig.formUrl,
      method: formConfig.method,
      data: values
    })
      .then((response) => {
        if (response.data.error === null) {
          callbackSuccess ? callbackSuccess(response) : () => {};
          alertSuccess("Page saved");
          setFormPage(response.data.page);
          resetEditingTime(new Date(response.data.updated_at));
          setSubmitting(false);
          setDirty(false);
        } else {
          callbackFailure ? callbackFailure(response) : () => {};
          alertError(`Unable to save form page: ${response.data.error}`);
          setSubmitting(false);
        }
      })
      .catch((error) => {
        alertHttpError(error);
        setSubmitting(false);
      });
  };

  const renderFormik = () => (
    <Formik
      enableReinitialize
      initialValues={{
        page: pageInitialValues(),
        questions: questionsInitialValues()
      }}
      formValidation={formValidation}
      onSubmit={(values, { setSubmitting }) => {
        attemptSavePage(values, setSubmitting);
      }}
    >
      {(formikProps) => (
        <Form className="sg-mgmt-form" id={formConfig.formId}>
          <FormikObserver formikProps={formikProps} onChange={formOnChange} />
          {renderForm(formikProps)}
          {renderAddQuestionButton(formikProps, true)}
          {renderQuestions(formikProps)}
          {renderAddQuestionButton(formikProps, false)}
          {renderButtons(formikProps)}
        </Form>
      )}
    </Formik>
  );

  return <div className="max-w-screen-lg">{renderFormik()}</div>;
};

FormsFormsFormPageForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {}
};

export default FormsFormsFormPageForm;
