import { useQuery, useQueryClient } from "@tanstack/react-query";
import urljoin from "url-join";

import { request } from "@shared/axiosClient";

const fetchCustomReports = async (apiRoot) => {
  return request({
    method: "get",
    url: urljoin(apiRoot, "/reports")
  });
};

export const useCustomReports = (apiRoot, eventId) => {
  return useQuery({
    queryKey: ["customReports", eventId],
    queryFn: () => fetchCustomReports(apiRoot),
    cacheTime: 30 * (1000 * 60)
  });
};

export const useRefreshCustomReports = (eventId) => {
  const queryClient = useQueryClient();
  return () => {
    queryClient.invalidateQueries({ queryKey: ["customReports", eventId] });
  };
};
