import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetFilterByRole = props => {
  const { control } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Filter by Role (optional)</FormLabel>
          <Controller
            name="widget_config[filters][role]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={""}>(none)</MenuItem>
                <MenuItem value={"attendee"}>Attendee</MenuItem>
                <MenuItem value={"exhibitor"}>Exhibitor</MenuItem>
                <MenuItem value={"speaker"}>Speaker</MenuItem>
                <MenuItem value={"staff"}>Staff</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

WidgetFilterByRole.propTypes = {
  control: PropTypes.object.isRequired
};

export default WidgetFilterByRole;
