import React, { useContext } from "react";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import Bubble from "@shared/Bubble";
import StatTile from "@shared/StatTile";

import EmailWidgetSettings from "./EmailWidgetSettings";

const EmailWidget = () => {
  const { editMode, widget, values } = useContext(WidgetContext);
  const tableCls = "pb-4 pr-4 text-black";
  const tableHeaderStyle = { width: "120px", fontWeight: "400" };

  const percentage = (val, max) => {
    if (max === 0) {
      return "0%";
    }

    return `${Math.round((val / max) * 100)}%`;
  };

  const renderRateRow = () => {
    return (
      <table>
        <thead>
          <tr className="text-xs font-normal uppercase tracking-widest">
            <th className={tableCls} style={tableHeaderStyle}>
              Open Rate
            </th>
            <th className={tableCls} style={tableHeaderStyle}>
              Click Rate
            </th>
            <th className={tableCls} style={tableHeaderStyle}>
              Bounce Rate
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={tableCls}>
              <StatTile color={"#1B5EC1"} value={percentage(values.opened, values.delivered)} />
            </td>
            <td className={tableCls}>
              <StatTile color={"#2196F3"} value={percentage(values.clicked, values.delivered)} />
            </td>
            <td className={tableCls}>
              <StatTile color={"#F89728"} value={percentage(values.bounced, values.delivered)} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderValuesRow = () => {
    return (
      <table>
        <thead>
          <tr className="text-xs font-normal uppercase tracking-widest">
            <th className={tableCls} style={tableHeaderStyle}>
              Opened
            </th>
            <th className={tableCls} style={tableHeaderStyle}>
              Clicked A Link
            </th>
            <th className={tableCls} style={tableHeaderStyle}>
              Bounced
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td className={tableCls}>
              <StatTile color={"#1B5EC1"} value={values.opened} />
            </td>
            <td className={tableCls}>
              <StatTile color={"#2196F3"} value={values.clicked} />
            </td>
            <td className={tableCls}>
              <StatTile color={"#F89728"} value={values.bounced} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  };

  const renderContent = () => {
    if (
      !widget.widget_config.email_id &&
      !widget.widget_config.email_template_id &&
      !widget.widget_config.email_template_tag_id
    ) {
      return <div className="pt-4">You must select a valid email, template, or tag to display email stats.</div>;
    }

    if (values.error) {
      return <div className="pt-4">Error: {values.error}</div>;
    }
    return (
      <div className="flex">
        <div className="w-[240px] pl-4 pt-4">
          <Bubble colorBack={"#1A206C"} label={"Recipients"} size={135} value={values.delivered} />
        </div>
        <div>
          <div>{renderRateRow()}</div>
          <div>{renderValuesRow()}</div>
        </div>
      </div>
    );
  };

  const renderNotice = () => {
    if (!values || values.error) return <></>;

    return (
      <div className="py-2 text-sm text-sg-comms-saved">
        {
          "*Recipients are email main recipients that are reported back by their email provider, who may provide incorrect information, especially in the case of bounces."
        }
      </div>
    );
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Email Stats Settings" settingsComponent={EmailWidgetSettings} />;
  };

  return (
    <WidgetContentFrame fluidHeight>
      <WidgetHeader />
      <div className="pt-4">{renderContent()}</div>
      {renderNotice()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default EmailWidget;
