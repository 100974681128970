import React, { useState } from "react";
import PropTypes from "prop-types";
import MainLogoModal from "./MainLogoModal";
import UserMenu from "@user/UserMenu";
const MainBanner = props => {
  const { config, rootUrl, title, user } = props;
  const [modalVisible, setModalVisible] = useState(false);

  const resetModal = () => {
    setModalVisible(false);
  };

  const openModal = () => {
    if (user.role !== "basic") {
      setModalVisible(true);
    }
  };

  const renderLogo = () => {
    if (config.logo_data) {
      return (
        <img
          src={config.logo_url}
          class="p-1"
          onClick={openModal}
          alt="logo"
        />
      );
    }
    return (
      <div
        onClick={openModal}
        className="flex flex-col justify-center colu align-middle items-center te cursor-pointer w-[178px] h-[133px] bg-white border border-stone-300 text-center"
      >
        <img src="/images/icons/upload-file-filled.svg" alt="Upload Tile" className="inline-block"/>
        <div className="text-black">
          Upload company logo
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-[154px] flex">
      <div className="shrink-0 justify-center items-center flex w-[214px] h-full bg-white">
        {renderLogo()}
      </div>
      <div className="flex items-center h-full w-full bg-gradient-to-l from-sky-50 to-white pl-6">
        <div>
          <div className="text-black text-[28px] font-normal font-['Roboto']">
            {config && config.name ? config.name+" ": ""}Event Management
          </div>
          <div className="flex items-center text-black text-base font-normal font-['Roboto']">
            <div className="inline-block mt-1">Powered by</div>
            <img src="/images/silentgrowl-logo-header.svg" className="pl-2 h-[30px] inline-block"/>
          </div>
        </div>
       <div className="sg-mgmt-event-header-controls absolute top-4 right-12">
          <div className="sg-mgmt-event-header-controls">
            <UserMenu user={user}/>
          </div>
        </div>
      </div>
      <MainLogoModal
        config={config}
        modalVisible={modalVisible}
        resetModal={resetModal}
        rootUrl={rootUrl}
      />
    </div>
  );
};

MainBanner.defaultProps = {
  title: ""
};

MainBanner.propTypes = {
  config: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  title: PropTypes.string,
  user: PropTypes.object.isRequired
};

export default MainBanner;
