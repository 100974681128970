import React, { useContext, useRef, useState } from "react";
import PropTypes from "prop-types";

import { CloudUpload as CloudUploadIcon } from "@mui/icons-material";
import { Button, FormControl, FormLabel, TextField, Select, MenuItem } from "@mui/material";
import axios from "axios";
import Cropper from "react-cropper";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

import "cropperjs/dist/cropper.css";

const ImageWidgetSettings = props => {
  const { closeModal } = props;
  const [cropImage, setCropImage] = useState(null);
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const cropperRef = useRef(null);
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch
  } = useForm({
    defaultValues: {
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      image: widget?.image || null,
      widget_config: {
        image_fit: widget?.widget_config?.image_fit || "contain"
      }
    }
  });
  const watchImage = watch("image");
  const watchSize = watch("widget_size");
  const isEdit = !!widget?.id;

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const canSubmit = () => {
    if (isEdit) {
      return true;
    }

    if (watchImage) {
      return true;
    }

    if (isSubmitting) {
      return false;
    }

    return false;
  };

  const setAsCropImage = evt => {
    let files = null;

    if (evt.dataTransfer) {
      files = evt.dataTransfer.files;
    } else if (evt.target) {
      files = evt.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setCropImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const submitFn = submitData => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const formData = new FormData();
    if (submitData.image instanceof File) {
      formData.append("widget[image]", submitData.image);
    }

    const croppedImage = cropperRef.current?.cropper.getCroppedCanvas().toDataURL();
    if (croppedImage) {
      formData.append("widget[image_data_cropped]", croppedImage);
    }

    formData.append("widget[widget_config][image_fit]", submitData.widget_config.image_fit);
    formData.append("widget[widget_size]", submitData.widget_size);
    formData.append("widget[widget_type]", "image");
    formData.append("widget_board_id", widgetBoard.id);
    formData.append("widget[widget_position]", submitData.widget_position);

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: formData
    })
      .then(result => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const initialAspectRatio = () => {
    return watchSize === 1 ? 16 / 9 : 32 / 9;
  };

  const renderCropper = () => {
    if (watchImage) {
      return (
        <div className="mb-4 mt-4">
          <Cropper
            style={{ height: 300, width: "100%" }}
            initialAspectRatio={initialAspectRatio()}
            src={cropImage}
            ref={cropperRef}
            viewMode={1}
            guides={true}
            minCropBoxHeight={10}
            minCropBoxWidth={10}
            // background={false}
            // responsive={true}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
          />
        </div>
      );
    }

    return <></>;
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <FormControl>
            <FormLabel>Image File</FormLabel>
            <Controller
              name="image"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <>
                  <div>
                    <Button component="label" variant="contained" startIcon={<CloudUploadIcon />}>
                      {value ? "Change image" : "Upload image"}
                      <TextField
                        {...field}
                        inputProps={{
                          accept: "image/jpeg, image/png"
                        }}
                        onChange={event => {
                          setAsCropImage(event);
                          onChange(event.target.files[0]);
                        }}
                        sx={{
                          display: "none"
                        }}
                        error={!!errors.image}
                        type="file"
                      />
                    </Button>
                  </div>
                  <div>
                    <span>{value?.name}</span>
                  </div>
                </>
              )}
            />
          </FormControl>
        </div>
        {renderCropper()}
        <div className="mb-4 mt-4">
          <div className="mb-4 w-64">
            <FormControl fullWidth>
              <FormLabel>Image Fit</FormLabel>
              <Controller
                name="widget_config[image_fit]"
                control={control}
                rules={{ required: "Image fit is required" }}
                render={({ field: { value, onChange, ...field } }) => (
                  <Select {...field} fullWidth onChange={onChange} value={value} variant="outlined" size="small">
                    <MenuItem value={"contain"}>Contain</MenuItem>
                    <MenuItem value={"cover"}>Cover</MenuItem>
                    <MenuItem value={"fill"}>Fill (Stretch)</MenuItem>
                    <MenuItem value={"none"}>Original Size</MenuItem>
                    <MenuItem value={"scale-down"}>Scale Down</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
        </div>
        <WidgetSizeOptions control={control} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={!canSubmit()} isEdit={isEdit} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Image widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

ImageWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default ImageWidgetSettings;
