import React from "react";
import ReactDOM from "react-dom/client";

export const mountReactComponents = (mountComponents) => {
  const mountNodes = document.querySelectorAll("[data-react-class]");
  for (const mountNode of mountNodes) {
    if (mountNode) {
      const componentName = mountNode.dataset.reactClass;
      const component = mountComponents[componentName];
      if (component) {
        const props = JSON.parse(mountNode.dataset.reactProps);
        document.addEventListener('DOMContentLoaded', () => {
          ReactDOM.createRoot(mountNode).render(
            React.createElement(component, props)
          );
        });
      } else {
        console.warn(`Could not find component ${componentName} in mountable components`);
      }
    }
  }
}

export const mountReactComponentsGlob = (components, filenames) => {
  document.addEventListener("DOMContentLoaded", () => {
    const mountPoints = document.querySelectorAll("[data-react-class]");
    mountPoints.forEach((mountPoint) => {
      const { dataset } = mountPoint;
      const componentName = dataset.reactClass;
      if (componentName) {
        const componentIndex = filenames.findIndex((x) => x.replace("./","").replace(".jsx","").replace(".js", "") === componentName);
        const Component = components[componentIndex];
        if (Component) {
          const props = JSON.parse(dataset.reactProps);
          const root = ReactDOM.createRoot(mountPoint);
          root.render(<Component {...props} />);
        } else {
          console.warn(
            "WARNING: No component found for: ",
            dataset.reactClass,
            components
          );
        }
      }
    });
  });
}
