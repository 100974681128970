import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";


import { Stack, Chip } from "@mui/material";
import axios from "axios";
import clsx from "clsx";
import { Formik, Field, Form } from "formik";
import isArray from "lodash/isArray";
import isPlainObject from "lodash/isPlainObject";
import Dropzone from "react-dropzone";
import Modal from "react-modal";
import urljoin from "url-join";

import EventUserContext from "@event/EventUserContext";
import { alertError, alertHttpError, alertSuccess } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import {
  renderCheckField,
  renderIntegerField,
  renderRichTextAreaField,
  renderTextField,
  renderDateField,
  renderCancelButton,
  renderSubmitButton,
  renderButton
} from "@shared/FormUtils";
import Loading from "@shared/Loading";
import { isDeveloper } from "@shared/UserUtils";


import PeopleBlankEventParticipant from "./PeopleBlankEventParticipant";
import PeopleIndividualModal from "../PeopleIndividualModal";
import PeopleModal from "../PeopleModal";

const PeopleEventParticipantsModal = (props) => {
  const {
    addIndividual,
    apiRoot,
    enabledMetadataFields,
    individuals,
    participantId,
    modalVisible,
    metadataFields,
    regFields,
    resetModal,
    rootUrl,
    tags,
    types,
    updateFunc
  } = props;
  const { user } = useContext(EventUserContext);
  const [fetched, setFetched] = useState(false);
  const [showOverride, setShowOverride] = useState(false);
  const [participant, setParticipant] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [addPersonModalVisible, setAddPersonModalVisible] = useState(false);
  const [dataBlockCount, setDataBlockCount] = useState(0);
  const [dataEditor, setDataEditor] = useState(false);
  const [dataEditorRaw, setDataEditorRaw] = useState(false);
  const [personModalVisible, setPersonModalVisible] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [photoDropped, setPhotoDropped] = useState(false);
  const [previewPhotoUrl, setPreviewPhotoUrl] = useState(null);
  const dropzoneAccept = "image/png, image/jpg, image/jpeg, image/pjpeg";

  useEffect(() => {
    const fetchParticipant = async () => {
      if (!participantId) {
        setFetched(true);
        return;
      }
      try {
        const result = await axios(urljoin(apiRoot, "/participants", `/${participantId}`));
        setParticipant(result.data.participant);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchParticipant();
  }, [apiRoot, participantId]);

  useEffect(() => {
    if (participant) {
      setSelectedTags(participant.tags);
      setPreviewPhotoUrl(participant.photo_url);
    }
  }, [participant]);

  Modal.setAppElement("#root");

  const onDrop = (acceptedFiles) => {
    const reader = new FileReader();
    if (typeof window.FileReader !== "undefined") {
      reader.onload = (e) => {
        setPhoto(acceptedFiles[0]);
        setPhotoDropped(true);
        setPreviewPhotoUrl(e.target.result);
      };
      reader.readAsDataURL(acceptedFiles[0]);
    } else {
      setPhoto(acceptedFiles[0]);
      setPhotoDropped(true);
      setPreviewPhotoUrl(null);
    }
  };

  const dropzoneFilename = () => {
    if (photoDropped) {
      return (
        <>
          File attached:
          <br />
          {photo.name}
        </>
      );
    }
    return <>Upload Photo</>;
  };

  const renderPhoto = () => {
    if (previewPhotoUrl) {
      return (
        <img
          className="sg-mgmt-form-speaker-photo-container cursor-pointer"
          src={previewPhotoUrl}
          alt="Event Participant"
        />
      );
    }
    return (
      <div className="sg-mgmt-form-input-dropzone-tile-prompt">
        Drag and Drop
        <br />
        or Click to Choose File
      </div>
    );
  };

  const renderPhotoField = () => {
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Photo</label>
        <Dropzone accept={dropzoneAccept} onDrop={onDrop} name="event_participant[override_photo]">
          {({ getRootProps, getInputProps, isDragActive }) => {
            return (
              <>
                <div
                  {...getRootProps()}
                  className={clsx("dropzone", "sg-mgmt-form-input-dropzone-tile", {
                    "dropzone--isActive": isDragActive
                  })}
                >
                  <input {...getInputProps()} />
                  {renderPhoto()}
                </div>
                <div className="sg-mgmt-participant-photo-filename cursor-pointer">{dropzoneFilename()}</div>
              </>
            );
          }}
        </Dropzone>
      </div>
    );
  };

  const closeAddPersonModal = () => {
    setAddPersonModalVisible(false);
  };

  const openAddPersonModal = () => {
    setAddPersonModalVisible(true);
  };

  const closePersonModal = () => {
    setPersonModalVisible(false);
  };

  const openPersonModal = () => {
    setPersonModalVisible(true);
  };

  const updateIndividual = (individual) => {
    const newParticipant = participant;
    participant.individual = individual;
    setParticipant(newParticipant);
  };

  const isEdit = () => {
    return participant && participant.id;
  };

  const config = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-participant-edit",
        formUrl: urljoin(apiRoot, "/participants", `/${participant.id}`),
        method: "PATCH",
        title: "Edit Participant"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-participant-add",
      formUrl: urljoin(apiRoot, "/participants"),
      method: "POST",
      title: "Add Participant"
    };
  })();

  const toggleOverride = () => {
    setShowOverride(!showOverride);
  };

  const addTag = (gid) => {
    // ignore duplicate tag
    if (selectedTags.map((t) => t.gid).includes(gid)) {
      return;
    }

    // ignore missing tag
    if (!gid) {
      return;
    }

    const tag = tags.find((t) => t.gid === gid);
    setSelectedTags([tag].concat(selectedTags));
  };

  const removeTag = (gid) => {
    setSelectedTags((prevTags) => prevTags.filter((t) => t.gid !== gid));
  };

  const handleAddTagForm = (value) => {
    addTag(value);
  };

  const renderCustomTextField = (field, metaType = "custom") => {
    return renderTextField(field.label, `participant[${metaType}][${field.slug}]`, []);
  };

  const renderCustomDateField = (field, metaType = "custom") => {
    return renderDateField(field.label, `participant[${metaType}][${field.slug}]`, []);
  };

  const renderCustomDropdownField = (field, metaType = "custom", formatClasses = {}) => {
    const options = field.options.map((opt) => ({
      label: opt.label,
      value: opt.label
    }));

    return (
      <SelectField
        label={field.label}
        name={`participant[${metaType}][${field.slug}]`}
        options={options}
        formatClasses={formatClasses}
        includeBlank={true}
      />
    );
  };

  const renderCustomCheckField = (field, metaType = "custom", formatClasses = {}) => {
    const options = field.options.map((opt) => ({
      id: opt.id,
      label: opt.label,
      slug: opt.slug
    }));
    return (
      <div key={field.gid} className={clsx("sg-mgmt-form-input-container", formatClasses)}>
        <label>{field.label}</label>
        {options.map((opt) =>
          renderCheckField(opt.label, `participant[${metaType}][${field.slug}][options][${opt.slug}]`)
        )}
      </div>
    );
  };

  const renderCustomField = (field) => {
    switch (field.field_format) {
      case "text":
        return renderCustomTextField(field);
      case "dropdown":
        return renderCustomDropdownField(field);
      case "checkboxes":
        return renderCustomCheckField(field);
      default:
        return <div key={field.gid}>not a text field</div>;
    }
  };

  const renderCustomFields = () => {
    return regFields.map((f) => (
      <div key={f.id} className="sg-mgmt-form-row">
        {renderCustomField(f)}
      </div>
    ));
  };

  /* eslint-disable dot-notation */
  const initialCustomAnswers = () => {
    if (participant && participant.registration_answers) {
      const custom = {};
      const ids = Object.keys(participant.registration_answers);
      ids.forEach((id) => {
        if (isPlainObject(participant.registration_answers[id])) {
          const val = { options: {} };
          const keys = Object.keys(participant.registration_answers[id]);
          keys.forEach((key) => {
            val["options"][key] = true;
          });
          custom[id] = val;
        } else if (isArray(participant.registration_answers[id])) {
          const val = { options: {} };
          participant.registration_answers[id].forEach((opt) => {
            val["options"][opt] = true;
          });
          custom[id] = val;
        } else {
          custom[id] = participant.registration_answers[id];
        }
      });

      return custom;
    }
    return {};
  };
  /* eslint-enable dot-notation */

  const renderMetadataField = (field) => {
    switch (field.field_format) {
      case "text":
        return renderCustomTextField(field, "metadata");
      case "date":
        return renderCustomDateField(field, "metadata");
      case "dropdown":
        return renderCustomDropdownField(field, "metadata");
      case "checkboxes":
        return renderCustomCheckField(field, "metadata");
      default:
        return <div key={field.gid}>not a text field</div>;
    }
  };

  const renderMetadataFields = () => {
    if(Object.keys(enabledMetadataFields).length > 0) {
      return Object.keys(enabledMetadataFields).filter((key) => enabledMetadataFields[key]['selected']).map((key) => {
        let f = metadataFields.find((f) => f.slug === key);
        return (
          <div key={f.id} className="sg-mgmt-form-row">
            {renderMetadataField(f)}
          </div>
        )
      });
    }
    return metadataFields.map((f) => (
      <div key={f.id} className="sg-mgmt-form-row">
        {renderMetadataField(f)}
      </div>
    ));
  };

  const initialMetadataValues = () => {
    const metadataValues = {};
    participant.metadata.forEach((md) => {
      if (md.field_format == "checkboxes") {
        const val = { options: {} };
        md.value.split(",").forEach((opt) => {
          val["options"][opt] = true;
        });
        metadataValues[md.field_slug] = val;
      } else {
        metadataValues[md.field_slug] = md.value;
      }
    });
    return metadataValues;
  };

  const renderSelectedTags = () => {
    return (
      <div className="mt-2 flex">
        {selectedTags.map((tag) => (
          <Chip key={tag.id} className="mr-2" color="primary" label={tag.name} onDelete={() => removeTag(tag.gid)} />
        ))}
      </div>
    );
  };

  const renderTagAddButton = (values) => {
    const tag_add = values.tag_add;
    const selectedTag = tag_add ? tag_add : false;
    const disabled = !selectedTag || selectedTag === "Select option";

    return renderButton(
      "Add",
      () => {
        handleAddTagForm(tag_add);
      },
      { disabled: disabled, color: "secondary" }
    );
  };

  const renderTagSelect = (values) => {
    const filteredTags = tags.filter((t) => !selectedTags.map((tag) => tag.gid).includes(t.gid));
    const options = filteredTags.map((opt) => ({
      label: opt.name,
      value: opt.gid
    }));
    return (
      <Stack spacing={2} direction="row">
        <SelectField disableLabel={true} name={"tag_add"} options={options} />
        {renderTagAddButton(values)}
      </Stack>
    );
  };

  const renderTags = (values) => {
    return (
      <>
        {renderTagSelect(values)}
        {renderSelectedTags()}
      </>
    );
  };

  const renderOverrideFields = () => {
    if (showOverride) {
      return (
        <div className="sg-mgmt-form-input-container">
          <div className="sg-mgmt-link cursor-pointer" onClick={toggleOverride}>
            Hide override fields
          </div>
          <div className="sg-mgmt-form-row">
            <Stack spacing={2} direction="row">
              {renderTextField("First Name", "participant[override_name_first]")}
              {renderTextField("Last Name", "participant[override_name_last]")}
            </Stack>
          </div>
          <div className="sg-mgmt-form-row">{renderTextField("Email", "participant[override_email]")}</div>
          <div className="sg-mgmt-form-row">{renderTextField("Company", "participant[override_company]")}</div>
          <div className="sg-mgmt-form-row">{renderTextField("Job Title", "participant[override_job_title]")}</div>
          <div className="sg-mgmt-form-row">
            {renderTextField("Address (line 1)", "participant[override_work_address_street_1]")}
          </div>
          <div className="sg-mgmt-form-row">
            {renderTextField("Address (line 2)", "participant[override_work_address_street_2]")}
          </div>
          <div className="sg-mgmt-form-row">
            <Stack spacing={2} direction="row">
              {renderTextField("City", "participant[override_work_address_city")}
              {renderTextField("State/Province", "participant[override_work_address_state]", [
                "sg-mgmt-form-input-container-state"
              ])}
              {renderTextField("ZIP/Postal Code", "participant[override_work_address_postcode]", [
                "sg-mgmt-form-input-container-postcode"
              ])}
            </Stack>
          </div>
          <div className="sg-mgmt-form-row">
            {renderTextField("Country", "participant[override_work_address_country]")}
          </div>
          <div className="sg-mgmt-form-row">{renderTextField("Phone", "participant[override_work_phone]")}</div>
          <div className="sg-mgmt-form-row">{renderRichTextAreaField("Bio", "participant[override_bio]")}</div>
          <div className="sg-mgmt-form-speaker-container-photo">{renderPhotoField()}</div>
        </div>
      );
    }
    return (
      <div className="sg-mgmt-form-input-container">
        <div className="sg-mgmt-link cursor-pointer" onClick={toggleOverride}>
          Show override fields
        </div>
      </div>
    );
  };

  const renderIndividualField = (label, value) => {
    return (
      <div className="sg-mgmt-modal-view-field">
        <span className="sg-mgmt-modal-view-field-label">{label}</span>
        :&nbsp;
        {value}
      </div>
    );
  };

  const updateIndividualSelected = (individual, setFieldValue) => {
    addIndividual(individual);
    setFieldValue("individual_gid", individual.gid);
  };

  const renderIndividual = () => {
    if (isEdit()) {
      return (
        <>
          {renderIndividualField("GID", participant.individual.gid)}
          {renderIndividualField("First Name", participant.individual.name_first)}
          {renderIndividualField("Last Name", participant.individual.name_last)}
          {renderIndividualField("Company", participant.individual.company)}
          {renderIndividualField("Email", participant.individual.email)}
          <div className="sg-mgmt-link my-1 cursor-pointer" onClick={openPersonModal}>
            Edit Person
          </div>
        </>
      );
    }
    const options = individuals.map((ind) => ({
      label: `${ind.name_first} ${ind.name_last} (${ind.email})`,
      value: ind.gid
    }));
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Select Individual</label>
        <SelectField options={options} className="sg-mgmt-form-input" name="individual_gid" includeBlank />
        <div className="sg-mgmt-link my-1 cursor-pointer" onClick={openAddPersonModal}>
          Add New Person
        </div>
      </div>
    );
  };

  const renderRoleField = () => {
    const roleOptions = [
      { label: "Attendee", value: "attendee" },
      { label: "Speaker", value: "speaker" },
      { label: "Staff", value: "staff" },
      { label: "Exhibitor", value: "exhibitor" }
    ];
    return <SelectField label={"Role"} name="participant[role]" options={roleOptions} required />;
  };

  const renderStatusField = () => {
    const statusOptions = [
      { label: "Preregistered", value: "preregistered" },
      { label: "Registered", value: "registered" },
      { label: "Declined", value: "declined" },
      { label: "Disallowed", value: "disallowed" },
      { label: "Cancelled", value: "cancelled" }
    ];
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Status</label>
        <SelectField
          options={statusOptions}
          className="sg-mgmt-form-input"
          name="participant[status]"
          autoComplete="off"
          includeBlank
        />
      </div>
    );
  };

  const renderTypeField = () => {
    const typeOptions = types.map((t) => ({
      label: t.name,
      value: t.id
    }));
    return (
      <div className="sg-mgmt-form-input-container">
        <label>Participant Type</label>
        <SelectField
          options={typeOptions}
          className="sg-mgmt-form-input"
          name="participant[event_participant_type_id]"
          autoComplete="off"
          includeBlank
        />
      </div>
    );
  };

  const renderDataBlockField = (idx, type, value = "") => (
    <div className="sg-mgmt-form-input-container">
      <Field
        className="sg-mgmt-form-input"
        type="text"
        as="input"
        name={`data_block[${idx}][${type}]`}
        defaultValue={value}
        autoComplete="off"
      />
    </div>
  );

  const renderDataBlockRawField = () => (
    <div className="sg-mgmt-form-input-container">
      <Field
        className="sg-mgmt-form-input"
        type="text"
        as="textarea"
        name="data_block_raw"
        defaultValue={participant ? JSON.stringify(participant.data, null, 2) : JSON.stringify({})}
        autoComplete="off"
      />
    </div>
  );

  const renderDataBlockRawToggle = () => {
    if (dataEditorRaw) {
      return (
        <span
          className="sg-mgmt-link"
          onClick={() => {
            setDataEditorRaw(false);
          }}
        >
          Click to disable raw editor view
        </span>
      );
    }
    return (
      <span
        className="sg-mgmt-link"
        onClick={() => {
          setDataEditorRaw(true);
        }}
      >
        Click to enable raw editor view
      </span>
    );
  };

  const renderDataBlockOpenView = () => {
    return (
      <>
        <h2>Data Block</h2>
        <div className="sg-mgmt-form-row">
          <div>
            <div className="mt-1">
              <span
                className="sg-mgmt-link"
                onClick={() => {
                  setDataEditor(false);
                }}
              >
                Click to disable data block editor
              </span>
            </div>
            <div className="mt-1">{renderDataBlockRawToggle()}</div>
          </div>
        </div>
      </>
    );
  };

  const renderDataBlockTableView = () => {
    const blankIndexBegin = participant ? Object.keys(participant.data).length : 0;
    return (
      <div>
        {renderDataBlockOpenView()}
        <div className="sg-mgmt-form-row">
          <div className="flex w-full">
            <div className="w-1/2">
              <strong>Key</strong>
            </div>
            <div className="w-1/2">
              <strong>Value</strong>
            </div>
          </div>
        </div>
        {participant &&
          Object.keys(participant.data).map((item, idx) => (
            <div key={item} className="sg-mgmt-form-row">
              <div className="flex w-full">
                <div className="w-1/2 pr-4">{renderDataBlockField(idx, "key", item)}</div>
                <div className="w-1/2 pr-4">{renderDataBlockField(idx, "value", participant.data[item])}</div>
              </div>
            </div>
          ))}
        {[...Array(dataBlockCount)].map((_, idx) => (
          <div key={idx} className="sg-mgmt-form-row">
            <div className="flex w-full">
              <div className="w-1/2 pr-4">{renderDataBlockField(idx + blankIndexBegin, "key", "")}</div>
              <div className="w-1/2 pr-4">{renderDataBlockField(idx + blankIndexBegin, "value", "")}</div>
            </div>
          </div>
        ))}
        <div className="sg-mgmt-form-row">
          <span
            className="sg-mgmt-link"
            onClick={() => {
              setDataBlockCount((prevCount) => prevCount + 1);
            }}
          >
            Add data block field
          </span>
        </div>
      </div>
    );
  };

  const renderDataBlockRawView = () => {
    return (
      <div>
        {renderDataBlockOpenView()}
        <div className="sg-mgmt-form-row">{renderDataBlockRawField()}</div>
      </div>
    );
  };

  const renderDataBlockClosedView = () => {
    return (
      <div>
        <h2>Data Block</h2>
        <div className="sg-mgmt-form-row">
          <span
            className="sg-mgmt-link"
            onClick={() => {
              setDataEditor(true);
            }}
          >
            Click to enable data block editor
          </span>
        </div>
      </div>
    );
  };

  const renderDataBlockEditor = () => {
    if (!isDeveloper(user)) {
      return <></>;
    }

    if (!dataEditor) {
      return renderDataBlockClosedView();
    }

    if (!dataEditorRaw) {
      return renderDataBlockTableView();
    }

    return renderDataBlockRawView();
  };

  const formInitialValues = () => {
    if (participant) {
      return {
        individual_gid: "",
        role: participant.role || "attendee",
        status: participant.status || "preregistered",
        override_name_first: participant.override_name_first || "",
        override_name_last: participant.override_name_last || "",
        override_company: participant.override_company || "",
        override_email: participant.override_email || "",
        override_job_title: participant.override_job_title || "",
        override_work_address_street_1: participant.override_work_address_street_1 || "",
        override_work_address_street_2: participant.override_work_address_street_2 || "",
        override_work_address_city: participant.override_work_address_city || "",
        override_work_address_state: participant.override_work_address_state || "",
        override_work_address_postcode: participant.override_work_address_postcode || "",
        override_work_address_country: participant.override_work_address_country || "",
        override_work_phone: participant.override_work_phone || "",
        override_bio: participant.override_bio || "",
        test_flag: participant.test_flag || false,
        attended: participant.attended || false,
        opt_out: participant.opt_out || false,
        sort_order: participant.sort_order || 0,
        event_participant_type_id: participant.event_participant_type_id || "",
        data: participant.data || {},
        custom: initialCustomAnswers(),
        metadata: initialMetadataValues()
      };
    }
    return PeopleBlankEventParticipant;
  };

  const dataBlockInitialValues = () => {
    const dataObj = {};
    if (participant) {
      Object.keys(participant.data).forEach((item, idx) => {
        dataObj[idx] = {
          key: item,
          value: participant.data[item]
        };
      });
    }
    return dataObj;
  };

  const renderButtons = (isSubmitting) => (
    <Stack direction="row" spacing={2}>
      {renderSubmitButton("Save", isSubmitting)}
      {renderCancelButton("Cancel", resetModal)}
    </Stack>
  );

  const renderPersonModal = () => {
    if (participant) {
      return (
        <PeopleIndividualModal
          fetched={fetched}
          individual={participant.individual}
          modalVisible={personModalVisible}
          resetModal={closePersonModal}
          rootUrl={rootUrl}
          updateIndividual={updateIndividual}
        />
      );
    }
    return <></>;
  };

  const renderOptOutCheckbox = () => {
    if (participant) {
      if (participant.opt_out_global) {
        return (
          <>
            <div className="sg-mgmt-form-row">
              <em>This person is opted-out of ALL direct emails.</em>
            </div>
            {renderCheckField("Opt Out of Direct Emails for This Event", "participant[opt_out]")}
          </>
        );
      }
      return renderCheckField("Opt Out of Direct Emails for this Event", "participant[opt_out]");
    }
    return <></>;
  };

  const renderAddEditParticipantModal = () => {
    if (fetched) {
      return (
        <div className="sg-mgmt-modal-frame">
          <div className="sg-mgmt-modal-title">{config.title}</div>
          <div className="sg-mgmt-modal-content">
            <Formik
              initialValues={{
                individual_gid: "",
                participant_gid: "",
                participant: formInitialValues(),
                data_block: dataBlockInitialValues()
              }}
              onSubmit={(values, { setSubmitting }) => {
                const form = document.getElementById(config.formId);
                const formData = new FormData(form);
                const token = document.querySelector("[name=csrf-token]").content;
                axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
                // why the fuck is override bio left out of formData?
                formData.set("participant[override_bio]", values.participant.override_bio);
                // Metadata checkboxes values if all checkboxes are empty.
                if(values && values.participant.metadata) {
                  const objectFieldsKeys = Object.keys(values.participant.metadata).filter(key=> typeof(values.participant.metadata[key]) === 'object');
                  objectFieldsKeys.forEach((key) => {
                      const value = values.participant.metadata[key];
                      if(value && value.options && Object.keys(value.options).filter(key => value.options[key]).length == 0) {
                        formData.set(`participant[metadata][${key}]`, "");
                      }
                  })
                }
                // Add participant GID on edits
                if (isEdit()) {
                  formData.set("individual_gid", participant.individual.gid);
                  formData.set("participant_gid", participant.gid);
                }

                if (photo) {
                  formData.set("participant[override_photo]", photo);
                }

                if (values.participant.test_flag === false ||
                  values.participant.test_flag === "" ||
                  values.participant.test_flag === null
                ) {
                  formData.set("participant[test_flag]", false);
                }

                if (values.participant.attended === false) {
                  formData.set("participant[attended]", false);
                }

                if (values.participant.opt_out === false) {
                  formData.set("participant[opt_out]", false);
                }

                // Put tags into array
                const tagGids = selectedTags.map((t) => t.gid);
                formData.set("participant[tags]", tagGids);

                // Construct data block from data_block values
                if (dataEditor) {
                  if (!dataEditorRaw) {
                    // table editor
                    const newData = {};
                    Object.keys(values.data_block).forEach((idx) => {
                      const { key } = values.data_block[idx];
                      const val = values.data_block[idx].value === undefined ? "" : values.data_block[idx].value;
                      if (key) {
                        newData[key] = val;
                      }
                      formData.delete(`data_block[${idx}][key]`);
                      formData.delete(`data_block[${idx}][value]`);
                    });
                    Object.keys(newData).forEach((key) => {
                      formData.set(`participant[data][${key}]`, newData[key]);
                    });
                  } else {
                    // raw editor
                    let formJson = {};
                    try {
                      formJson = JSON.parse(values.data_block_raw);
                    } catch {
                      alertError("JSON in data block editor is invalid");
                      setSubmitting(false);
                      return;
                    }
                    const newData = {};
                    Object.keys(formJson).forEach((key) => {
                      const val = formJson[key];
                      newData[key] = val;
                    });
                    Object.keys(newData).forEach((key) => {
                      formData.set(`participant[data][${key}]`, newData[key]);
                    });
                    formData.delete("data_block_raw");
                  }
                }

                // Remove tag_add field
                formData.delete("tag_add");

                axios({
                  url: config.formUrl,
                  method: config.method,
                  data: formData
                })
                  .then((response) => {
                    if (response.data.error === null) {
                      updateFunc(response.data.participant);
                      setDataEditor(false);
                      setDataEditorRaw(false);
                      resetModal();
                      alertSuccess(`Participant ${config.alert} successfully`);
                    } else {
                      alertError(response.data.error);
                      setSubmitting(false);
                    }
                  })
                  .catch((error) => {
                    alertError(error);
                    setSubmitting(false);
                  });
              }}
            >
              {({ values, isSubmitting, setFieldValue }) => (
                <Form className="sg-mgmt-form" id={config.formId}>
                  <div className="float-right -mt-2">{renderButtons(isSubmitting)}</div>
                  <div className="sg-mgmt-form-container">
                    <h2>Individual</h2>
                    {renderIndividual()}
                    <h2>Role</h2>
                    {renderRoleField()}
                    <h2>Participant Type</h2>
                    {renderTypeField(values)}
                    <h2>Status</h2>
                    {renderStatusField()}
                    <h2>Override Fields</h2>
                    {renderOverrideFields()}
                    <h2>Registration Questions</h2>
                    {renderCustomFields()}
                    <h2>Attended</h2>
                    <div className="sg-mgmt-form-row">
                      {renderCheckField("Attended Event", "participant[attended]")}
                    </div>
                    <h2>Metadata</h2>
                    {renderMetadataFields()}
                    <h2>Tags</h2>
                    {renderTags(values)}
                    <h2>Testing</h2>
                    <div className="sg-mgmt-form-row">
                      {renderCheckField("Test Flag (exclude from reports)", "participant[test_flag]")}
                    </div>
                    <h2>Sorting</h2>
                    <div className="sg-mgmt-form-row w-16">
                      {renderIntegerField("Sort Order", "participant[sort_order]")}
                    </div>
                    <h2>Email</h2>
                    {renderOptOutCheckbox()}
                    {renderDataBlockEditor()}
                  </div>
                  <div className="sg-mgmt-form-actions">{renderButtons(isSubmitting)}</div>
                  <PeopleModal
                    rootUrl={rootUrl}
                    closeModal={closeAddPersonModal}
                    modalVisible={addPersonModalVisible}
                    resetModal={closeAddPersonModal}
                    updateFunc={(individual) => updateIndividualSelected(individual, setFieldValue)}
                  />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      );
    }
    return (
      <div className="sg-mgmt-modal-frame">
        <div className="sg-mgmt-modal-title">Participant:</div>
        <div className="sg-mgmt-modal-content">
          <div className="sg-mgmt-modal-view">
            <Loading />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        className="sg-mgmt-modal"
        overlayClassName="sg-mgmt-modal-overlay"
        isOpen={modalVisible}
        onRequestClose={resetModal}
        contentLabel="Add Participant"
      >
        {renderAddEditParticipantModal()}
      </Modal>

      {renderPersonModal()}
    </>
  );
};

PeopleEventParticipantsModal.defaultProps = {
  participantId: null,
  enabledMetadataFields: {}
};

PeopleEventParticipantsModal.propTypes = {
  addIndividual: PropTypes.func.isRequired,
  apiRoot: PropTypes.string.isRequired,
  enabledMetadataFields: PropTypes.object,
  individuals: PropTypes.array.isRequired,
  participantId: PropTypes.number,
  metadataFields: PropTypes.array.isRequired,
  modalVisible: PropTypes.bool.isRequired,
  regFields: PropTypes.array.isRequired,
  resetModal: PropTypes.func.isRequired,
  rootUrl: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
  types: PropTypes.array.isRequired,
  updateFunc: PropTypes.func.isRequired
};

export default PeopleEventParticipantsModal;
