import React, { useState } from "react";

import Modal from "react-modal";

import CustomReportFieldSelect from "./CustomReportFieldSelect";
import CustomReportKeys from "./CustomReportKeys";


const CustomReportFieldsModal = (props) => {
  const { keys, selectedKeys, keySelected, updateKeys } = props;
  const [modalOpen, setModalOpen] = useState(false);
  Modal.setAppElement("body");
  console.log(`render ReportFieldsModal with ${selectedKeys}`);

  const toggleModal = () => {
    if (typeSelected()) {
      setModalOpen(!modalOpen);
    }
  };

  const typeSelected = () => {
    return keys && Object.keys(keys).length > 0;
  };

  const massKeySelection = (keys, selectAll) => {
    let newSelectedKeys = selectedKeys;
    if (selectAll) {
      keys.forEach((key) => {
        if (!selectedKeys.some((e) => e.value === key.value)) {
          newSelectedKeys.push(key);
        }
      });
    } else {
      keys.forEach((key) => {
        if (selectedKeys.some((e) => e.value === key.value)) {
          newSelectedKeys = newSelectedKeys.filter(function(e) {
            return e.value !== key.value;
          });
        }
      });
    }
    updateKeys(newSelectedKeys);
  };

  const renderModal = () => {
    return (
      <>
        <div className="report-columns-modal-container">
          <div className="report-columns-modal-key-select">
            <div className="report-columns-modal-header">
              <h3>Add Columns</h3>
            </div>
            <CustomReportFieldSelect
              selectedKeys={selectedKeys}
              options={keys}
              onChange={(key) => {
                keySelected(key);
              }}
              massKeySelection={massKeySelection}
            />
          </div>
          <div className="report-columns-modal-keys">
            <div className="report-columns-modal-header">
              <h3>Selected Columns</h3>
              <p>You can drag the fields to sort them in the order you want the columns to appear.</p>
            </div>
            <CustomReportKeys keys={selectedKeys} updateKeys={updateKeys} selectedKeys={selectedKeys} />
            <button className="sg-mgmt-btn" onClick={toggleModal}>
              Continue
            </button>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="report-tool-modifier-link report-tool-edit-link" onClick={toggleModal} disabled={typeSelected()}>
        Add Columns
      </div>
      <Modal
        className="white-background report-modal report-columns-modal disable-scrollbars"
        overlayClassName="report-modal-overlay"
        isOpen={modalOpen}
        onRequestClose={toggleModal}
        contentLabel=""
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default CustomReportFieldsModal;
