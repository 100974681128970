import React, { useContext, useState } from "react";
import axios from "axios";
import urljoin from "url-join";
import PropTypes from "prop-types";
import { alertError, alertHttpError } from "@shared/Alerts";
import Loading from "@shared/Loading";
import { renderCreateButton } from "@shared/FormUtils";
import GrowlModal from "@shared/GrowlModal";
import GrowlTableStatic from "@shared/GrowlTableStatic";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import HousingBlankRoomBlock from "./HousingBlankRoomBlock";
import HousingRoomBlockModal from "./HousingRoomBlockModal";

const HousingRoomBlocksIndex = props => {
  const {
    addBlock,
    blocks,
    fetched,
    goEdit,
    goRooms,
    setEditBlockId,
    updateBlocks
  } = props;
  const { apiRoot } = useContext(EventContext).values;
  const { user } = useContext(EventUserContext);
  const [modalAddVisible, setModalAddVisible] = useState(false);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const performDelete = id => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    axios({
      url: urljoin(apiRoot, `/housing/blocks/${id}`),
      method: "DELETE"
    })
      .then(response => {
        if (response.data.error === null) {
          window.location.reload();
        } else {
          alertError(response.data.error);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const deleteConfirmMsg = () => (
    <>
      <p>Are you sure you want to delete this room block?</p>
    </>
  );

  const renderDelete = (block) => {
    if (block.room_block_room_types.length === 0) { return <></>; }

    return (
      <>
        <GrowlModal
          content={deleteConfirmMsg()}
          title={`Delete Room Block`}
          trigger={
            <span className="text-ui-7 uppercase cursor-pointer border-r-ui-7 border-r pr-2 inline-block">Delete</span>
          }
          actions={[
            {
              label: "Cancel",
              close: true
            },
            {
              label: "Delete",
              close: true,
              func: () => {
                performDelete(block.id);
              }
            }
          ]}
        />
      </>
    );
  };

  const renderManageAction = (block) => {
    if (block.main) { return <></>; }

    return (
      <>
        <span
          className="text-ui-7 uppercase cursor-pointer border-r-ui-7 border-r px-2 inline-block"
          onClick={() => {
            setEditBlockId(block.id);
            goRooms();
          }}
        >
          Manage
        </span>
      </>
    );
  };

  const renderEditAction = (block) => (
    <>
      <span
        className="text-ui-7 uppercase cursor-pointer pl-2"
        onClick={() => {
          setEditBlockId(block.id);
          goEdit();
        }}
      >
        Edit Info
      </span>
    </>
  );

  const generateStaticRowData = (block) => {
    return {
      key: block.id,
      cells: [
        <>{block.name}</>,
        <>{block.code}</>,
        <>
          {block.owner_name_first}
          &nbsp;
          {block.owner_name_last}
        </>,
        <>{block.notes}</>,
        <>
          <div className="text-right leading-none">
            {renderDelete(block)}
            {renderManageAction(block)}
            {renderEditAction(block)}
          </div>
        </>
      ]
    }
  }

  const renderBlockTableStatic = () => {
    if (!fetched) { return <Loading />; }

    const mainBlock = blocks.filter(blk => blk.main)[0];
    const nonMainBlocks = blocks.filter(blk => !blk.main);

    const columns = ["Room Block Name", "Code", "Owner", "Notes", "Actions"];
    const items = [generateStaticRowData(mainBlock), ...nonMainBlocks.map(b => generateStaticRowData(b))];
    return (
      <div className="my-2">
        <GrowlTableStatic
          columns={columns}
          items={items}
        />
      </div>
    );
  };

  const renderNewBlockButton = () => {
    return (
      renderCreateButton(
        "Add Room Block",
        () => {
          setModalAddVisible(true);
        }
      )
    )
  };

  return (
    <div>
      {renderNewBlockButton()}
      {renderBlockTableStatic()}
      <HousingRoomBlockModal
        block={HousingBlankRoomBlock}
        modalVisible={modalAddVisible}
        resetModal={modalAddReset}
        title="Create Room Block"
        updateFunc={addBlock}
      />
    </div>
  );
};

HousingRoomBlocksIndex.propTypes = {
  addBlock: PropTypes.func.isRequired,
  blocks: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  fetched: PropTypes.bool.isRequired,
  hotels: PropTypes.array.isRequired,
  goEdit: PropTypes.func.isRequired,
  goRooms: PropTypes.func.isRequired,
  setEditBlockId: PropTypes.func.isRequired,
  updateBlocks: PropTypes.func.isRequired
};

export default HousingRoomBlocksIndex;
