import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: "14px"
        }
      }
    }
  },
  palette: {
    primary: {
      main: "#2196F3",
      dark: "#1E88E5"
    },
    secondary: {
      main: "#F89728",
      contrastText: "#FFFFFF",
      dark: "#E67C02"
    },
    preview: {
      main: "#0C4EB1",
      contrastText: "#FFFFFF"
    },
    error: {
      main: "#D2450F"
    }
  }
});

export default theme;

