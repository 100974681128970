import React from "react";

import { Autocomplete, TextField } from "@mui/material";

const CustomReportFilterField = (props) => {
  const { filter, updateAllCriteria, index, options, operationOptions, deleteIndex } = props;

  const setFilterOption = (key) => {
    updateAllCriteria({
      selectedOption: key,
      operation: filter.operation,
      operationValue: filter.operationValue,
      count: index
    });
  };

  const setFilterOperation = (key) => {
    updateAllCriteria({
      selectedOption: filter.selectedOption,
      operation: key,
      operationValue: filter.operationValue,
      count: index
    });
  };

  const setFilterValue = (key) => {
    updateAllCriteria({
      selectedOption: filter.selectedOption,
      operation: filter.operation,
      operationValue: key.target.value,
      count: index
    });
  };

  const renderSelect = (options, value, onChange, name) => {
    return (
      <>
        <Autocomplete
          options={options}
          value={value}
          onChange={onChange}
          renderInput={(params) => <TextField autoComplete="off" {...params} />}
          disablePortal
          sx={{
            width: "200px"
          }}
        />
        <input type="hidden" name={name} value={value} />
      </>
    );
  };

  return (
    <>
      {renderSelect(options, filter.selectedOption, (_e, newValue) => {
        setFilterOption(newValue);
      })}
      {renderSelect(operationOptions, filter.operation, (_e, newValue) => {
        setFilterOperation(newValue);
      })}
      <input
        type="text"
        className="report-tool-filter-input"
        style={{ marginBottom: "0px" }}
        placeholder="..value.."
        value={filter.operationValue}
        onChange={(key) => {
          setFilterValue(key);
        }}
      />
      <div
        className="report-tool-delete-modifier"
        onClick={() => {
          deleteIndex(index);
        }}
      >
        <img src="/images/reporting/form-icons/icon-close-grey.svg" />
      </div>
    </>
  );
};

export default CustomReportFilterField;
