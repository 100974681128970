import React, { useEffect, useState } from "react";

// import cloneDeep from "lodash/cloneDeep";

import CustomReportFilterChain from "./CustomReportFilterChain";

const CustomReportFilters = (props) => {
  const { edit, options, initialFilterChains, updateFilterChains, updateFilters } = props;
  const [chains, setChains] = useState([]);
  const [chainCount, setChainCount] = useState(0);
  const [initialChainsImported, setInitialChainsImported] = useState(false);

  useEffect(() => {
    if (edit) {
      importChains();
    }
  }, [edit, initialFilterChains]);

  const operations = [
    { value: "equals", id: 0, label: "Equals" },
    { value: "greater_than", id: 1, label: "Greater than" },
    { value: "greater_than_equal", id: 2, label: "Greater than or equals" },
    { value: "less_than", id: 3, label: "Less than" },
    { value: "less_than", id: 4, label: "Less than or equals" },
    { value: "contains", id: 5, label: "Contains" },
    { value: "begins_with", id: 6, label: "Begins with" },
    { value: "ends_with", id: 7, label: "Ends with" },
    { value: "is_true", id: 8, label: "is True" },
    { value: "is_false", id: 9, label: "is False" }
  ];

  const importChains = () => {
    if (!initialChainsImported && initialFilterChains.length > 0) {
      initialFilterChains.forEach((chain, index) => {
        addChain({
          count: index + 1,
          filters: importFilters(chain.filters),
          id: chain.id
        });
      });
      setChainCount(initialFilterChains.length);
      setInitialChainsImported(true);
    }
  };

  const importFilters = (filters) => {
    // exclude and log any filter whose option is missing from the options list
    filters = filters.filter((filter) => {
      const option = options.find((ele) => ele.value === filter.selectedOption.value);
      if (!option) {
        console.log("Filter option not found in options list: ", filter.selectedOption.value);
        return false;
      }
      return true;
    });

    return filters.map((filter, idx) => ({
      count: idx + 1,
      operationValue: filter.operationValue,
      selectedOption: {
        value: filter.selectedOption.value,
        label: options.find((ele) => ele.value === filter.selectedOption.value).label
      },
      operation: operations.find((ele) => ele?.value === filter?.operation?.value)
    }));
  };

  const updateChain = (chain) => {
    const newChains = chains;
    newChains.forEach((item) => {
      if (item.id === chain.id) {
        item.filters = chain.filters;
      }
    });
    updateFilterChains(newChains);
    setChains(newChains);
  };

  const deleteChainAtIndex = (index) => {
    let newChains = [];
    chains.forEach((chain) => {
      if (chain.count !== index) {
        newChains.push(chain);
      }
    });
    setChains(newChains);
  };

  const addChain = ({ count = null, filters = [] }) => {
    const newChains = chains;
    const newId = count || chainCount + 1;
    newChains.push({ count: newId, id: newId, filters: filters });
    setChains(newChains);
    updateFilterChains(newChains);
    setChainCount(chainCount + 1);
  };

  const renderOr = () => {
    return <div className="report-tool-filter-chain-or">OR</div>;
  };

  const renderFilterChains = () => {
    return chains.map((chain, _index) => {
      return (
        <div key={`filter-chain-${chain.id}`}>
          <div
            key={`filter-chain-item-${chain.id}`}
            style={{ display: "flex", alignItems: "baseline" }}
            className="report-tool-filter-chain-item"
          >
            <CustomReportFilterChain
              count={chain.count}
              id={chain.id}
              key={`filter-chain-${chain.id}`}
              operations={operations}
              options={options}
              index={chain.id}
              chain={chain}
              updateChain={updateChain}
              deleteIndex={deleteChainAtIndex}
            />
          </div>
          {renderOr()}
        </div>
      );
    });
  };

  return (
    <div className="report-tool-filter-wrapper">
      {renderFilterChains()}
      <div className="report-tool-modifier-link" onClick={addChain}>
        Add filter chain
      </div>
    </div>
  );
};

export default CustomReportFilters;
