import React, { useContext } from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControl, FormControlLabel } from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const MicroformWidgetSettings = (props) => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard, metadataFields } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;

  const dataSetOptions = {
    participantsByStatus: [
      { value: "preregistered", label: "Pre-registered", order: 0 },
      { value: "registered", label: "Registered", order: 1 },
      { value: "declined", label: "Declined", order: 2 },
      { value: "disallowed", label: "Disallowed", order: 3 },
      { value: "cancelled", label: "Cancelled", order: 4 }
    ]
  };

  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "participantsByStatus",
      widget_header: widget?.widget_header || "Header",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_config: {
        microform: widget?.widget_config?.microform || {},
        enabled_metadata_fields: widget?.widget_config?.enabled_metadata_fields || {},
        filters: widget?.widget_config?.filters || {
          role: "",
          tag: "",
          type: "",
          metadata: ""
        }
      }
    }
  });

  const dataSet = watch("widget_data_set");

  const dataSets = [
    { value: "participantsByStatus", label: "Participants By Status" },
  ];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = (submitData) => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    Object.entries(submitData.widget_config.microform[dataSet]).forEach(([key, microform]) => {
      if (microform.selected) {
        const option = dataSetOptions[dataSet].find((data) => data.value.toString() === key);

        // if it's not in the data set, purge it
        if (!option) {
          delete submitData.widget_config.microform[dataSet][key];
          return;
        }
        // otherwise, set the label
        microform.label = option.label;

        // if dataset has order values, set the order
        if (option.order !== undefined) {
          microform.order = option.order;
        }
      } else {
        delete submitData.widget_config.microform[dataSet][key];
      }
    });

    // purge configs from previously selected dataSets
    Object.entries(submitData.widget_config.microform).forEach(([key, _values]) => {
      if (key !== dataSet) {
        delete submitData.widget_config.microform[key];
      }
    });

    // add in type
    submitData.widget_type = "microform";
    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then((_result) => {
        onUpdate();
        closeModal();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderMicroformSelections = () => {
    return (
      <div className="pb-12">
        <h3>Select from the options below</h3>
        {dataSetOptions[dataSet].map((data) => {
          return (
            <div className="flex flex-row" key={`${dataSet}-dataset-${data.value}`}>
              <div className="w-48">
                <FormControl>
                  <Controller
                    name={`widget_config[microform][${dataSet}][${data.value}][selected]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={data.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const renderEnabledMetadataFields = () => {
    return (
      <div className="pb-12">
        <h3>Select from the options below</h3>
        {metadataFields.map((data) => {
          return (
            <div className="flex flex-row" key={`${dataSet}-metadata-${data.slug}`}>
              <div className="w-48">
                <FormControl>
                  <Controller
                    name={`widget_config[enabled_metadata_fields][${data.slug}][selected]`}
                    control={control}
                    defaultValue={false}
                    render={({ field: { value, onChange } }) => (
                      <FormControlLabel
                        label={data.label}
                        control={
                          <Checkbox checked={value} onChange={onChange} value={value} variant="outlined" size="small" />
                        }
                      />
                    )}
                  />
                </FormControl>
              </div>
            </div>
          );
        })}
      </div>
    );
  }

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          <div>{renderMicroformSelections()}</div>
          {renderEnabledMetadataFields()}
        </div>
        <WidgetSizeOptions control={control} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Microform widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

MicroformWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default MicroformWidgetSettings;
