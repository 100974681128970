import React from "react";
import { Link } from "react-router-dom";

const MainIndexNewEventTile = () => {
  const linkPath = "/events/new";

  return (
    <div className="sg-mgmt-tile-container">
      <Link to={linkPath}>
        <div className="sg-mgmt-tile bg-neutral-200">
          <img
            className="sg-mgmt-tile-new-event-plus"
            src="/images/icons/add-filled.svg"
            alt="Add New Event"
          />
          <div className="text-center text-black text-base font-normal font-['Roboto'] leading-normal tracking-tight">Add New Event</div>
        </div>
        <div className="sg-mgmt-tile-title">&nbsp;</div>
      </Link>
    </div>
  );
};

export default MainIndexNewEventTile;
