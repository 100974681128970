import React from "react";

import PageHeader from "@shared/PageHeader";

import PeopleEventParticipantTags from "./PeopleEventParticipantTags";
import PeopleEventParticipantTypes from "./PeopleEventParticipantTypes";

const PeopleEventParticipantSettings = () => {
  return (
    <div className="sg-mgmt-content sg-mgmt-content-full max-w-[700px]">
      <PageHeader text="Participant Settings" />
      <div className="mb-6 mt-12">
        <PeopleEventParticipantTypes />
      </div>
      <hr />
      <div className="mb-12 mt-6">
        <PeopleEventParticipantTags />
      </div>
    </div>
  );
};

export default PeopleEventParticipantSettings;
