import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import { Formik, Form } from "formik";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";
import Loading from "@shared/Loading";
import { alertError, alertHttpError } from "@shared/Alerts";
import SelectField from "@shared/forms/SelectField";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import HousingBlankRoomBlockRoomType from "./HousingBlankRoomBlockRoomType";
import HousingRoomBlockRoomTypeInventoryModal from "./HousingRoomBlockRoomTypeInventoryModal";
import { renderSubmitButton, renderCancelButton } from "@shared/FormUtils";

const HousingRoomBlockInventory = props => {
  const { config, goIndex, block, blocks, updateBlocks } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [manageRoomBlockRoomType, setManageRoomBlockRoomType] = useState(
    HousingBlankRoomBlockRoomType
  );
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [modalInventoryVisible, setModalInventoryVisible] = useState(false);
  const [hotels, setHotels] = useState([]);
  const [fetched, setFetched] = useState(false);
  const { user } = useContext(EventUserContext);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/hotels"));
        setHotels(result.data.hotels);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHotels();
  }, [apiRoot, block.id]);

  const sortRooms = toSort => sortBy(toSort, ["name"]);

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const modalInventoryReset = () => {
    setModalInventoryVisible(false);
  };

  const addRoomType = roomType => {
    setRoomTypes(sortRooms(roomTypes.concat(roomType)));
  };

  const updateHotels = hotel => {
    const newHotels = hotels.map(h => (h.gid === hotel.gid ? hotel : h));
    setHotels(newHotels);
  };

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  const renderHotelTable = roomBlockRoomTypes => {
    return (
      <div
        className="mb-2"
        key={`hotel-table-${roomBlockRoomTypes[0].room_type.hotel_id}`}
      >
        <h2>{roomBlockRoomTypes[0].room_type.hotel_name}</h2>
        <table className="w-full table-fixed">
          <thead>
            <tr>
              <th className="py-2 px-4 w-1/6 text-left text-white bg-ui-3">
                Hotel
              </th>
              <th className="py-2 px-4 w-1/6 text-left text-white bg-ui-3">
                Room Type
              </th>
              <th className="py-2 px-4 w-1/6 text-left text-white bg-ui-3">
                Description
              </th>
              <th className="py-2 px-4 w-1/6 text-right text-white bg-ui-3">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {roomBlockRoomTypes.map(rbrt => (
              <tr key={rbrt.gid}>
                <td className="py-2 px-4 font-bold border-t">
                  {rbrt.room_type.hotel_name}
                </td>
                <td className="py-2 px-4 font-bold border-t">
                  {rbrt.room_type.name}
                </td>
                <td className="py-2 px-4 border-t">
                  {rbrt.room_type.description}
                </td>
                <td className="py-2 px-4 text-right border-t">
                  <span
                    className="sg-mgmt-link"
                    onClick={() => {
                      setManageRoomBlockRoomType(rbrt);
                      setModalInventoryVisible(true);
                    }}
                  >
                    Manage Inventory
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderRoomTypes = () => {
    if (fetched) {
      const sortedRoomBlockRoomTypes = sortBy(block.room_block_room_types, [
        "room_type.hotel_id",
        "room_type.name"
      ]);
      const hotelIds = [
        ...new Set(
          block.room_block_room_types
            .map(e => e.room_type)
            .map(rt => rt.hotel_id)
        )
      ];

      return (
        <>
          {hotelIds.map(hid =>
            renderHotelTable(
              sortedRoomBlockRoomTypes.filter(
                rbrt => rbrt.room_type.hotel_id === hid
              )
            )
          )}
        </>
      );
    }
    return <Loading />;
  };

  const renderAddButton = () => {
    return (
      renderSubmitButton(
        "Add Room Type", 
        false
      )
    );
  };

  const renderBackButton = () => {
    return (
      renderCancelButton(
        "Back",
        goIndex
      )
    );
  };

  const renderRoomTypeSelect = () => {
    if (fetched) {
      const selectedRoomTypeGids = block.room_block_room_types
        .map(e => e.room_type)
        .map(rt => rt.gid);
      const options = hotels.map(h =>
        h.room_types.filter(rt => !selectedRoomTypeGids.includes(rt.gid))
        .map(rt => ({
          label: `${h.name}: ${rt.name}`,
          value: rt.id,
          hotel: h.name
        }))
      ).flat();
      return (
        <Formik
          initialValues={{
            room_type_id: ""
          }}
          onSubmit={(values, { setSubmitting }) => {
            const token = document.querySelector("[name=csrf-token]").content;
            axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
            axios({
              url: urljoin(apiRoot, `/housing/blocks/${block.id}`, "/add_type"),
              method: "POST",
              data: values
            })
              .then(response => {
                if (response.data.error === null) {
                  updateBlocks(response.data.block);
                  setSubmitting(false);
                } else {
                  alertError(response.data.error);
                  setSubmitting(false);
                }
              })
              .catch(error => {
                alertHttpError(error);
              });
          }}
        >
          {({ values, isSubmitting }) => (
            <div className="my-4">
              <Form className="sg-mgmt-form" id="sg-mgmt-block-room-type-form">
                <div className="flex">
                  <div className="flex-auto mr-2">
                    <SelectField
                      name="room_type_id"
                      options={options}
                      groupBy={(option) => option.hotel}
                    />
                  </div>
                  <div className="flex-none">
                    {renderAddButton(values.room_type_id, isSubmitting)}
                  </div>
                </div>
              </Form>
            </div>
          )}
        </Formik>
      );
    }
    return <></>;
  };

  return (
    <div>
      <h1>{block.name}</h1>
      {renderRoomTypes()}
      {renderRoomTypeSelect()}
      <HousingRoomBlockRoomTypeInventoryModal
        blocks={blocks}
        config={config}
        block={block}
        modalVisible={modalInventoryVisible}
        resetModal={modalInventoryReset}
        roomBlockRoomType={manageRoomBlockRoomType}
        title="Set Inventory"
        updateFunc={updateBlocks}
      />
      {renderBackButton()}
    </div>
  );
};

HousingRoomBlockInventory.propTypes = {
  config: PropTypes.object.isRequired,
  goIndex: PropTypes.func.isRequired,
  block: PropTypes.object.isRequired,
  blocks: PropTypes.array.isRequired,
  updateBlocks: PropTypes.func.isRequired
};

export default HousingRoomBlockInventory;
