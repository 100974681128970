import React from "react";

import TabbedPage from "@shared/TabbedPage";

import TicketingPackages from "./packages/TicketingPackages";
import TicketingTickets from "./tickets/TicketingTickets";

const Ticketing = () => {
  const getTabs = () => {
    let tabs = [
      {
        label: "Tickets",
        component: TicketingTickets,
        componentProps: {}
      },
      {
        label: "Packages",
        component: TicketingPackages,
        componentProps: {}
      }
    ];

    return tabs;
  };
  return <TabbedPage tabs={getTabs()} />;
};

export default Ticketing;
