import React, { useContext } from "react";


import WidgetDownloadIcon from "@dashboard/form/WidgetDownloadIcon";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";
import StatTile from "@shared/StatTile";
import clsx from "clsx";
import sortBy from "lodash/sortBy";

const OverviewCommunications = () => {
  const { widget, values } = useContext(WidgetContext);
  const { event } = useContext(EventContext).values;
  const tableCls = "pb-4 pr-4 text-black";
  const tableHeaderStyle = { width: "120px", fontWeight: "400" };

  const renderItemTypeName = () => {
    switch (widget.widget_data_set) {
      case "commsOverviewByTemplate":
        return "Email Template";
      case "commsOverviewByTag":
        return "Email Template Tag";
      default:
        return "Email";
    }
  };

  const reportUrl = gid => {
    switch (widget.widget_data_set) {
      case "commsOverviewByTemplate":
        return `/reporting/events/${event.slug}/emails/activity/template/${gid}`;
      case "commsOverviewByTag":
        return `/reporting/events/${event.slug}/emails/activity/tag/${gid}`;
      default:
        return "";
    }
  };

  const renderReportLink = gid => {
    return (
      <div className="mx-auto" style={{ width: "fit-content" }}>
        <a href={reportUrl(gid)}>
          <WidgetDownloadIcon size={36} />
        </a>
      </div>
    );
  };

  const renderTableHeaders = () => {
    return (
      <thead>
        <tr className="text-xs font-normal uppercase tracking-widest">
          <th className={clsx(tableCls, "text-left")} style={{ width: "240px", fontWeight: "400" }}>
            {renderItemTypeName()}
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Recipients
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Opened
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Clicked
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Bounced
          </th>
          <th className={tableCls} style={tableHeaderStyle}>
            Report
          </th>
          <th className={tableCls}>&nbsp;</th>
        </tr>
      </thead>
    );
  };

  const renderRows = () =>
    sortBy(values, ["delivered", "opened"])
      .reverse()
      .map((item, _idx) => renderRow(item));

  const renderRow = item => {
    return (
      <tr key={item.gid}>
        <td className={tableCls}>{item.name}</td>
        <td className={tableCls}>
          <StatTile color={"#1A206C"} value={item.delivered} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#1B5EC1"} value={item.opened} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#2196F3"} value={item.clicked} />
        </td>
        <td className={tableCls}>
          <StatTile color={"#F89728"} value={item.bounced} />
        </td>
        <td className={tableCls}>{renderReportLink(item.gid)}</td>
      </tr>
    );
  };

  return (
    <table className="w-full">
      {renderTableHeaders()}
      <tbody>{renderRows()}</tbody>
    </table>
  );
};

export default OverviewCommunications;
