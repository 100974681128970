import React from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetEmailSelect = props => {
  const { control, emails } = props;
  return (
    <div className="mb-4 mt-4">
      <div className="mb-4 w-full">
        <FormControl fullWidth>
          <FormLabel>Select email</FormLabel>
          <Controller
            name="widget_config[email_id]"
            control={control}
            render={({ field: { value, onChange, ...field } }) => (
              <Select
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                MenuProps={{ disableScrollLock: true }}
              >
                {emails.map(email => (
                  <MenuItem key={`email-${email.id}`} value={email.id}>
                    {email.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </FormControl>
      </div>
    </div>
  );
};

WidgetEmailSelect.propTypes = {
  control: PropTypes.object.isRequired,
  emails: PropTypes.array.isRequired
};

export default WidgetEmailSelect;
