import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import orderBy from "lodash/orderBy";
import urljoin from "url-join";
import MainIndexNewEventTile from "./MainIndexNewEventTile";
import MainIndexTile from "./MainIndexTile";
import MainMenu from "../MainMenu";
import ToggleArrow from "@shared/ToggleArrow";
const MainIndex = ({ rootUrl }) => {
  const [events, setEvents] = useState([]);
  const [fetched, setFetched] = useState(false);
  const [archivedOpen, setArchivedOpen] = useState(false);
  useEffect(() => {
    const fetchEvents = async () => {
      const result = await axios(urljoin(rootUrl, "/-/events"));
      setEvents(result.data);
      setFetched(true);
    };

    fetchEvents();
  }, [rootUrl]);

  const renderTiles = archived => {
    const filteredEvents = events.filter(e => e.archived === archived);
    const sortedEvents = orderBy(
      filteredEvents,
      ["date_end", "date_begin", "name"],
      ["desc", "desc", "asc"]
    );
    return sortedEvents.map(e => <MainIndexTile event={e} key={e.gid} />);
  };

  const renderNewEventTile = () => {
    // TODO: Check user role and conditionally render
    return <MainIndexNewEventTile rootUrl={rootUrl} />;
  };

  toggleArchiveOpened = () => {
    setArchivedOpen(!archivedOpen);
  }

  const renderContent = () => {
    if (fetched) {
      return (
        <>
          <div className="sg-mgmt-index-header">Company Events</div>
          <div className="sg-mgmt-tile-section">
            {renderNewEventTile()}
            {renderTiles(false)}
          </div>
          <div onClick={toggleArchiveOpened} className="cursor-pointer sg-mgmt-index-header">
            <ToggleArrow
              open={archivedOpen}
              className="inline-block mr-2"
            />
            Archived Events
          </div>
          <div className="sg-mgmt-tile-section">
            {archivedOpen ? renderTiles(true) : ""}
          </div>
        </>
      );
    }
    return <h2>Loading</h2>;
  };

  return (
    <div className="sg-mgmt-content-frame">
      <MainMenu />
      <div className="sg-mgmt-index">{renderContent()}</div>
    </div>
  );
};

MainIndex.propTypes = {
  rootUrl: PropTypes.string.isRequired
};

export default MainIndex;
