import React, { useContext } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import clsx from 'clsx';
import { Field, Formik, Form } from "formik";
import urljoin from "url-join";
import EventContext from "@event/EventContext";
import CheckFieldSmall from "@shared/CheckFieldSmall";
import DatePickerField from "@shared/DatePickerField";
import EventDetailsBlankToken from "./EventDetailsBlankToken";
import { renderCancelButton, renderSubmitButton } from "@shared/FormUtils";
import { Stack } from "@mui/material";
import { renderDateField, renderTextField } from "@shared/FormUtils";
const EventDetailsTokenForm = props => {
  const { apiRoot } = useContext(EventContext).values;
  const { callbackFailure, callbackSuccess, cancelButton, token } = props;

  const isEdit = () => {
    return token && token.id;
  };

  const formConfig = (() => {
    if (isEdit()) {
      return {
        alert: "updated",
        formId: "sg-mgmt-form-token-edit",
        formUrl: urljoin(apiRoot, "/details/tokens", `/${token.id}`),
        method: "PATCH",
        saveButton: "Update",
        title: "Edit API Token"
      };
    }
    return {
      alert: "added",
      formId: "sg-mgmt-form-token-add",
      formUrl: urljoin(apiRoot, "/details/tokens"),
      method: "POST",
      saveButton: "Create",
      title: "Create API Token"
    };
  })();

  const permissionsFields = {
    attendees: [
      { label: "View", field: "attendees_view" },
      { label: "Edit", field: "attendees_edit" }
    ],
    forms: [
      { label: "View", field: "forms_view" },
      { label: "Edit", field: "forms_edit" }
    ],
    housing: [
      { label: "View", field: "housing_view" },
      { label: "Edit", field: "housing_edit" }
    ],
    feature_flags: [
      { label: "View", field: "feature_flag_view" },
      { label: "Edit", field: "feature_flag_edit" }
    ],
    meetings: [
      { label: "View", field: "meetings_view" },
      { label: "Edit", field: "meetings_edit" }
    ],
    sessions: [
      { label: "View", field: "sessions_view" },
      { label: "Edit", field: "sessions_edit" }
    ],
    speakers: [
      { label: "View", field: "speakers_view" },
      { label: "Edit", field: "speakers_edit" }
    ],
    surveys: [
      { label: "View", field: "surveys_view" },
      { label: "Edit", field: "surveys_edit" }
    ],
    users: [
      { label: "View", field: "users_view" },
      { label: "Edit", field: "users_edit" }
    ],
    ticketing: [
      { label: "View", field: "ticketing_view" },
      { label: "Edit", field: "ticketing_edit" }
    ],
  };

  const renderField = (label, field, formatClasses = []) => (
    <div className={clsx("sg-mgmt-form-input-container", formatClasses)}>
      <label>{label}</label>
      <Field
        className="sg-mgmt-form-input"
        type="text"
        name={`token[${field}]`}
        autoComplete="off"
      />
    </div>
  );

  const renderCheck = (fieldName, label) => (
    <div className="sg-mgmt-permissions-section-field" key={fieldName}>
      <CheckFieldSmall fieldName={`token[${fieldName}]`} label={label} />
    </div>
  );

  const renderSection = (label, inputs) => (
    <div className="sg-mgmt-permissions-section">
      <div className="sg-mgmt-permissions-section-name">{label}</div>
      <div className="sg-mgmt-permissions-section-fields">
        {inputs.map(input => renderCheck(input.field, input.label))}
      </div>
    </div>
  );

  const formInitialValues = () => {
    if (isEdit()) {
      return {
        attendees_view: token.attendees_view || false,
        attendees_edit: token.attendees_edit || false,
        housing_view: token.housing_view || false,
        housing_edit: token.housing_edit || false,
        forms_view: token.forms_view || false,
        forms_edit: token.forms_edit || false,
        feature_flag_edit: token.feature_flag_edit || false,
        feature_flag_view: token.feature_flag_view || false,
        meetings_view: token.meetings_view || false,
        meetings_edit: token.meetings_edit || false,
        sessions_view: token.sessions_view || false,
        sessions_edit: token.sessions_edit || false,
        speakers_view: token.speakers_view || false,
        speakers_edit: token.speakers_edit || false,
        surveys_view: token.surveys_view || false,
        surveys_edit: token.surveys_edit || false,
        ticketing_view: token.ticketing_view || false,
        ticketing_edit: token.ticketing_edit || false,
        users_view: token.users_view || false,
        users_edit: token.users_edit || false,
        name: token.name || "",
        expiry: token.expiry || null,
        whitelist_values: token.whitelist || ""
      };
    }
    return EventDetailsBlankToken;
  };

  const renderForm = () => {
    return (
      <Formik
        initialValues={{
          token: formInitialValues()
        }}
        onSubmit={(values, { setSubmitting }) => {
          const form = document.getElementById(formConfig.formId);
          const formData = new FormData(form);
          if (values.token.whitelist_values) {
            values.token.whitelist_values.split(",").forEach(ip => {
              formData.append("token[whitelist][]", ip);
            });
          }
          const csrfToken = document.querySelector("[name=csrf-token]").content;
          axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;
          axios({
            url: formConfig.formUrl,
            method: formConfig.method,
            data: formData
          }).then(response => {
            if (response.data.error === null) {
              callbackSuccess(response);
            } else {
              callbackFailure(response);
              setSubmitting(false);
            }
          });
        }}
      >
        {({ isSubmitting }) => (
          <Form className="sg-mgmt-form" id={formConfig.formId}>
            <div className="sg-mgmt-form-container">
              <h2>Token Details</h2>
              <Stack direction="row" spacing={2}>
                {renderTextField("Name", "token[name]")}
                {renderDateField("Expiration", "token[expiry]")}
              </Stack>
              <Stack direction="row" spacing={2}>
                {renderTextField(
                  "IP Whitelist (comma separated)",
                  "token[whitelist_values]"
                )}
              </Stack>
              <h2>Permissions</h2>
              <div className="sg-mgmt-permissions-container">
                {renderSection("Attendees", permissionsFields.attendees)}
                {renderSection("Feature Flags", permissionsFields.feature_flags)}
                {renderSection("Forms", permissionsFields.forms)}
                {renderSection("Housing", permissionsFields.housing)}
                {renderSection("Meetings", permissionsFields.meetings)}
                {renderSection("Sessions", permissionsFields.sessions)}
                {renderSection("Speakers", permissionsFields.speakers)}
                {renderSection("Surveys", permissionsFields.surveys)}
                {renderSection("Ticketing", permissionsFields.ticketing)}
                {renderSection("Users", permissionsFields.users)}
              </div>
            </div>
            <Stack direction="row" spacing={2}>
              {renderSubmitButton(formConfig.saveButton, isSubmitting)}
              {renderCancelButton("Cancel", cancelButton)}

            </Stack>
          </Form>
        )}
      </Formik>
    );
  };

  return <>{renderForm()}</>;
};

EventDetailsTokenForm.defaultProps = {
  callbackFailure: () => {},
  callbackSuccess: () => {},
  cancelButton: () => {}
};

EventDetailsTokenForm.propTypes = {
  callbackFailure: PropTypes.func,
  callbackSuccess: PropTypes.func,
  cancelButton: PropTypes.func,
  token: PropTypes.object.isRequired
};

export default EventDetailsTokenForm;
