import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import Loading from "@shared/Loading";
import { alertError, alertHttpError } from "@shared/Alerts";
import EventContext from "@event/EventContext";
import EventUserContext from "@event/EventUserContext";
import HousingHotelForm from "./HousingHotelForm";

const HousingHotel = props => {
  const { editHotelId, goIndex, setEditHotelId, updateHotels } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [fetched, setFetched] = useState(false);
  const [hotel, setHotel] = useState(false);
  const { user } = useContext(EventUserContext);

  const editEnabled = () => {
    if (user.role === "basic") {
      return false;
    }
    return true;
  };

  useEffect(() => {
    const fetchHotel = async () => {
      try {
        const result = await axios(
          urljoin(apiRoot, "/housing/hotels", `/${editHotelId}`)
        );
        setHotel(result.data.hotel);
        setFetched(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHotel();
  }, [apiRoot, editHotelId]);

  const success = h => {
    updateHotels(h);
    goIndex();
    setEditHotelId(null);
  };

  const failure = () => {
    alertError("Failed to update hotel");
  };

  const renderHotelForm = () => {
    if (fetched) {
      return (
        <HousingHotelForm
          callbackFailure={failure}
          callbackSuccess={success}
          cancelButton={goIndex}
          hotel={hotel}
        />
      );
    }
    return <Loading />;
  };

  return <div>{renderHotelForm()}</div>;
};

HousingHotel.propTypes = {
  config: PropTypes.object.isRequired,
  editHotelId: PropTypes.number.isRequired,
  goIndex: PropTypes.func.isRequired,
  setEditHotelId: PropTypes.func.isRequired,
  updateHotels: PropTypes.func.isRequired
};

export default HousingHotel;
