import React, { useState } from "react";
import PropTypes from "prop-types";

import CustomReportCheckBox from "./CustomReportCheckBox";

const ReportFieldSelect = (props) => {
  const { selectedKeys, massKeySelection, options, onChange } = props;
  const [selectedOption, setSelectedOption] = useState({ value: "", label: "", datatype: "" });
  const [optionArray, setOptionArray] = useState([]);
  const [openOptions, setOpenOptions] = useState([]);
  console.log(`render ReportFieldSelect with ${selectedKeys}`);

  const optionClicked = (e, option) => {
    // e.preventDefault();
    onChange(option);
  };

  const objectExpand = (e, object) => {
    e.stopPropagation();
    let newOptions = openOptions;
    if (newOptions.includes(object)) {
      newOptions = newOptions.filter((item) => {
        return item !== object;
      });
    } else {
      newOptions.push(object);
    }
    setOpenOptions(newOptions);
  };

  const renderOptions = () => {
    const selected = selectedKeys.map((ele) => ele.value);
    return (
      <>
        {options.map((option) => (
          <div key={option.value} className="report-field-select-option pb-1" onClick={(e) => optionClicked(e, option)}>
            <CustomReportCheckBox disabled={false} inline={true} value={selected.includes(option.value)} />
            <div className="inline">{option.label}</div>
          </div>
        ))}
      </>
    );
  };

  return <div>{renderOptions()}</div>;
};

export default ReportFieldSelect;
