import React from "react";
import PropTypes from "prop-types";

const StatTile = props => {
  const { color, value } = props;

  return (
    <div
      style={{ backgroundColor: color, width: "120px", height: "36px" }}
      className="rounded text-center text-2xl font-medium leading-9 text-white"
    >
      {value}
    </div>
  );
};

StatTile.propTypes = {
  color: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired
};

export default StatTile;
