import React from "react";
import PropTypes from "prop-types";

import { TextField, FormControl, FormLabel } from "@mui/material";
import { Controller } from "react-hook-form";

const WidgetHeaderField = props => {
  const { control, optional, placeholder } = props;
  const labelText = optional ? "Header (optional)" : "Header";

  return (
    <div className="mb-4 w-full">
      <FormControl fullWidth>
        <FormLabel>{labelText}</FormLabel>
        <Controller
          name="widget_header"
          control={control}
          render={({ field: { value, onChange, ...field } }) => (
            <>
              <TextField
                {...field}
                fullWidth
                onChange={onChange}
                value={value}
                variant="outlined"
                size="small"
                placeholder={placeholder}
              />
            </>
          )}
        />
      </FormControl>
    </div>
  );
};

WidgetHeaderField.defaultProps = {
  errors: {},
  optional: false,
  placeholder: ""
};

WidgetHeaderField.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  optional: PropTypes.bool,
  placeholder: PropTypes.string
};

export default WidgetHeaderField;
