import React from "react";
import PropTypes from "prop-types";
import { SnackbarProvider } from 'notistack';
import MuiAlert from "@shared/MuiAlert";
import Footer from "@shared/Footer";
import MainBanner from "./MainBanner";
import MainEventForm from "./MainEventForm";

const MainEventEditApp = props => {
  const { config, rootUrl, user } = props;

  return (
    <SnackbarProvider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={3500}
      Components={{
        mui: MuiAlert
      }}
    >
      <div className="sg-mgmt-container">
        <MainBanner config={config} rootUrl={rootUrl} user={user} />
        <div className="sg-mgmt-body-container container">
          <div className="row">
            <div className="col-xl-12">
              <div className="sg-mgmt-body">
                <MainEventForm {...props} />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </SnackbarProvider>
  );
};

MainEventEditApp.propTypes = {
  config: PropTypes.object.isRequired,
  event: PropTypes.object.isRequired,
  rootUrl: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired
};

export default MainEventEditApp;
