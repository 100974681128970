import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import axios from "axios";
import sortBy from "lodash/sortBy";
import urljoin from "url-join";

import EventContext from "@event/EventContext";
import { alertHttpError } from "@shared/Alerts";
import PageHeader from "@shared/PageHeader";

import HousingRoomBlock from "./HousingRoomBlock";
import HousingRoomBlockInventory from "./HousingRoomBlockInventory";
import HousingRoomBlocksIndex from "./HousingRoomBlocksIndex";

const HousingRoomBlocks = props => {
  const { forceUpdate } = props;
  const { apiRoot } = useContext(EventContext).values;
  const [blocks, setBlocks] = useState([]);
  const [config, setConfig] = useState({});
  const [hotels, setHotels] = useState([]);
  const [page, setPage] = useState("index");
  const [editBlockId, setEditBlockId] = useState(null);
  const [fetchedBlocks, setFetchedBlocks] = useState(false);
  const [fetchedHotels, setFetchedHotels] = useState(false);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/hotels"));
        setHotels(result.data.hotels);
        setConfig(result.data.configuration);
        setFetchedHotels(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    const fetchBlocks = async () => {
      try {
        const result = await axios(urljoin(apiRoot, "/housing/blocks"));
        setBlocks(result.data.blocks);
        setFetchedBlocks(true);
      } catch (error) {
        alertHttpError(error);
      }
    };

    fetchHotels();
    fetchBlocks();
  }, [apiRoot]);

  const fetched = () => {
    return fetchedHotels && fetchedBlocks;
  };

  const goToPage = p => {
    setPage(p);
  };

  useEffect(() => {
    goToPage("index");
  }, [forceUpdate]);

  const sortBlocks = blocksToSort => sortBy(blocksToSort, ["name"]);

  const addBlock = block => {
    setBlocks(sortBlocks(blocks.concat(block)));
  };

  const updateBlocks = block => {
    const newBlocks = blocks.map(b => (b.gid === block.gid ? block : b));
    setBlocks(newBlocks);
  };

  const renderPage = () => {
    switch (page) {
      case "index":
        return (
          <HousingRoomBlocksIndex
            addBlock={addBlock}
            blocks={blocks}
            config={config}
            setEditBlockId={setEditBlockId}
            fetched={fetched()}
            hotels={hotels}
            goEdit={() => goToPage("edit")}
            goRooms={() => goToPage("room_types")}
            updateBlocks={updateBlocks}
          />
        );
      case "edit":
        return (
          <HousingRoomBlock
            config={config}
            blocks={blocks}
            editBlockId={editBlockId}
            goIndex={() => goToPage("index")}
            setEditBlockId={setEditBlockId}
            updateBlocks={updateBlocks}
          />
        );
      case "room_types":
        return (
          <HousingRoomBlockInventory
            blocks={blocks}
            config={config}
            block={blocks.find(ele => ele.id === editBlockId)}
            goIndex={() => goToPage("index")}
            updateBlocks={updateBlocks}
          />
        );
      default:
        throw new Error("invalid housing page");
    }
  };

  return (
    <div>
      <PageHeader text="Room Blocks" />
      {renderPage()}
    </div>
  );
};

HousingRoomBlocks.propTypes = {
  forceUpdate: PropTypes.any.isRequired
};

export default HousingRoomBlocks;
