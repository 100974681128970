import React from "react";
import EventDetailsEventInfo from "./EventDetailsEventInfo";
import EventDetailsIntegrations from "./integrations/EventDetailsIntegrations";
import EventDetailsRegistration from "./registration/EventDetailsRegistration";
import EventDetailsTokens from "./tokens/EventDetailsTokens";
import Metadata from "./metadata/Metadata";
import TabbedPage from "@shared/TabbedPage";

const EventDetails = () => {
  return (
    <TabbedPage
      tabs={[
        { label: "Event Info",     component: EventDetailsEventInfo, componentProps: {}},
        { label: "Registration",   component: EventDetailsRegistration, componentProps: {}},
        { label: "Integrations",   component: EventDetailsIntegrations, componentProps: {}},
        { label: "API Management", component: EventDetailsTokens, componentProps: {}},
        { label: "Metadata",       component: Metadata, componentProps: {}},
      ]}
    />
  )
};

export default EventDetails;
