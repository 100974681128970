import React from "react";

const ErrorFallback = ({ error }) => (
  <div className="sg-mgmt-error-boundary">
    <h2>This page has experienced an error.</h2>
    <p>Please try again.</p>
    <p>If you continue to have this issue, please notify your SilentGrowl contact.</p>
  </div>
);

export default ErrorFallback;
