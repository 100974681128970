import React from "react";
import PropTypes from "prop-types";

import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const WidgetDownloadIcon = props => {
  const { size } = props;

  return (
    <div
      className="number-circle-badge overflow-hidden text-center"
      style={{
        backgroundColor: "#1ba310",
        borderRadius: Math.round(size * 0.5),
        color: "#ffffff",
        fontSize: Math.round(size * 0.5),
        lineHeight: `${size}px`,
        height: size,
        width: size
      }}
    >
      <FontAwesomeIcon icon={faFileDownload} />
    </div>
  );
};

WidgetDownloadIcon.propTypes = {
  size: PropTypes.number.isRequired
};

export default WidgetDownloadIcon;
