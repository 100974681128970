import React from "react"

const InputSlugGenerator = ({
  maxSlugLength,
  formikProps,
  name,
  generatedValue,
  associatedValue
}) => {

  const generateSlug = () => {
    if(formikProps) {
      if(generatedValue) {
        formikProps.setFieldValue(name, generatedValue)
      }else if(associatedValue) {
        formikProps.setFieldValue(name, slugify(associatedValue))
      }else {
        formikProps.setFieldValue(name, `slug${Math.floor(Math.random()*(1000001)+1)}`)
      }
    }

  }

  const slugify = (str) => {
    str = str.replace(/^\s+|\s+$/g, ''); // trim
    str = str.toLowerCase();
  
    // remove accents, swap ñ for n, etc
    var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
    var to   = "aaaaeeeeiiiioooouuuunc------";
    for (var i=0, l=from.length ; i<l ; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
    }

    str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-'); // collapse dashes
    if(maxSlugLength) {
      str = str.substring(0, maxSlugLength)
    }
    return str;
  }

  return (
    <div
      onClick={generateSlug}
      className="absolute left-4 text-blue cursor-pointer"
    >
      Click to auto generate value
    </div>
  )
}

export default InputSlugGenerator