import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import axios from "axios";
import { format } from 'date-fns';
import { Controller, useForm } from "react-hook-form";
import urljoin from "url-join";

import WidgetColorThemeField from "@dashboard/form/WidgetColorThemeField";
import WidgetDataSetSelect from "@dashboard/form/WidgetDataSetSelect";
import WidgetFilterByTag from "@dashboard/form/WidgetFilterByTag";
import WidgetFilterByType from "@dashboard/form/WidgetFilterByType";
import WidgetHeaderField from "@dashboard/form/WidgetHeaderField";
import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const GraphWidgetSettings = props => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard, tags, types } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    formState: { errors, isSubmitting },
    handleSubmit,
    watch
  } = useForm({
    defaultValues: {
      widget_data_set: widget?.widget_data_set || "registrationActivity",
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_theme: widget?.widget_theme || "#1A206C",
      widget_config: {
        time_period: widget?.widget_config?.time_period || "",
        date_begin: widget?.widget_config?.date_begin || "",
        date_end: widget?.widget_config?.date_end || "",
        custom_stepping: widget?.widget_config?.custom_stepping || "",
        filters: widget?.widget_config?.filters || {
          tag: "",
          type: ""
        },
        graph: widget?.widget_config?.graph || {}
      }
    }
  });

  const timePeriod = watch("widget_config[time_period]");

  const dataSets = [{ value: "registrationActivity", label: "Registration Activity" }];

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = submitData => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;

    // add in type
    submitData.widget_type = "graph";

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then(result => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const renderTimePeriodSetting = () => {
    return (
      <div className="mb-4 mt-4">
        <div className="mb-4 w-full">
          <FormControl fullWidth>
            <FormLabel>Select time period</FormLabel>
            <Controller
              name="widget_config[time_period]"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <Select
                  {...field}
                  fullWidth
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  size="small"
                  MenuProps={{ disableScrollLock: true }}
                >
                  <MenuItem value={"day"}>By Day</MenuItem>
                  <MenuItem value={"week"}>By Week</MenuItem>
                  <MenuItem value={"month"}>By Month</MenuItem>
                  <MenuItem value={"custom"}>Custom</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </div>
      </div>
    )
  };

  // copied from FieldConversions.js
  // TODO: replace when date-fns comes out
  const getDateWithoutTimezone = (value) => {
    let temp_value = ""
    if (value) {
      if (typeof value == "object") {
        temp_value = value.toISOString()
      } else {
        temp_value = value
      }
      return new Date(temp_value.split("T")[0] + " 00:00:00");
    }
    return null
  }

  // TODO: Prevent end date being before start date
  const renderCustomDateSetting = () => {
    if (timePeriod !== "custom") return <></>;

    return (
      <div className="mb-4 mt-4 flex">
        <div className="mb-4 w-1/2 mr-4">
          <FormControl fullWidth>
            <FormLabel>Start Date</FormLabel>
            <Controller
              name="widget_config[date_begin]"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <DatePicker
                  {...field}
                  format="yyyy-MM-dd"
                  timezone="system"
                  fullWidth
                  onChange={date => {
                    onChange(format(date, 'yyyy-MM-dd'))
                  }}
                  value={getDateWithoutTimezone(value)}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true
                    },
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </div>
        <div className="mb-4 w-1/2 ml-4">
          <FormControl fullWidth>
            <FormLabel>End Date</FormLabel>
            <Controller
              name="widget_config[date_end]"
              control={control}
              render={({ field: { value, onChange, ...field } }) => (
                <DatePicker
                  {...field}
                  format="yyyy-MM-dd"
                  timezone="system"
                  fullWidth
                  onChange={date => {
                    onChange(format(date, 'yyyy-MM-dd'))
                  }}
                  value={getDateWithoutTimezone(value)}
                  slotProps={{
                    textField: {
                      size: "small",
                      fullWidth: true
                    },
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                />
              )}
            />
          </FormControl>
        </div>
      </div>
    )
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <WidgetHeaderField control={control} errors={errors} placeholder={"i.e. Registration Activity"} />
          <WidgetDataSetSelect control={control} dataSets={dataSets} errors={errors} />
          {renderTimePeriodSetting()}
          {renderCustomDateSetting()}
          <WidgetFilterByTag control={control} tags={tags} />
          <WidgetFilterByType control={control} types={types} />
          <WidgetColorThemeField control={control} fieldName="widget_theme" errors={errors} />
        </div>
        <WidgetSizeOptions control={control} />
        <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={isSubmitting} isEdit={!!widget?.id} />
      </form>
    );
  };

  return (
    <div className="group relative bg-white" data-id={widget?.id}>
      <div>
        <h2>Graph widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

GraphWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default GraphWidgetSettings;
