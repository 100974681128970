import React, { useContext } from "react";
import PropTypes from "prop-types";

import { FormControl, FormLabel, MenuItem, Select, TextField } from "@mui/material";
import axios from "axios";
import { useForm, Controller } from "react-hook-form";
import urljoin from "url-join";

import WidgetSettingsButtons from "@dashboard/form/WidgetSettingsButtons";
import WidgetSizeOptions from "@dashboard/form/WidgetSizeOptions";
import WidgetBoardContext from "@dashboard/WidgetBoardContext";
import WidgetContext from "@dashboard/WidgetContext";
import EventContext from "@event/EventContext";

const IframeWidgetSettings = props => {
  const { closeModal } = props;
  const { onUpdate, widget } = useContext(WidgetContext);
  const { widgetBoard } = useContext(WidgetBoardContext);
  const { apiRoot } = useContext(EventContext).values;
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      widget_header: widget?.widget_header || "",
      widget_position: widget?.widget_position || 0,
      widget_size: widget?.widget_size || 1,
      widget_config: {
        url: widget?.widget_config?.url || "",
        scrolling: widget?.widget_config?.scrolling || "no"
      }
    }
  });

  const formConfig = (() => {
    if (widget.id) {
      return {
        method: "PATCH",
        url: urljoin(apiRoot, "/widgets", `/${widget.id}`)
      };
    }
    return {
      method: "POST",
      url: urljoin(apiRoot, "/widgets")
    };
  })();

  const submitFn = submitData => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    submitData.widget_type = "iframe";

    axios({
      method: formConfig.method,
      url: formConfig.url,
      data: {
        widget: submitData,
        widget_board_id: widgetBoard.id
      }
    })
      .then(result => {
        onUpdate();
        closeModal();
        console.log(result.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const renderForm = () => {
    return (
      <form onSubmit={handleSubmit(submitFn)}>
        <div className="mb-4 mt-4">
          <div className="mb-4 mt-4 w-full">
            <FormControl fullWidth>
              <FormLabel>Iframe URL</FormLabel>
              <Controller
                name="widget_config[url]"
                control={control}
                rules={{
                  required: "URL is required",
                  pattern: {
                    value: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/gi,
                    message: "URL must be valid and begin with http:// or https://"
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <TextField fullWidth value={value} onChange={onChange} error={!!errors?.widget_config?.url}/>
                )}
              />
              {errors?.widget_config?.url && <span className="text-red-500">{errors?.widget_config?.url?.message}</span>}
            </FormControl>
          </div>
          <div className="mb-4 mt-4 w-full">
            <FormControl fullWidth>
              <FormLabel>Scrolling</FormLabel>
              <Controller
                name="widget_config[scrolling]"
                control={control}
                render={({ field: { value, onChange, ...field } }) => (
                  <Select {...field} fullWidth onChange={onChange} value={value} variant="outlined" size="small">
                    <MenuItem value={"no"}>No</MenuItem>
                    <MenuItem value={"yes"}>Yes</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </div>
          <WidgetSizeOptions control={control} />
          <WidgetSettingsButtons closeModal={closeModal} isSubmitDisabled={!isSubmitting && !isValid} isEdit={!!widget?.id} />
        </div>
      </form>
    );
  };

  return (
    <div className="group relative bg-white">
      <div>
        <h2>Iframe widget</h2>
      </div>
      <div>{renderForm()}</div>
    </div>
  );
};

IframeWidgetSettings.propTypes = {
  closeModal: PropTypes.func.isRequired
};

export default IframeWidgetSettings;
