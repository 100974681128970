import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import urljoin from "url-join";
import { alertHttpError, alertSuccess } from "@shared/Alerts";
import GrowlModal from "@shared/GrowlModal";
import GrowlTable from "@shared/GrowlTable";
import { formatDateFriendly } from "@shared/TimeUtils";
import EventContext from "@event/EventContext";
import HousingBlankBooking from "./HousingBlankBooking";
import HousingBookingModal from "./HousingBookingModal";
import { renderCreateButton } from "@shared/FormUtils";

const HousingBookingsList = props => {
  const {
    addBooking,
    bookings,
    blocks,
    config,
    deleteBooking,
    editBooking,
    goToFinalize,
    hotels,
    instructions,
    participants,
    setEditBooking,
    updateBookings
  } = props;
  const { apiRoot, event } = useContext(EventContext).values;
  const [modalAddVisible, setModalAddVisible] = useState(false);
  const [modalEditVisible, setModalEditVisible] = useState(false);
  const [warnModal, setWarnModal] = useState(false);
  const [warnModalText, setWarnModalText] = useState("");

  const modalAddReset = () => {
    setModalAddVisible(false);
  };

  const modalEditReset = () => {
    setModalEditVisible(false);
  };

  const resubmit = id => {
    const token = document.querySelector("[name=csrf-token]").content;
    axios.defaults.headers.common["X-CSRF-TOKEN"] = token;
    const formData = new FormData();
    formData.append("booking[resubmit]", true);
    axios({
      url: urljoin(apiRoot, "/housing/bookings", `/${id}`),
      method: "PATCH",
      data: formData
    })
      .then(response => {
        if (response.data.error === null) {
          alertSuccess("Booking submitted successfully");
          updateBookings(response.data.booking);
        } else {
          // alertError(response.data.error);
          setWarnModalText(response.data.error);
          setWarnModal(true);
        }
      })
      .catch(error => {
        alertHttpError(error);
      });
  };

  const renderEdit = (booking) => (
    <>
      <span
        className="cursor-pointer"
        onClick={() => {
          setEditBooking(booking);
          setModalEditVisible(true);
        }}
      >
        Edit
      </span>
    </>
  );

  const renderResubmit = booking => {
    if (!booking.status === "pending") { return <></>; }

    return (
      <>
        <span
          className="cursor-pointer"
          onClick={() => {
            resubmit(booking.id);
          }}
        >
          Resubmit
        </span>
      </>
    );
  }

  const columns = [
    {
      field: "guest_name",
      headerName: "Participant Name",
      flex: 1
    },
    {
      field: "participant_type",
      headerName: "Participant Type",
      flex: 1,
      renderCell: params => {
        return params.row.event_participant.event_participant_type
      },
    },
    {
      field: "room_block_name",
      headerName: "Room Block",
      flex: 1
    },
    {
      field: "hotel_name",
      headerName: "Hotel",
      flex: 1
    },
    {
      field: "room_type_name",
      headerName: "Room Type",
      flex: 1
    },
    {
      field: "check_in_date",
      headerName: "Check-in Date",
      flex: 1,
      renderCell: params => <span>{formatDateFriendly(params.value, null)}</span>,
    },
    {
      field: "check_out_date",
      headerName: "Check-out Date",
      flex: 1,
      renderCell: params => <span>{formatDateFriendly(params.value, null)}</span>,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1
    },
    {
      headerName: "Finalized?",
      field: "paid",
      searchable: false,
      flex: 1,
      renderCell: params => {
        if (params.value) {
          return <div style={{ textAlign: "left", paddingLeft: "8px" }}>✓</div>;
        }
        return <div style={{ textAlign: "left", paddingLeft: "8px" }} />;
      },
      sortComparator: (a, b) => {
        const aFlag = a.paid ? 1 : 0;
        const bFlag = b.paid ? 1 : 0;
        return aFlag - bFlag;
      }
    },
    {
      headerName: "Actions",
      field: "actions",
      type: "actions",
      minWidth: 150,
      flex: 1,
      getActions: params => [
        renderEdit(params.row),
        renderResubmit(params.row)
      ]
    }
  ];

  const renderNewBookingButton = () => {
    return (
      renderCreateButton(
        "Create New Booking",
        () => setModalAddVisible(true)
      )
    )
  };

  return (
    <div>
      {renderNewBookingButton()}
      <div><span className="sg-mgmt-link" onClick={goToFinalize}>Mass Finalize</span></div>
      <GrowlTable
        columns={columns}
        items={bookings}
        tableName={`${event.slug}-bookings`}
      />
      <HousingBookingModal
        blocks={blocks}
        bookings={bookings}
        booking={HousingBlankBooking}
        config={config}
        hotels={hotels}
        instructions={instructions}
        modalVisible={modalAddVisible}
        participants={participants}
        resetModal={modalAddReset}
        title="Create Booking"
        updateFunc={addBooking}
      />
      <HousingBookingModal
        blocks={blocks}
        bookings={bookings}
        booking={editBooking}
        config={config}
        deleteBooking={deleteBooking}
        hotels={hotels}
        instructions={instructions}
        modalVisible={modalEditVisible}
        participants={participants}
        resetModal={modalEditReset}
        title="Edit Booking"
        updateFunc={updateBookings}
      />
      <GrowlModal
        content={(
          <div>
            <p>
              There is insufficient inventory on one or more of the requested
              dates to create this booking.
            </p>
            <p>
              This booking will remain in &quot;pending&quot; mode, and can be resubmitted
              when inventory is made available.
            </p>
            <p>Error details: {warnModalText}</p>
          </div>
        )}
        title="Insufficient Inventory"
        open={warnModal}
        actions={[
          {
            label: "Close",
            close: true
          }
        ]}
      />
    </div>
  );
};

HousingBookingsList.defaultProps = {
  editBooking: HousingBlankBooking
};

HousingBookingsList.propTypes = {
  addBooking: PropTypes.func.isRequired,
  bookings: PropTypes.array.isRequired,
  blocks: PropTypes.array.isRequired,
  config: PropTypes.object.isRequired,
  editBooking: PropTypes.object,
  fetched: PropTypes.bool.isRequired,
  goToFinalize: PropTypes.func.isRequired,
  hotels: PropTypes.array.isRequired,
  participants: PropTypes.array.isRequired,
  setEditBooking: PropTypes.func.isRequired,
  updateBookings: PropTypes.func.isRequired
};

export default HousingBookingsList;
