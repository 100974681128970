import React, { useContext } from "react";

import useMediaQuery from "@mui/material/useMediaQuery";

import WidgetContentFrame from "@dashboard/WidgetContentFrame";
import WidgetContext from "@dashboard/WidgetContext";
import WidgetHeader from "@dashboard/WidgetHeader";
import WidgetSettings from "@dashboard/WidgetSettings";
import Bubble from "@shared/Bubble";

import BubbleWidgetSettings from "./BubbleWidgetSettings";

const BubbleWidget = () => {
  const { editMode, values, widget } = useContext(WidgetContext);
  const dataSet = widget?.widget_data_set;
  const small = useMediaQuery("(max-width:768px)");
  const large = useMediaQuery("(min-width:1280px)");

  const renderBubble = (key, bubble) => {
    if (!bubble.selected) {
      return <React.Fragment key={`${widget.id}-bubble-${key}`}></React.Fragment>;
    }
    if (!values) {
      return <React.Fragment key={`${widget.id}-bubble-${key}`}></React.Fragment>;
    }
    return (
      <Bubble
        key={`${widget.id}-bubble-${key}`}
        colorBack={bubble.color}
        label={bubble.label}
        size={small ? 75 : 135}
        value={values[key]}
      />
    );
  };

  const renderStandard = bubbles => {
    return (
      <div className="flex h-widget-body justify-start pt-8 align-middle">
        {bubbles.map(val => {
          return renderBubble(val[0], val[1]);
        })}
      </div>
    );
  };

  const renderLarge = bubbles => {
    return (
      <div className="flex h-widget-body justify-center pt-8 align-middle">
        {bubbles.map(val => {
          return <div key={val[0]} className="mx-4">{renderBubble(val[0], val[1])}</div>;
        })}
      </div>
    );
  };

  const renderContent = () => {
    if (!dataSet) {
      return <div className="h-widget-body">No data set selected</div>;
    }

    const sortedBubbles = Object.entries(widget?.widget_config?.bubbles?.[dataSet])
      .filter(val => val[1].selected)
      .sort((a, b) => a[1].order - b[1].order);

    if (large && widget.widget_size == 2) {
      return renderLarge(sortedBubbles);
    }

    return renderStandard(sortedBubbles);
  };

  const renderControls = () => {
    if (!editMode) return <></>;

    return <WidgetSettings label="Bubble Settings" settingsComponent={BubbleWidgetSettings} />;
  };

  return (
    <WidgetContentFrame>
      <WidgetHeader />
      {renderContent()}
      {renderControls()}
    </WidgetContentFrame>
  );
};

export default BubbleWidget;
